import { AppointmentStatus, Brand, Checklist, Dealer, NoteSuggestion, NoteType } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum DMS_CAPABILITIES {
  CarInShop = 1,
  ScheduledInOut,
  CancelAppointment,
  Mileage
}

export enum VERSION_TIER {
  BasicTier = 1,
  PlusTier,
  PremiumTier,
  EnterpriseTier
}
export class DealerLocation extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  active: boolean = false;
  name: string = "";
  headline: string = "";
  footerline: string = "";
  logo: string = "";
  language_code: string = "";
  vat: number = 0;
  include_vat: boolean = false;
  zoho_link: string = "";
  importer_version: string = "";
  number_citroen: string = "";
  number_ds: string = "";
  number_peugeout: string = "";
  summer_a_threshold: number = 0;
  summer_n_threshold: number = 0;
  winter_a_threshold: number = 0;
  winter_n_threshold: number = 0;
  all_season_a_threshold: number = 0;
  all_season_n_threshold: number = 0;
  truck_tyre_a_threshold: number = 0;
  truck_tyre_n_threshold: number = 0;
  car_ready_btn_visible: boolean = false;
  diagnose_overview_enabled: boolean = false;
  exact_is_category_licence_enabled: boolean = false;
  apk_visible: boolean = false;
  date_visible: boolean = false;
  accept_network_metrics: boolean = false;
  last_import_at: string | null = null;
  street?: string | null = null;
  postal_code?: string | null = null;
  city?: string | null = null;
  country?: string | null = null;
  phone_nr?: string | null = null;
  dealer_id: number = 0;
  extra_parts_list_id: number | null = null;
  default_customer: string = "";
  dp_driver_visible: boolean = false;
  dp_contractor_visible: boolean = false;
  dp_owner_visible: boolean = false;
  enable_appointment_propagation: boolean = false;
  pin_visible: boolean = false;
  video_enabled: boolean = false;
  schedule_enabled: boolean = false;
  first_name_optional: boolean = false;
  rdw_btn_visible: boolean = false;
  diagnose_status_visible: boolean = false;
  mcc_button_visible: boolean = false;
  customer_communication_visible: boolean = false;
  is_editing_question_videos_enabled: boolean = false;
  check_in_days_prior: number = 0;
  version_id: VERSION_TIER = VERSION_TIER.BasicTier;
  datastore_key: string = "";
  fleet_nr_visible?: boolean | null = null;
  service_box_visible_on_car: boolean = false;
  service_box_visible_on_wo?: boolean = false;
  dbb_user: string = "";
  ip_address_enabled: boolean = false;
  ip_address: string = "";
  dms_price_enabled: boolean = false;
  dms_billing_enabled: boolean = false;
  online_check_in_enabled: boolean = false;
  desk_check_in_enabled: boolean = false;
  is_open_desk_checkout_on_car_out_of_shop_enabled: boolean = false;
  customcom_enabled: boolean = false;
  postcom_enabled: boolean = false;
  zoho_id: string = "";
  cdk_business_unit: string = "";
  appointment_detail_users_indicator_enabled: boolean = false;
  dealer?: Dealer | null = null;
  version?: Version | null = null;
  notifier_key: string = "";
  is_tyre_team_enabled: boolean = false;
  repair_overview_enabled: boolean = false;
  is_dayplanner_enabled: boolean = false;
  dms_id: number = 0;
  dbb_enabled: boolean = false;
  hu_visible: boolean = false;
  is_warranty_pin_signature_missing_warning_enabled: boolean = false;
  is_warranty_pin_support_warning_enabled: boolean = false;
  is_warranty_pin_claim_warning_enabled: boolean = false;
  is_recall_pin_support_warning_enabled: boolean = false;
  is_recall_pin_claim_warning_enabled: boolean = false;
  is_automatically_pinned_appointments_readonly: boolean = false;
  is_manually_pinning_appointments_enabled: boolean = false;
  third_party_send_communications: boolean = false;
  hide_next_km_and_next_date?: boolean = false;
  exact_account_id: string | null = null;
  exact_is_dynamic_quantity_licenses_enabled: boolean = false;
  exact_location_startup_costs_invoiced: boolean = false;
  note_suggestions?: NoteSuggestion[] | null = null;
  note_types?: NoteType[] | null = null;
  dms_capability_ids?: DMS_CAPABILITIES[] | null = null;

  private _brands?: Brand[] | null = null;
  private _checklists?: Checklist[] | null = null;
  private _statuses?: AppointmentStatus[] | null = null;
  private _timeslots?: Timeslot[] | null = null;
  private _warranty_pin_signature_brands?: Brand[] | null = null;

  get brands(): Brand[] {
    return this._brands ? this._brands : [];
  }

  set brands(brands: Brand[] | null | undefined) {
    this._brands = brands;
  }

  get checklists(): Checklist[] {
    return this._checklists ? this._checklists : [];
  }

  set checklists(checklists: Checklist[] | null | undefined) {
    this._checklists = checklists;
  }

  get statuses(): AppointmentStatus[] {
    return this._statuses ? this._statuses : [];
  }

  set statuses(statuses: AppointmentStatus[] | null | undefined) {
    this._statuses = statuses;
  }

  get timeslots(): Timeslot[] {
    return this._timeslots ? this._timeslots : [];
  }

  set timeslots(timeslots: Timeslot[] | null | undefined) {
    this._timeslots = timeslots;
  }

  get warranty_pin_signature_brands(): Brand[] {
    return this._warranty_pin_signature_brands ? this._warranty_pin_signature_brands : [];
  }

  set warranty_pin_signature_brands(warranty_pin_signature_brands: Brand[] | null | undefined) {
    this._warranty_pin_signature_brands = warranty_pin_signature_brands;
  }
}

export class LocationAttachDetach extends ApiLoadable {
  checklist_id: number = 0;
  dealer_location_id: number = 0;
  dealer_id: number = 0;
}

export class Timeslot extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  deadline: string = "";
  active: boolean = false;
  dealer_location_id: number = 0;
}

export class Version extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  name: string = "";
  private _features: Feature[] | null = null;

  get features(): Feature[] {
    return this._features ? this._features : [];
  }

  set features(features: Feature[] | null) {
    this._features = features;
  }
}

export class Feature extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  key?: string = "";
  name: string = "";
  enabled: boolean = false;
  visible_for_dealer: boolean = false;
  active: boolean = false;
  display_type: string = "";
  price: number = 0;
  options_enc: string = "";
  version_id: VERSION_TIER = VERSION_TIER.BasicTier;
}

export class LocationReportTemplate extends ApiLoadable {
  id: number = 0;
  filename: string = "";
  name: string = "";
  report_type_id: number = 0;
  unit: string = "";
  thresholds: string = "";
  report_query_atributes: string = "";
  order: number = 0;
  group: number = 0;
  report_type: string = "";
}

export class ReportData extends ApiLoadable {
  id: number = 0;
  report_data: Record<string, string>[] | null = null;
  report_colors: ReportColor[] | null = null;
  report_type: string = "";
  unit: string = "";
  thresholds: ReportThreshold[] = [];
  report_name: string = "";
  report_order: number = 0;
}

export class ReportColor {
  name: string = "";
  color: string = "";
}

export class ReportThreshold {
  name: string = "";
  color: string = "";
  y: number = 0;
}
