import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useDealersLocations } from "hooks";
import { DealerLocation, NoteSuggestion, NoteType } from "models";
import { queryKeys } from "modules/NoteSuggestions/queryKeys";
import ApiInstance from "util/Api";

export type NoteTypeWithSuggestion = {
  note_suggestions: NoteSuggestion[];
  note_type: NoteType;
  icon: string;
  translation_key: string;
  default_message: string;
};

export const updateNoteSuggestionMutation = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();
  const [NoteSuggestionQueryKeys] = queryKeys.noteSuggestions();
  const { mutate, isPending } = useMutation({
    mutationFn: async (payload: NoteTypeWithSuggestion) => {
      await ApiInstance.post(NoteSuggestionQueryKeys.endpoint, payload, NoteSuggestionQueryKeys.baseUrl);
    },
    onMutate: (data: NoteTypeWithSuggestion) => {
      const dealerLocationData = queryClient.getQueryData(queryKeys.location(selectedLocation?.id)) as DealerLocation;
      const previousNotesTypeMatchIndex = dealerLocationData.note_types?.findIndex(type => type.note_type_id === data.note_type.note_type_id);
      if ((!previousNotesTypeMatchIndex && previousNotesTypeMatchIndex !== 0) || !dealerLocationData) return;

      const existingSuggestions = dealerLocationData.note_suggestions || [];
      const filteredSuggestions = existingSuggestions.filter(s => s.note_type_id !== data.note_type.note_type_id);
      const updatedNoteSuggestions = [...filteredSuggestions, ...data.note_suggestions];

      const updatedNoteTypes = dealerLocationData.note_types!.with(previousNotesTypeMatchIndex, data.note_type);

      const updatedDealerLocationData = {
        ...dealerLocationData,
        note_types: updatedNoteTypes,
        note_suggestions: updatedNoteSuggestions
      };

      queryClient.setQueryData(queryKeys.location(selectedLocation?.id), updatedDealerLocationData);

      return { dealerLocationData };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKeys.location(selectedLocation?.id), context?.dealerLocationData);
    }
  });

  return { mutate, isPending };
};

export const useGetDealerLocationData = () => {
  const { selectedLocation } = useDealersLocations();
  const [queryKeyLocation] = queryKeys.location(selectedLocation?.id);

  const { data: location, isLoading } = useQuery<DealerLocation>({
    queryKey: queryKeys.location(selectedLocation?.id),
    queryFn: async () => {
      const response = await ApiInstance.post(queryKeyLocation.endpoint, { id: selectedLocation?.id }, queryKeyLocation.baseUrl);
      return response.data;
    },
    enabled: !!selectedLocation?.id
  });

  return { location, isLoading };
};
