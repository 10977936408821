import { useQueryClient } from "@tanstack/react-query";
import _startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";
import { Checkbox, Dropdown, Grid, GridColumn } from "semantic-ui-react";

import { DealersAndLocationsDropdown, NavPortal, SNOOZE_DEPARTMENT, SNOOZE_STATUS, SNOOZE_TYPE } from "components";
import { useUser } from "hooks";
import { ExportDataButton, ExportDataModal, LeadTypeFilter, ResetFiltersButton, SearchFilter } from "modules/LeadsDashboard/components";
import { PossibleQuestionStatusOptions, useExportModal } from "modules/LeadsDashboard/hooks";
import { useSnoozedFilterHandlers } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { exportLeadsSnoozed } from "modules/LeadsDashboard/service";
import { ITranslation, SelectOption } from "util/interfaces";

export type LeadsSnoozedFilters = {
  customer_approved: boolean;
  page: number;
  appointment_date_from: Date | null;
  appointment_date_to: Date | null;
  snooze_date_from: Date | null;
  snooze_date_to: Date | null;
  dealer_ids: number[];
  location_ids: number[];
  is_lease_company: boolean | null;
  search_term: string;
  interventions: boolean;
  statuses: PossibleQuestionStatusOptions[] | null;
  questions: number[] | null;
  tags: number[] | null;
  snooze_department_id: SNOOZE_DEPARTMENT | null;
  snooze_status_id: SNOOZE_STATUS | null;
  snooze_type_id: SNOOZE_TYPE | null;
  last_customer_ok_is_me: boolean;
};

type LeadsSnoozedFiltersProps = {
  filters: LeadsSnoozedFilters;
  tagsOptions: SelectOption<number>[];
  departmentOptions: SelectOption<number>[];
};

export const SnoozedLeadsFilters = ({
  filters: { search_term, dealer_ids, location_ids, tags, customer_approved, interventions, snooze_department_id },
  tagsOptions,
  departmentOptions
}: LeadsSnoozedFiltersProps) => {
  const user = useUser();
  const t = useTranslation().t as ITranslation;
  const queryClient = useQueryClient();
  const snoozedFilters = queryClient.getQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters);
  const { handleCloseExportModal, handleOpenExportModal, showExportModal } = useExportModal();
  const {
    handleTagsChange,
    handleSearchChange,
    handleResetFilters,
    handleDepartmentChange,
    handleInterventionsChange,
    handleDealerLocationChange,
    handleCustomerApprovedChange
  } = useSnoozedFilterHandlers();
  const hasMultipleLocationsSelected = snoozedFilters ? snoozedFilters?.location_ids?.length > 1 : false;
  const hasMultipleDealersSelected = snoozedFilters ? snoozedFilters?.dealer_ids?.length > 1 : false;

  return (
    <>
      <NavPortal>
        <SearchFilter value={search_term} handleSearchChange={handleSearchChange} />
      </NavPortal>

      <div className="toolbar-container">
        <Grid columns={16} className="toolbar-grid">
          <GridColumn width={2} className="no-left-gutter right-gutter">
            <LeadTypeFilter />
          </GridColumn>
          <GridColumn width={2} className="left-gutter right-gutter">
            <Dropdown
              fluid
              clearable
              selection
              name="tag"
              defaultValue={snooze_department_id ?? undefined}
              selectOnBlur={false}
              options={departmentOptions}
              placeholder={t("v8_department").message || "Department"}
              onChange={(_, { value }) => handleDepartmentChange(value as number)}
            />
          </GridColumn>
          <GridColumn width={4} className="left-gutter right-gutter">
            <DealersAndLocationsDropdown
              values={{ dealerIds: dealer_ids, locationIds: location_ids }}
              handleOnChange={({ dealerIds, locationIds }) => handleDealerLocationChange({ dealerIds, locationIds })}
            />
          </GridColumn>
          <GridColumn width={4} className="left-gutter right-gutter">
            <Dropdown
              fluid
              selection
              name="tag"
              multiple
              value={tags || []}
              selectOnBlur={false}
              options={tagsOptions}
              placeholder={t("v8_tags").message || "Tags"}
              onChange={(_, { value }) => handleTagsChange(value as number[])}
            />
          </GridColumn>
          <GridColumn width={4} className="left-gutter right-gutter flex-align-justify-center">
            <div className="filters-checkboxes-container">
              <div className="filters-checkbox-container">
                <Checkbox toggle name="customer_approved" onChange={handleCustomerApprovedChange} checked={customer_approved} />
                <span className="no-wrap-text"> {_startCase(t("v8_customer_approved").message || "Customer approved")}</span>
              </div>
              <div className="filters-checkbox-container">
                <Checkbox toggle name="intervention" onChange={handleInterventionsChange} checked={interventions} />
                <span className="no-wrap-text"> {t("v8_intervention").message || "Intervention"}</span>
              </div>
            </div>
          </GridColumn>
        </Grid>

        <div className="toolbar-reset-export-buttons-container">
          <ResetFiltersButton handleReset={handleResetFilters} />
          {user?.csv_download && <ExportDataButton handleOnClick={handleOpenExportModal} hasWarning={hasMultipleLocationsSelected || hasMultipleDealersSelected} />}
        </div>
      </div>

      {snoozedFilters && (
        <ExportDataModal open={showExportModal} onClose={handleCloseExportModal} onExportData={() => snoozedFilters && exportLeadsSnoozed(snoozedFilters)} />
      )}
    </>
  );
};
