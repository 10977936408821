import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownDivider, DropdownItem, DropdownProps, Icon, Input } from "semantic-ui-react";

import "modules/LeadsDashboard/components/TableFilters/TableDropdownSelector/TableDropdownSelector.scss";
import { useDropdownSelector } from "modules/LeadsDashboard/components/TableFilters/TableDropdownSelector/useDropdownSelector";
import { SelectOptionWithIcon } from "modules/LeadsDashboard/hooks";
import { ITranslation } from "util/interfaces";

export interface DropdownTableSelectorProps extends DropdownProps {
  selected: string[];
  options: SelectOptionWithIcon<string | number>[];
  handleOnSelect: (value: string[]) => void;
  multiple?: boolean;
  withSearch?: boolean;
}

export const TableDropdownSelector = ({ handleOnSelect, options, selected, multiple = false, withSearch = false, ...rest }: DropdownTableSelectorProps) => {
  const t = useTranslation().t as ITranslation;
  const { dropdownOptions, handleOnClick, handleOpen, handleClose, setSearchTerm, showDropdown } = useDropdownSelector({
    handleOnSelect,
    options,
    selected,
    multiple,
    withSearch
  });
  const isSelected = (value: string) => selected.includes(value);

  return (
    <div className="TableDropdownSelector">
      <Dropdown
        value={multiple ? [] : ""}
        multiple={multiple}
        open={showDropdown}
        onOpen={handleOpen}
        onClose={handleClose}
        closeOnBlur={true}
        selectOnBlur={false}
        icon={null}
        closeOnChange={!multiple}
        scrolling
        className="custom-dropdown"
        trigger={
          <div>
            <Icon className={`${selected.includes("all") ? "filter" : "filters"} table-dropdown-filters-icon`} size="small" />
          </div>
        }
        inline
        {...rest}
      >
        <Dropdown.Menu onClick={(e: Event) => e.stopPropagation()} onKeyDown={(e: Event) => e.stopPropagation()}>
          {withSearch && (
            <>
              <Input
                icon="magnifying glass"
                iconPosition="left"
                className="search"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                placeholder={`${t("v8_search").message}...` || "Search..."}
              />
              <DropdownDivider />
            </>
          )}

          {dropdownOptions?.length ? (
            dropdownOptions.map(option => {
              const selected = isSelected(option.value.toString());

              return (
                <DropdownItem
                  key={option.value}
                  value={option.value.toString()}
                  selected={selected}
                  onClick={(e, data) => handleOnClick(e, data?.value?.toString() || "")}
                  className={`item-width ${option.icon && selected ? "icon-item" : ""}`}
                >
                  <div>
                    {option?.icon && <Icon className={option.icon} style={{ color: option?.iconColor }} />}
                    <span className={`text ellipsis ellipsis-wo-nr ${selected && (option.icon ? "icon-selected-text-color" : "selected-text-color")}`}>
                      {option.text}
                    </span>
                  </div>
                </DropdownItem>
              );
            })
          ) : (
            <DropdownItem>
              <span className="selected-text-color"> {t("v8_no_results_found").message || "No results found"} </span>
            </DropdownItem>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
