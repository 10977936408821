import { Button, Icon, Modal } from "semantic-ui-react";

import "modules/LocationLicenseOverview/components/InvoiceModal/InvoiceModal.scss";
import { HistoricalInvoice } from "modules/LocationLicenseOverview/types";

type InvoiceModalProps = {
  onClose: () => void;
  isOpen: boolean;
  invoice: HistoricalInvoice;
  invoicePDF: Blob | null;
};

export const InvoiceModal = ({ onClose, isOpen, invoice, invoicePDF }: InvoiceModalProps) => {
  if (!invoicePDF) return;

  return (
    <Modal open={isOpen} size="large" onClose={onClose} closeOnDimmerClick={false} className="InvoiceModal">
      <Modal.Header>
        <div className="InvoiceModal-header">
          <span>{invoice.Subject}</span>
          <Button className="-appointment-status" size="small" onClick={onClose}>
            <Icon className="xmark grey" size="big" />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="InvoiceModal-Content" tabIndex={0}>
          <iframe src={URL.createObjectURL(invoicePDF)} title={invoice.Subject} width="100%" height="100%" />
        </div>
      </Modal.Content>
    </Modal>
  );
};
