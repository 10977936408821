import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { ReactTable } from "components/ReactTable";
import "modules/DealerReports/components/DealerUsersReport/DealerUsersReport.scss";
import { ParsedReportData } from "modules/DealerReports/util/types";
import { ITranslation } from "util/interfaces";

interface ReportRow {
  [key: string]: any;
}

interface DealerUsersReportTableProps {
  data: ParsedReportData[];
}

const formatTableCell = (value: any): React.ReactNode => {
  if (value === null || value === undefined) return "0";

  if (typeof value === "number") return value.toString().includes(".") ? Number(value.toFixed(2)) : value;
  return value;
};

const DealerUsersReportTable = ({ data }: DealerUsersReportTableProps) => {
  const t = useTranslation().t as ITranslation;

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <div className="Table__no-results">
        <p>{t("v8_no_data").message || "No data"}</p>
      </div>
    );
  }

  const columnHelper = createColumnHelper<ReportRow>();
  const columns = Object.keys(data[0])
    .filter(key => key !== "__typename")
    .map(key =>
      columnHelper.accessor(key, {
        header: () => <strong>{t(key.replace("user_", "")).message || key}</strong>,
        cell: info => formatTableCell(info.getValue())
      })
    );

  return (
    <div className="DealerUsersReportTable">
      <div style={{ maxHeight: "600px", overflow: "auto" }}>
        <ReactTable data={data} columns={columns} columnSortingEnabled={true} />
      </div>
    </div>
  );
};

export default DealerUsersReportTable;
