import { DefaultError, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import { CriticalHistory, QUESTION_STATUSES } from "models";
import ApiInstance from "util/Api";

type CriticalHistoryReq = {
  appointment_id: number;
  page?: number;
};

type UseItemsNotRepairedType = {
  loading: boolean;
  data: CriticalHistory[];
  error: DefaultError | null;
  fetchItemsNotRepaired: (data: CriticalHistoryReq) => void;
};

export const useItemsNotRepaired = (): UseItemsNotRepairedType => {
  const [data, setData] = useState<CriticalHistory[]>([]);

  const fetchCriticalHistory = async ({ appointment_id, page = 1 }: CriticalHistoryReq) => {
    const res = await ApiInstance.post("/leads/advised_critical_history", { appointment_id, page });
    return res.data.question_results;
  };

  const handleSuccessResponse = (response: CriticalHistory[]) => {
    const itemsNotRepaired =
      response?.filter((question: any) => !question.mechanic_fixed && [QUESTION_STATUSES.Advice, QUESTION_STATUSES.Necessary].includes(question.status)) || [];

    setData(itemsNotRepaired);
  };

  const criticalHistoryMutation = useMutation({
    mutationFn: fetchCriticalHistory,
    onSuccess: handleSuccessResponse,
    onError: e => toast.error(e.message)
  });

  return { data, loading: criticalHistoryMutation.isPending, error: criticalHistoryMutation.error, fetchItemsNotRepaired: criticalHistoryMutation.mutate };
};
