import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DetailsCards, TabData } from "components";
import { MileageChart } from "modules/CarDetails/components";
import "modules/CarDetails/components/CarDrivingTrend/CarDrivingTrend.scss";
import { AppointmentHistory } from "modules/CarDetails/hooks";
import { getAverageKM, getColorByAverageKM } from "modules/CarDetails/utils";
import { ITranslation } from "util/interfaces";

type CarDrivingTrendProps = {
  tabData: TabData;
  appointmentHistory: AppointmentHistory[];
  isExpanded: boolean;
};

export type KmChartColors = {
  backgroundColors: string[];
};

export type BubbleData = {
  x: Date;
  y: number;
  r: number;
};

export type RelevantData = {
  km: number;
  time: Date;
};

export type CarDrivingChartData = {
  isMilageChartVisible: boolean;
  totalAverageKM: {
    color: string;
    day: number;
    month: number;
    year: number;
  };
  kmChartColors: {
    backgroundColors: string[];
  };
  bubbleData: BubbleData[];
  lineData: BubbleData[];
};

const prepareChartData = (history: AppointmentHistory[]) => {
  if (!Array.isArray(history) || history.length < 2) return { isMilageChartVisible: false };

  const lineData: BubbleData[] = [];
  const bubbleData: BubbleData[] = [];
  const relevantDataForAverageCalculation: RelevantData[] = [];
  const kmChartColors: KmChartColors = { backgroundColors: [] };
  let currentKm = -1;

  [...history].reverse().forEach(item => {
    const date = new Date(item.date);

    if (item.current_km > currentKm) {
      currentKm = item.current_km;
      kmChartColors.backgroundColors.push("#2D4473");
      lineData.push({ x: date, y: item.current_km, r: 6 });
      relevantDataForAverageCalculation.push({ km: item.current_km, time: date });
    } else {
      kmChartColors.backgroundColors.push("#FF0000");
    }

    bubbleData.push({ x: date, y: item.current_km, r: 6 });
  });

  if (relevantDataForAverageCalculation.length < 2) return { isMilageChartVisible: false };

  const totalAverageKM = getAverageKM(relevantDataForAverageCalculation[0], relevantDataForAverageCalculation[1]);

  return {
    isMilageChartVisible: true,
    kmChartColors,
    bubbleData,
    lineData,
    totalAverageKM: {
      color: getColorByAverageKM(totalAverageKM),
      day: totalAverageKM,
      month: 30 * totalAverageKM,
      year: 365 * totalAverageKM
    }
  };
};

export const CarDrivingTrend = ({ appointmentHistory, tabData, isExpanded }: CarDrivingTrendProps) => {
  const [chartData, setChartData] = useState<CarDrivingChartData>({
    isMilageChartVisible: false,
    totalAverageKM: { color: "", day: 0, month: 0, year: 0 },
    kmChartColors: { backgroundColors: [] },
    bubbleData: [],
    lineData: []
  });
  const t = useTranslation().t as ITranslation;

  useEffect(() => {
    setChartData({ ...prepareChartData(appointmentHistory) } as CarDrivingChartData);
  }, [appointmentHistory]);

  return (
    <DetailsCards containerStyle="mt-20" isExpanded={isExpanded} id={tabData.id} icon="chart simple" title={`${t("v8_driving_trend").message || "Driving Trend"}`}>
      <div className="CarDrivingTrend">
        {!chartData.isMilageChartVisible && <div className="NoDataAvailable">{t("v8_no_data_available").message || "No data available"}</div>}

        {chartData.isMilageChartVisible && (
          <div className="MileageChart-Wrapper">
            <div style={{ marginBottom: "0.5em", fontSize: "1.2em" }}>
              {t("v8_total_car_driving_trend").message || "Total driving trend"} ({t("v8_average").message || "Average"}&nbsp;
              <span style={{ color: chartData.totalAverageKM.color }}>{chartData.totalAverageKM.day}</span> km {t("v8_per_day").message || "Per day"},&nbsp;
              <span style={{ color: chartData.totalAverageKM.color }}>{chartData.totalAverageKM.month}</span> km {t("v8_per_month").message || "Per month"},&nbsp;
              <span style={{ color: chartData.totalAverageKM.color }}>{chartData.totalAverageKM.year}</span> km {t("v8_per_year").message || "Per year"} )
            </div>
            <MileageChart colors={chartData.kmChartColors} bubbleData={chartData.bubbleData} lineData={chartData.lineData} />
          </div>
        )}
      </div>
    </DetailsCards>
  );
};
