import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, GridColumn, Icon, Label, Menu, MenuItem, MenuItemProps } from "semantic-ui-react";

import { AnswerStatusSelector, CUSTOM_CONFIRM_TYPES, Can, CustomConfirm, Pin, getPinColor, useCan } from "components";
import { useDealersLocations } from "hooks";
import { COMMUNICATION_RESULT_STATUS, Check, CommunicationEvent, FinalCheckImage, Intervention, PIN_TYPE, PinModel, STATUS_IDENTIFIER } from "models";
import { Attachments, GeneralInfo, ImportantNotes, InterventionChecklist, PinCategory, Subitems } from "modules/AppointmentDetails/components/Interventions/components";
import "modules/AppointmentDetails/components/Interventions/Interventions.scss";
import { useAppointment, useChecklist, useIntervention } from "modules/AppointmentDetails/hooks";
import { useConnectedInterventionId } from "modules/AppointmentDetails/hooks/index";
import { getPriceInVAT } from "util/common";
import { ITranslation } from "util/interfaces";

export type PinType = {
  key: string;
  text: any;
  value: PIN_TYPE;
  icon: JSX.Element;
};

type InterventionItemProps = {
  intervention: Intervention;
  index: number;
  dmsPriceEnabled: boolean | undefined;
  t: ITranslation;
  pinTypeData: PinType[];
  showPriceInVat?: boolean;
  onRequestCloseTabs: (index: number | null) => void;
  activeIntervention: number | null;
  hasChanges: boolean;
  diagnose_overview_event?: CommunicationEvent;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
};

enum INTERVENTION_TABS {
  General = "general",
  Subitems = "subitems",
  PinType = "pintype",
  Attachments = "attachments",
  Checklists = "checklists"
}

const mappedStatusIcons: Record<COMMUNICATION_RESULT_STATUS, React.ReactNode | null> = {
  [COMMUNICATION_RESULT_STATUS.OkStatus]: <Icon size="small" className="user check" color="green" />,
  [COMMUNICATION_RESULT_STATUS.NotOkStatus]: <Icon size="small" className="user xmark" color="red" />,
  [COMMUNICATION_RESULT_STATUS.ContactMeStatus]: <Icon size="small" className="phone" color="green" />,
  [COMMUNICATION_RESULT_STATUS.NotAnsweredStatus]: null
};

const renderDiagnoseOverviewAnswer = (status?: COMMUNICATION_RESULT_STATUS) => {
  if (!status) return null;

  const icon = mappedStatusIcons[status];
  return (
    icon && (
      <div className="status-container diagnose-overview-icon">
        <div>{icon}</div>
      </div>
    )
  );
};

const InterventionItem = ({
  intervention,
  index,
  dmsPriceEnabled,
  t,
  showPriceInVat,
  pinTypeData,
  onRequestCloseTabs,
  activeIntervention,
  hasChanges,
  setHasChanges,
  diagnose_overview_event
}: InterventionItemProps) => {
  const latestPinData = intervention.pin_history?.sort((a: PinModel, b: PinModel) => {
    const dateA = new Date(String(a.created_on));
    const dateB = new Date(String(b.created_on));
    return dateB.getTime() - dateA.getTime();
  })[0];
  const [showTabs, setShowTabs] = useState(false);
  const [activeTab, setActiveTab] = useState<INTERVENTION_TABS | null>(INTERVENTION_TABS.General);
  const [newActiveTab, setNewActiveTab] = useState<INTERVENTION_TABS | null>(INTERVENTION_TABS.General);
  const [newActiveIntervention, setNewActiveIntervention] = useState<number | null | undefined>();

  const [pinTypeID, setPinTypeID] = useState(latestPinData?.pin_type_id || 0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { appointment } = useAppointment(intervention.appointment_id);
  const { optimisticInterventionUpdate } = useIntervention(intervention.appointment_id);
  const { selectedLocation: location } = useDealersLocations();
  const canUpdateInterventions = useCan("update", "interventions");
  const {
    checklistQuery: { data: checklists }
  } = useChecklist(intervention.appointment_id);
  const { updateConnectedInterventionId } = useConnectedInterventionId();

  const connectedChecklists = checklists?.filter((checklist: Check) => checklist.intervention_id === intervention.id);
  useEffect(() => {
    if (activeTab === INTERVENTION_TABS.Checklists) {
      updateConnectedInterventionId(intervention.id);
      return;
    }
    updateConnectedInterventionId(null);
  }, [activeTab, showTabs]);

  useEffect(() => {
    if (latestPinData?.pin_type_id) {
      setPinTypeID(latestPinData.pin_type_id);
    }
  }, [latestPinData?.pin_type_id]);

  const toggleTabs = () => {
    if (hasChanges) {
      setNewActiveIntervention(activeIntervention === index ? null : index);
      setShowConfirmModal(true);
      return;
    }

    onRequestCloseTabs(activeIntervention === index ? null : index);
  };

  useEffect(() => {
    if (activeIntervention === index) {
      if (activeTab === null) {
        setActiveTab(INTERVENTION_TABS.General);
      }
      setShowTabs(true);
    } else {
      setActiveTab(null);
      setShowTabs(false);
    }
  }, [activeIntervention]);

  const selectedPin: PinType[] = pinTypeID && pinTypeData ? pinTypeData.filter(pinType => pinType.value === pinTypeID) : [];

  const handleTab = (_e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, menuItem: MenuItemProps) => {
    if (hasChanges) {
      setShowConfirmModal(true);
      setNewActiveTab(menuItem.name as INTERVENTION_TABS);
    } else {
      setActiveTab(menuItem.name as INTERVENTION_TABS);
    }
  };

  const handleConfirm = () => {
    setShowConfirmModal(false);
    if (newActiveIntervention !== undefined) {
      onRequestCloseTabs(newActiveIntervention);
    }
    setActiveTab(newActiveTab);
    setHasChanges(false);
  };

  const onRequestCloseModal = () => {
    setShowConfirmModal(false);
  };

  const handleSubTab = (name: INTERVENTION_TABS) => {
    if (activeTab === name) {
      setShowTabs(false);
      setActiveTab(null);
    } else {
      setShowTabs(true);
      setActiveTab(name);
    }
    onRequestCloseTabs(index);
  };

  const handlePinType = (pinTypeID: PIN_TYPE) => {
    setPinTypeID(pinTypeID);
  };

  const attachments = [
    ...(intervention.attachments || []),
    ...(intervention.diagnose_overview_attachments || []),
    ...(appointment?.final_check_images?.filter((item: FinalCheckImage) => item.intervention_index === index) || [])
  ];

  const toggleVisibleInPDF = () => {
    if (!canUpdateInterventions) return;

    if (appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus) {
      optimisticInterventionUpdate.mutate({
        ...intervention,
        visible_to_customer: !intervention.visible_to_customer
      });
    }
  };

  const toggleCustomerOk = () => {
    if (appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus) {
      optimisticInterventionUpdate.mutate({
        ...intervention,
        customer_ok: !intervention.customer_ok
      });
    }
  };

  return (
    <div className="Intervention">
      <Grid>
        <GridColumn width={activeIntervention ? 7 : 8} className="intrevention-container">
          <div className="title-container">
            {intervention?.question_result_status ? (
              <div className="question-status-container">
                <AnswerStatusSelector
                  data={{ ...intervention, status: intervention.question_result_status as number | null } as Intervention}
                  type="intervention"
                  disabled
                />
              </div>
            ) : null}
            <div className="title-container-button" onClick={toggleTabs}>
              <Icon className="desktop" size="tiny" />
              <p>{intervention.title}</p>
              <Icon className={`chevron ${showTabs ? "down" : "right"}`} size="small" />
            </div>
          </div>
        </GridColumn>

        <GridColumn width={activeIntervention ? 5 : 2} className="intrevention-container">
          {dmsPriceEnabled && (
            <div className="column-container float-right">
              <p>{`€ ${showPriceInVat ? getPriceInVAT(intervention.price, location).toFixed(2) : intervention.price.toFixed(2)}`}</p>
            </div>
          )}
        </GridColumn>

        <GridColumn width={activeIntervention ? 4 : 6} className="intrevention-container">
          <div className="column-container float-right">
            <div className="column-container gap">
              {connectedChecklists?.length > 0 && (
                <Label className="checklist-label" onClick={() => handleSubTab(INTERVENTION_TABS.Checklists)}>
                  <div className="flex-row">
                    <Icon className="standard list" /> {connectedChecklists.length}
                  </div>
                </Label>
              )}
              {!!intervention.elements?.length && (
                <Label className="green" onClick={() => handleSubTab(INTERVENTION_TABS.Subitems)}>
                  <div className="flex-row">
                    <Icon className="diagram subtask" /> {intervention.elements.length}
                  </div>
                </Label>
              )}

              {attachments.length > 0 && (
                <Label className="skyblue" onClick={() => handleSubTab(INTERVENTION_TABS.Attachments)}>
                  <div className="flex-row">
                    <Icon className="paperclip" /> {attachments.length}
                  </div>
                </Label>
              )}
              {latestPinData && selectedPin && selectedPin.length > 0 && (
                <Pin pin={latestPinData} pinType={selectedPin[0]} intervention={intervention} onClick={() => handleSubTab(INTERVENTION_TABS.PinType)} />
              )}
            </div>
            {renderDiagnoseOverviewAnswer(diagnose_overview_event?.intervention_results?.find(r => r.intervention_id === intervention.id)?.communication_result_status)}
            <div className="status-container">
              <Can I="update" the="interventions">
                <div>
                  <Icon
                    className={`eye ${intervention.visible_to_customer ? "green" : "slash red"} ${canUpdateInterventions ? "pointer" : ""}`}
                    size="small"
                    onClick={toggleVisibleInPDF}
                  />
                </div>
              </Can>
              <div>
                <Icon
                  className={`${intervention.customer_ok ? "check circle green" : "remove circle red"}`}
                  size="large"
                  onClick={toggleCustomerOk}
                  disabled={!useCan("update", "interventions")}
                />
              </div>
              <div className="pointer position-relative" onClick={() => handleSubTab(INTERVENTION_TABS.General)}>
                {intervention.mechanic_fixed ? (
                  <>
                    <Icon className="wrench green pointer" size="small" />
                    <Icon className="check circle green wrench-check-icon" />
                  </>
                ) : (
                  <Icon className="wrench regular grey rotate-270" size="small" />
                )}
              </div>
            </div>
          </div>
        </GridColumn>

        {!showTabs && (
          <GridColumn width={16} style={{ paddingTop: 0 }}>
            <ImportantNotes data={intervention.pin_history} />
          </GridColumn>
        )}
      </Grid>

      {showTabs && (
        <div className="tabs">
          <Menu tabular>
            <MenuItem name={INTERVENTION_TABS.General} active={activeTab === INTERVENTION_TABS.General} onClick={handleTab}>
              <div className={`tab green ${activeTab === "general" ? "active" : ""}`}>
                <Icon className="screwdriver wrench" />
                <p>{t("v8_general_info").message || "General Info"}</p>
              </div>
            </MenuItem>
            <MenuItem name={INTERVENTION_TABS.Subitems} active={activeTab === INTERVENTION_TABS.Subitems} onClick={handleTab}>
              <div className={`tab darkgreen ${activeTab === INTERVENTION_TABS.Subitems ? "active" : ""}`}>
                <Icon className="diagram subtask" />
                <p>
                  {t("v8_subitems").message || "Subitems"} {intervention.elements?.length ? `(${intervention.elements.length})` : null}
                </p>
              </div>
            </MenuItem>
            {selectedPin && selectedPin.length > 0 && (
              <MenuItem name={INTERVENTION_TABS.PinType} active={activeTab === INTERVENTION_TABS.PinType} onClick={handleTab}>
                <div
                  className={`tab ${getPinColor(intervention.pin_history?.[0], appointment, location, intervention)} ${activeTab === INTERVENTION_TABS.PinType ? "active" : ""}`}
                >
                  {selectedPin[0].icon}
                  <p>{selectedPin[0].text}</p>
                </div>
              </MenuItem>
            )}
            <MenuItem name={INTERVENTION_TABS.Attachments} active={activeTab === INTERVENTION_TABS.Attachments} onClick={handleTab}>
              <div className={`tab skyblue ${activeTab === INTERVENTION_TABS.Attachments ? "active" : ""}`}>
                <Icon className="paperclip" />
                <p>
                  {t("v8_attachments").message || "Attachments"} {attachments.length > 0 ? `(${attachments.length})` : null}
                </p>
              </div>
            </MenuItem>
            {connectedChecklists.length > 0 && (
              <MenuItem name={INTERVENTION_TABS.Checklists} active={activeTab === INTERVENTION_TABS.Checklists} onClick={handleTab}>
                <div className={`tab green ${activeTab === INTERVENTION_TABS.Checklists ? "active" : ""}`}>
                  <Icon className="standard list" />
                  <p>
                    {t("v8_checklists").message || "Checklists"} {`(${connectedChecklists.length})`}
                  </p>
                </div>
              </MenuItem>
            )}
          </Menu>
          <div className="tabs-container">
            {activeTab === INTERVENTION_TABS.General && appointment && (
              <GeneralInfo
                pinTypeData={pinTypeData}
                typeID={pinTypeID}
                data={intervention}
                onPinTypeSet={handlePinType}
                onSetHasChanges={setHasChanges}
                appointment={appointment}
              />
            )}
            {activeTab === INTERVENTION_TABS.Subitems && <Subitems intervention={intervention} showPriceInVat={showPriceInVat} />}
            {appointment && selectedPin && selectedPin.length > 0 && activeTab === INTERVENTION_TABS.PinType && (
              <PinCategory
                latestPinData={latestPinData}
                data={intervention}
                pinType={selectedPin[0]}
                pinTypeData={pinTypeData}
                appointment={appointment}
                setHasChanges={setHasChanges}
              />
            )}
            {activeTab === INTERVENTION_TABS.Attachments && <Attachments intervention={intervention} attachments={attachments} />}
            {activeTab === INTERVENTION_TABS.Checklists && <InterventionChecklist checklist={connectedChecklists} />}
          </div>
        </div>
      )}
      <CustomConfirm
        isLoading={false}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        isOpen={showConfirmModal}
        handleConfirm={handleConfirm}
        handleCancel={onRequestCloseModal}
        confirmMsg={t("v8_unsaved_changes_confirm_message").message || "You have some unsaved changes. If you proceed, these changes will be lost."}
      />
    </div>
  );
};

export default withTranslation()(InterventionItem);
