import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect } from "react";

import { useDealersLocations } from "hooks";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";

export const useDefaultCarsFilters = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();

  const defaultLeadsCarsFilters: LeadsCarsFilter = {
    page: 1,
    dealer_ids: [],
    search_term: "",
    is_lease_company: null,
    date_from: moment().subtract(30, "days").toDate(),
    date_to: moment().toDate(),
    location_ids: selectedLocation?.id ? [selectedLocation.id] : []
  };

  useEffect(() => {
    const carsFilters = queryClient.getQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters);
    if (!selectedLocation || (carsFilters?.location_ids && carsFilters?.location_ids.length > 0)) return;

    queryClient.setQueryData(LeadsQueryKeys.carsFilters, { ...carsFilters, location_ids: selectedLocation ? [selectedLocation.id] : [] });
  }, [selectedLocation]);

  return { defaultLeadsCarsFilters };
};

export const useCarsFiltersQuery = () => {
  const { defaultLeadsCarsFilters } = useDefaultCarsFilters();
  const { data: carsFilters } = useQuery<LeadsCarsFilter>({ queryKey: LeadsQueryKeys.carsFilters, initialData: defaultLeadsCarsFilters });
  return { carsFilters };
};
