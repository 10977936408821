import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Grid, GridColumn, GridRow, Icon, Input, Modal, ModalProps } from "semantic-ui-react";

import { Editable, Pagination, useCan } from "components";
import { Appointment, QuestionResult, SearchTyreReplacement, TYRE_SEASONS } from "models";
import { TyreLocationData } from "modules/AppointmentDetails/components/Tyre";
import { useTyreReplacementModal } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/hooks";
import "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/TyreReplacementModal.scss";
import { getTyreSeasonIcon, isCarReadyOrQualityCheckDoneOrCanceled, parseNumberOrDefault } from "util/common";
import { ITranslation } from "util/interfaces";

interface TyreReplacementModalProps extends ModalProps {
  data: QuestionResult | null;
  onRequestClose?: () => void;
  appointment: Appointment;
  tireData: TyreLocationData;
}

export type Delivery = {
  order_before: string;
  delivery_before: string;
  delivery_from: string;
  id: string;
};

export type TyreData = {
  price: number;
  question_result_id: number;
  tyre_team: SearchTyreReplacement;
  tyre_id?: number;
  preselected?: boolean;
};

const MAX_RESULTS_PER_PAGE = 100;

export const TyreReplacementModal = ({ data, open, onRequestClose, appointment, tireData }: TyreReplacementModalProps) => {
  const t = useTranslation().t as ITranslation;
  const [currentPage, setCurrentPage] = useState(1);

  const {
    size,
    width,
    height,
    season,
    selection,
    brandsQuery,
    searchResult,
    manufacturer,
    setSize,
    setWidth,
    setHeight,
    setSeason,
    handleSelect,
    handleSearch,
    getBrandsData,
    handleAddTyre,
    handlePriceUpdate,
    setManufacturer
    // TYRE TEAM
    // deliveryQuery,
    // getDeliveriesData,
    // getDelivery,
    // getDeliveryChannel,
    // isTyreTeamEnabled,
    // inStock,
    // setInStock,
    // delivery,
    // setDelivery
  } = useTyreReplacementModal({ data, onRequestClose, appointment, tireData });
  const canUpdateAppointments = useCan("update", "appointments");
  const seasonOptions = [
    { key: TYRE_SEASONS.AllSeason, value: TYRE_SEASONS.AllSeason, text: t("v8_all_season").message || "All season" },
    { key: TYRE_SEASONS.Summer, value: TYRE_SEASONS.Summer, text: t("v8_summer").message || "Summer" },
    { key: TYRE_SEASONS.Winter, value: TYRE_SEASONS.Winter, text: t("v8_winter").message || "Winter" }
  ];

  const startIndex = (currentPage - 1) * MAX_RESULTS_PER_PAGE;
  const endIndex = currentPage * MAX_RESULTS_PER_PAGE;
  const segmentedResults = searchResult.length > MAX_RESULTS_PER_PAGE ? searchResult.slice(startIndex, endIndex) : searchResult;

  // const renderDeliveryPopup = (delivery: string) => {
  //   const type = getDelivery(delivery);
  //   return type ? <Popup content={<span>{type.content}</span>} trigger={<span>{type.trigger}</span>} /> : null;
  // };

  return (
    <Modal className="TyreReplacementModal" closeOnEscape size="fullscreen" dimmer="blurring" open={open} onClose={onRequestClose} closeOnDimmerClick={true}>
      <Modal.Header>{t("v8_add_replacement").message || "Add Replacement"}</Modal.Header>
      <Modal.Content scrolling className="modal-content-container">
        <div className="TyreReplacementModal">
          <Form onSubmit={handleSearch}>
            <Form.Group>
              <Form.Field width={2} required>
                <label>{t("v8_width").message || "Width"}</label>
                <Input
                  value={width}
                  placeholder={t("v8_width").message || "Width"}
                  fluid
                  name="width"
                  required
                  onChange={e => setWidth(Number(parseNumberOrDefault(e.target.value)))}
                />
              </Form.Field>

              <Form.Field width={2}>
                <label>{t("v8_height").message || "Height"}</label>
                <Input
                  value={height}
                  placeholder={t("v8_height").message || "Height"}
                  fluid
                  name="height"
                  onChange={e => setHeight(Number(parseNumberOrDefault(e.target.value)))}
                />
              </Form.Field>

              <Form.Field width={2} disabled={!height || height <= 0}>
                <label>{t("v8_size").message || "Size"}</label>
                <Input
                  value={size}
                  placeholder={t("v8_size").message || "Size"}
                  fluid
                  name="size"
                  onChange={e => setSize(Number(parseNumberOrDefault(e.target.value)))}
                />
              </Form.Field>

              <Form.Field width={4}>
                <label>{t("v8_brand").message || "Brand"}</label>
                <Dropdown
                  selection
                  selectOnBlur={false}
                  loading={brandsQuery.isLoading}
                  options={getBrandsData()}
                  clearable
                  className="DeliveryDropdown"
                  search
                  value={manufacturer}
                  onChange={(_evt, { value }) => setManufacturer(String(value))}
                  name="manufacturer"
                />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("v8_season").message || "Season"}</label>
                <Dropdown
                  selection
                  className="DeliveryDropdown"
                  selectOnBlur={false}
                  clearable
                  options={seasonOptions}
                  value={season}
                  onChange={(_evt, { value }) => setSeason(Number(value))}
                  name="season"
                />
              </Form.Field>

              {/* {isTyreTeamEnabled && (
                <>
                  <Form.Field width={6} required>
                    <label>{t("v8_delivery").message || "Delivery"}</label>
                    <Dropdown
                      selection
                      selectOnBlur={false}
                      clearable
                      className="DeliveryDropdown"
                      value={delivery as unknown as string[]}
                      options={getDeliveriesData()}
                      placeholder={t("v8_select_deliveries").message || "Select deliveries"}
                      loading={deliveryQuery.isLoading}
                      onChange={(_evt, { value }) => setDelivery(value as unknown as Delivery[])}
                      name="delivery"
                      multiple
                    />
                  </Form.Field>

                  <Form.Field width={1} className="in-stock-form-field">
                    <label>{t("v8_in_stock").message || "In stock"}</label>
                    <Checkbox toggle className="in-stock-checkbox" defaultChecked={inStock} name="in_stock" onChange={(_e, data) => setInStock(Boolean(data.checked))} />
                  </Form.Field>
                </>
              )} */}

              <Form.Field className="no-padding-right">
                <label className="search-form-label"> {t("v8_search").message || "Search"}</label>
                <Button
                  type="submit"
                  className="search-button"
                  // disabled={(isTyreTeamEnabled && !delivery.length) || brandsQuery.isLoading}
                  disabled={brandsQuery.isLoading || !width || width <= 0}
                  loading={brandsQuery.isLoading}
                >
                  {t("v8_search").message || "Search"}
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>

          {searchResult.length ? (
            <Grid>
              <GridRow className="header">
                <GridColumn width={1} />
                {/* {isTyreTeamEnabled ? (
                <>
                  <GridColumn width={3}>
                    <p>{t("v8_description").message || "Description"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_brand").message || "Brand"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{"Art / EAN"} #</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_gross").message || "Gross"} €</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_neto").message || "Neto"} €</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_customer").message || "Customer"} €</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_load").message || "Load"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_speed").message || "Speed"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_flat_tire").message || "Flat Tire"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_channel").message || "Channel"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_stock").message || "Stock"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_delivery").message || "Delivery"}</p>
                  </GridColumn>
                </>
              ) : ( */}
                <>
                  <GridColumn width={6}>
                    <p>{t("v8_description").message || "Description"}</p>
                  </GridColumn>
                  <GridColumn width={6}>
                    <p>{t("v8_brand").message || "Brand"}</p>
                  </GridColumn>
                  <GridColumn width={2}>
                    <p>{t("v8_price").message || "Price"}</p>
                  </GridColumn>
                </>
                <GridColumn width={1}>
                  <p>{t("v8_add").message || "Add"}</p>
                </GridColumn>
              </GridRow>
              {segmentedResults.map((item, index: number) => (
                <GridRow key={index}>
                  <GridColumn width={1}>
                    <Icon className={getTyreSeasonIcon(Number(item.season))} />
                  </GridColumn>
                  {/* {isTyreTeamEnabled ? (
                    <>
                      <GridColumn width={3}>
                        <p>{item.description}</p>
                      </GridColumn>
                      <GridColumn width={1}>
                        <p>{item.manufacturer}</p>
                      </GridColumn>
                      <GridColumn width={1}>
                        <div>
                          {item.system_number}
                          {item.ean && (
                            <>
                              {" / "}
                              <Popup hoverable trigger={<div className="replacement-tyre-ean">{item.ean}</div>} content={item.ean} />
                            </>
                          )}
                        </div>
                      </GridColumn>
                      <GridColumn width={1}>
                        {item.stock && item.gross_price ? (
                          <p>
                            {item.gross_price} {item.discount && `- ${item.discount}%`}
                          </p>
                        ) : (
                          <p>0.0</p>
                        )}
                      </GridColumn>{" "}
                      <GridColumn width={1} className={`${item.customerPrice && item.customerPrice < item.net_price ? "lower-customer-price" : ""}`}>
                        {item.stock && item.net_price ? (
                          <p>
                            {item.net_price} {item.price_reduction && `- ${item.price_reduction}%`}
                          </p>
                        ) : (
                          <p>0.0</p>
                        )}
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{item.customerPrice ? roundTwoDecimals(Number(item.customerPrice)).toFixed(2) : null}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{item.carry_capacity}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{item.speed}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>{item.run_flat_tire ? <Icon name="check circle" color="green" /> : ""}</GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{getDeliveryChannel(item.channel)}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{item.stock ? item.stock : 0}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{renderDeliveryPopup(item.delivery)}</p>
                      </GridColumn>{" "}
                    </>
                  ) : ( */}
                  <>
                    <GridColumn width={6}>
                      <p>{item.description}</p>
                    </GridColumn>
                    <GridColumn width={6}>
                      <p>{item.manufacturer}</p>
                    </GridColumn>
                    <GridColumn width={2}>
                      <Editable
                        value={String(item.gross_price || "")}
                        placeholder="0.00"
                        onSubmit={(price: string) => handlePriceUpdate(price, item.id, index)}
                        disabled={
                          !canUpdateAppointments ||
                          isCarReadyOrQualityCheckDoneOrCanceled(appointment) ||
                          Boolean(selection?.[item.ean]?.preselected) ||
                          Boolean(selection?.[item.ean]) === false
                        }
                      />
                    </GridColumn>
                  </>
                  <GridColumn width={1}>
                    <Checkbox
                      className="check"
                      checked={Boolean(selection?.[item.ean])}
                      disabled={Boolean(selection?.[item.ean]?.preselected)}
                      onChange={(_e, data) => handleSelect(index, Boolean(data.checked))}
                    />
                  </GridColumn>
                </GridRow>
              ))}
            </Grid>
          ) : (
            <div className="no-results-container">
              <p className="no-results-text"> {t("v8_no_tires_found").message || "No tires found"} </p>
            </div>
          )}

          {searchResult.length > MAX_RESULTS_PER_PAGE && (
            <div className="pagination-container">
              <Pagination
                currentPage={currentPage}
                pageSize={MAX_RESULTS_PER_PAGE}
                totalPages={Math.ceil(searchResult.length / MAX_RESULTS_PER_PAGE)}
                totalItems={searchResult.length}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className="action-buttons">
          <Button color="light" onClick={onRequestClose} type="button">
            {t("v8_cancel").message || "Cancel"}
            <Icon className="xmark" />
          </Button>
          <Button color="green" form="new-intervention" type="button" onClick={handleAddTyre}>
            {t("v8_add").message || "Add"}
            <Icon className="check" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
