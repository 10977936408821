import { useMemo, useState } from "react";

import { Appointment, ChecklistTemplate, QuestionResult, TYRE_POSITIONS } from "models";
import { TyreReplacementModal } from "modules/AppointmentDetails/components/Tyre/components";
import DBBLabelsModal from "modules/AppointmentDetails/components/Tyre/DBBLabelsModal";
import "modules/AppointmentDetails/components/Tyre/Tyre.scss";
import TyreChecklist from "modules/AppointmentDetails/components/Tyre/TyreChecklist";

export const tyrePosition = {
  isOnCar: (position: TYRE_POSITIONS) => position > TYRE_POSITIONS.NotTyre && position < TYRE_POSITIONS.StorageFrontLeft,
  isInCar: (position: TYRE_POSITIONS) => position > TYRE_POSITIONS.CarRearRight && position < TYRE_POSITIONS.TyrePositionLast,
  isNotTruck: (position: TYRE_POSITIONS) => position > TYRE_POSITIONS.TyrePositionLast
};

type TyreProps = {
  data: QuestionResult[];
  template?: ChecklistTemplate | null;
  appointment: Appointment;
};

export type TyreLocationData = {
  onCar: QuestionResult[];
  inStorage: QuestionResult[];
  onTruck: QuestionResult[];
};

export const Tyre = ({ data, template, appointment }: TyreProps) => {
  const [isDBBModalOpen, setIsDBBModalOpen] = useState(false);
  const [isReplacementModalOpen, setIsReplacementModalOpen] = useState(false);

  const [DBBModalData, setModalDBBModalData] = useState<QuestionResult[] | null>(null);
  const [tyreReplacementModalData, setTyreReplacementModalData] = useState<QuestionResult | null>(null);

  const tireData = useMemo(() => {
    const tyreLocationData: TyreLocationData = { onCar: [], inStorage: [], onTruck: [] };

    data?.forEach(item => {
      if (!item.tyre) return;

      if (tyrePosition.isOnCar(Number(item.tyre_position))) tyreLocationData.onCar.push(item);
      else if (tyrePosition.isInCar(Number(item.tyre_position))) tyreLocationData.inStorage.push(item);
      else tyreLocationData.onTruck.push(item);
    });

    return tyreLocationData;
  }, [data]);

  const handleModalClose = () => {
    setModalDBBModalData(null);
    setTyreReplacementModalData(null);
    setIsDBBModalOpen(false);
    setIsReplacementModalOpen(false);
  };

  const handleDBBModal = (data: QuestionResult[]) => {
    setModalDBBModalData(data);
    setIsDBBModalOpen(true);
  };

  const handleTyreReplacementModal = (data: QuestionResult) => {
    setTyreReplacementModalData(data);
    setIsReplacementModalOpen(true);
  };

  return (
    <div className="Tyre">
      <TyreChecklist onRequestDBBPreview={handleDBBModal} onRequestReplacement={handleTyreReplacementModal} data={data} template={template} appointment={appointment} />
      <DBBLabelsModal open={isDBBModalOpen} data={DBBModalData} onRequestClose={handleModalClose} appointment={appointment} />
      <TyreReplacementModal
        tireData={tireData}
        appointment={appointment}
        open={isReplacementModalOpen}
        data={tyreReplacementModalData}
        onRequestClose={handleModalClose}
      />
    </div>
  );
};
