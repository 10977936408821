import { useQueryClient } from "@tanstack/react-query";
import { Grid, GridColumn } from "semantic-ui-react";

import { DatePicker, DealersAndLocationsDropdown, NavPortal } from "components";
import { useUser } from "hooks";
import { ExportDataButton, ExportDataModal, LeadTypeFilter, ResetFiltersButton, SearchFilter } from "modules/LeadsDashboard/components";
import { useExportModal } from "modules/LeadsDashboard/hooks";
import { CustomDateRangeInput } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import "modules/LeadsDashboard/Leads/CarsLeads/components/CarsLeadsFilters/CarsLeadsFilters.scss";
import { useCarsFilterHandlers } from "modules/LeadsDashboard/Leads/CarsLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { exportLeadsCars } from "modules/LeadsDashboard/service";

export type LeadsCarsFilter = {
  page: number;
  date_from: Date;
  date_to: Date;
  dealer_ids: number[];
  location_ids: number[];
  is_lease_company: boolean | null;
  search_term: string;
};

type CarsLeadsFiltersProps = {
  filters: LeadsCarsFilter;
};

export const CarsLeadsFilters = ({ filters: { search_term, dealer_ids, location_ids, date_from, date_to } }: CarsLeadsFiltersProps) => {
  const user = useUser();
  const queryClient = useQueryClient();
  const carsFilters = queryClient.getQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters);

  const { handleCloseExportModal, handleOpenExportModal, showExportModal } = useExportModal();
  const { handleDateRangeChange, handleDealerLocationChange, handleResetFilters, handleSearchChange } = useCarsFilterHandlers();
  const hasMultipleLocationsSelected = carsFilters ? carsFilters?.location_ids?.length > 1 : false;
  const hasMultipleDealersSelected = carsFilters ? carsFilters?.dealer_ids?.length > 1 : false;

  return (
    <div className="CarsLeadsFilters">
      <NavPortal>
        <SearchFilter value={search_term} handleSearchChange={handleSearchChange} />
      </NavPortal>

      <div className="toolbar-container">
        <Grid columns={16} className="toolbar-grid">
          <GridColumn width={4} className="no-left-gutter right-gutter">
            <LeadTypeFilter />
          </GridColumn>
          <GridColumn width={4} className="left-gutter right-gutter">
            <DatePicker fluid selectsRange onDateChange={handleDateRangeChange} startDate={date_from} endDate={date_to} customInput={<CustomDateRangeInput />} />
          </GridColumn>
          <GridColumn width={8} className="left-gutter no-right-gutter">
            <DealersAndLocationsDropdown
              values={{ dealerIds: dealer_ids, locationIds: location_ids }}
              handleOnChange={({ dealerIds, locationIds }) => handleDealerLocationChange({ dealerIds, locationIds })}
            />
          </GridColumn>
        </Grid>

        <div className="toolbar-reset-export-buttons-container">
          <ResetFiltersButton handleReset={handleResetFilters} />
          {user?.csv_download && <ExportDataButton handleOnClick={handleOpenExportModal} hasWarning={hasMultipleLocationsSelected || hasMultipleDealersSelected} />}
        </div>
      </div>

      {carsFilters && <ExportDataModal open={showExportModal} onClose={handleCloseExportModal} onExportData={() => exportLeadsCars(carsFilters)} />}
    </div>
  );
};
