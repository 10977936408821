import moment from "moment";
import { ReactElement } from "react";
import { Icon, Label, Popup } from "semantic-ui-react";

import { DATE_FORMATS } from "components";
import { Appointment } from "models";
import "modules/Dayplanner/components/AppointmentCard/components/InterventionQuickView/InterventionQuickView.scss";

type InterventionQuickViewProps = {
  appointment: Appointment;
  popupTrigger: ReactElement;
};

type ReadyStatusProps = {
  isFixed: boolean;
};

const ReadyStatus = ({ isFixed }: ReadyStatusProps) => {
  if (isFixed)
    return (
      <Label style={{ backgroundColor: "white", color: "white" }}>
        <Icon color="green" className="check" />
      </Label>
    );

  return (
    <Label style={{ backgroundColor: "white", color: "white" }}>
      <Icon color="red" className="x" />
    </Label>
  );
};

export const InterventionQuickView = ({ appointment, popupTrigger }: InterventionQuickViewProps) => {
  if (!appointment.interventions) return popupTrigger;

  const content = appointment.interventions.map(i => (
    <div className="InterventionQuickView-interventions-container" key={i.id}>
      <div className="title-description-container">
        <div className="title">{i.title}</div>
      </div>

      <div className="special-indicators-container">
        {i.labor_minutes ? (
          <span className="labor-minutes">
            <Icon className="clock" />
            {moment.duration(i.labor_minutes, "minutes").format(DATE_FORMATS.time, { trim: false })}
          </span>
        ) : null}
        <span className="AnswerReadyStatus">{i.customer_ok && <ReadyStatus isFixed={i.mechanic_fixed} />}</span>
      </div>
    </div>
  ));

  return <Popup trigger={popupTrigger} content={<div className="InterventionQuickView-content">{content}</div>} hoverable style={{ padding: "0 5px" }} />;
};
