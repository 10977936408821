import { forwardRef } from "react";

import "modules/LeadsDashboard/Leads/CarsLeads/components/CustomDateRangeInput/CustomDateRangeInput.scss";

type CustomDateRangeInputProps = {
  value?: string;
  onClick?: () => void;
};

export const CustomDateRangeInput = forwardRef<HTMLButtonElement, CustomDateRangeInputProps>(({ onClick, value }, ref) => {
  const [startDate, endDate] = value?.split(" - ") || ["", ""];

  return (
    <div className="CustomDateRangeInput">
      <button onClick={onClick} ref={ref} className="button">
        <div className="dates-container">
          <div className="date-container">
            <span className="date-text">{startDate}</span>
          </div>
          <div className="dates-divider" />
          <div className="date-right-container">
            <span className="date-text">{endDate} </span>
          </div>
        </div>
      </button>
    </div>
  );
});

CustomDateRangeInput.displayName = "CustomDateRangeInput";
