import { useTranslation } from "react-i18next";

import { useDealersLocations } from "hooks";
import { KioskCommunication } from "models";
import { Agreements, CommunicationEvents, Services, Signature } from "modules/AppointmentDetails/components/KioskCommunicationLog/components";
import { ITranslation } from "util/interfaces";

type ContentProps = {
  comm: KioskCommunication;
};

export const Content = ({ comm }: ContentProps) => {
  const t = useTranslation().t as ITranslation;
  const { selectedLocation } = useDealersLocations();

  const { events, agreed_interventions, agreements } = comm;

  return (
    <>
      <CommunicationEvents comm={comm} events={events} />
      <Services services={agreed_interventions} title={t("v8_accepted_scheduled_services").message || "Accepted scheduled services"} vat={selectedLocation?.vat} />
      <Agreements agreements={agreements} />
      <Signature events={events} />
    </>
  );
};
