import { ReactElement, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItemProps, Icon } from "semantic-ui-react";

import "components/LocationDropdown/LocationDropdown.scss";
import { useDealersLocations } from "hooks";
import { DealerLocationDropdownOption, ITranslation } from "util/interfaces";

export const LocationDropdown = (): ReactElement => {
  const [searchTerm, setSearchTerm] = useState("");
  const { selectedLocation, dealerAndLocationsGroupedOptions, handleOnLocationChange, selectedDealer } = useDealersLocations(searchTerm);
  const [showDropdown, setShowDropdown] = useState(false);
  const t = useTranslation().t as ITranslation;
  const dropdownPlaceholder =
    selectedLocation && selectedDealer ? `${selectedDealer.name} - ${selectedLocation.name}` : t("v8_select_location").message || "Select a location";

  const handleSearch = (e: SyntheticEvent<HTMLElement, Event>) => {
    const target = e.target as HTMLInputElement;
    setSearchTerm(target.value);
  };

  const handleDropdownItemClick = (e: SyntheticEvent<Element, Event>, option: DealerLocationDropdownOption) => {
    e.stopPropagation();
    handleOnLocationChange(e, option);
    setSearchTerm("");
    setShowDropdown(false);
  };

  const handleCloseDropdown = () => {
    setSearchTerm("");
    setShowDropdown(false);
  };

  return (
    <div className="DealerLocationDropdown-wrapper">
      <Dropdown
        button
        className={`LocationDropdown icon -scrollable-dropdown nav-bar-map`}
        floating
        onClick={() => setShowDropdown(true)}
        labeled
        icon={
          <div className="location-placeholder-icon">
            <Icon className="location dot regular" />
          </div>
        }
        noResultsMessage={t("v8_no_locations").message || "No locations"}
        placeholder={dropdownPlaceholder}
        renderLabel={(option: DropdownItemProps) => ({ content: option.labelText, icon: option.icon })}
        upward={false}
        fluid={false}
        scrolling
        searchQuery={searchTerm}
        search
        open={showDropdown}
        onClose={handleCloseDropdown}
        onSearchChange={handleSearch}
      >
        <Dropdown.Menu className="location-dropdown-menu">
          {dealerAndLocationsGroupedOptions.map(option => (
            <Dropdown.Item
              onClick={e => handleDropdownItemClick(e, option)}
              active={selectedLocation?.id === Number(option.value)}
              key={option.key}
              disabled={option.disabled}
              className={`locationItem ${option.isLastLocation ? "last-location-item" : ""}`}
            >
              <Icon className={option.icon} />
              <span className={option.icon === "house" ? "dealer-dropdown-option" : ""}>{option.text}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
