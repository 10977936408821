import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { LocationLicenseOverviewKeys } from "modules/LocationLicenseOverview/queryKeys";
import { PaginatedHistoricalInvoices, PdfDownloadArgs } from "modules/LocationLicenseOverview/types";
import ApiInstance from "util/Api";
import { IBackendQueryKey, queryKeys } from "util/keyFactory";

export const useInvoices = (paginationUrl = "", currentInvoicePage = 0) => {
  const { data: paginatedInvoices }: { data: Record<number, PaginatedHistoricalInvoices> | undefined } = useQuery({ queryKey: [queryKeys.exact.paginatedInvoices] });
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const downloadInvoice = async (requestData: PdfDownloadArgs) => {
    const config = { responseType: "blob" };
    const res = await ApiInstance.post("/invoices/download", requestData, ENV.exactBaseUrl, config);
    return res;
  };

  const getInvoices = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    if (paginatedInvoices && currentInvoicePage in paginatedInvoices) return paginatedInvoices[currentInvoicePage] as PaginatedHistoricalInvoices;

    const { baseUrl, endpoint, params } = queryKey[0];
    const res = await ApiInstance.post(endpoint, params, baseUrl);
    const result = res.data as PaginatedHistoricalInvoices;
    result.results?.sort((a, b) => moment(b.Modified, "DD/MM/YYYY").diff(moment(a.Modified, "DD/MM/YYYY")));

    const updatedPaginatedInvoices = { ...paginatedInvoices };
    updatedPaginatedInvoices[currentInvoicePage] = result;
    queryClient.setQueryData(LocationLicenseOverviewKeys.paginatedInvoices, updatedPaginatedInvoices);

    return result;
  };

  const invoicesQuery = useQuery({
    queryKey: LocationLicenseOverviewKeys.invoices(
      paginationUrl
        ? { dealer_location_id: selectedLocation?.id, pagination_url: paginationUrl, page_index: currentInvoicePage }
        : { dealer_location_id: selectedLocation?.id }
    ),
    queryFn: getInvoices,
    retry: false,
    enabled: !!selectedLocation?.id
  });

  return {
    invoices: invoicesQuery.data,
    isLoadingInvoices: invoicesQuery.isLoading,
    downloadInvoice,
    paginatedInvoices
  };
};
