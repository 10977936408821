import { faCalendar, faClock, faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { DATE_FORMATS } from "components";
import { Appointment } from "models";
import { ITranslation } from "util/interfaces";

type AppointmentInfoType = {
  appointment: Appointment;
};

export const AppointmentInfo = ({ appointment }: AppointmentInfoType) => {
  const t = useTranslation().t as ITranslation;

  return (
    <div className="info-section">
      <div className="section-header">
        <span>{t("v8_appointment_information").message || "Appointment information"}</span>
      </div>
      <div className="section-content">
        <Grid>
          <Grid.Column width={4}>
            <span className="icon-text">
              {t("v8_wo_nr").message || "WO"} <FontAwesomeIcon icon={faHashtag} className="i-green mr-5" />
            </span>
            {appointment.wo_nr}
          </Grid.Column>

          <Grid.Column width={4}>
            <span className="icon-text">
              {t("v8_reg").message || "Reg."} <FontAwesomeIcon icon={faHashtag} className="i-green mr-5" />
            </span>
            {appointment.reg_number}
          </Grid.Column>

          {appointment.due_in && (moment.isMoment(appointment.due_in) || !appointment.due_in.startsWith("0001-01-01T00:00:00")) ? (
            <>
              <Grid.Column width={4}>
                <FontAwesomeIcon icon={faCalendar} className="i-green mr-5" />
                {moment(appointment.due_in).format(DATE_FORMATS.dateMonthYear)}
              </Grid.Column>

              <Grid.Column width={4}>
                <FontAwesomeIcon icon={faClock} className="i-green mr-5" />
                {moment(appointment.due_in).format(DATE_FORMATS.time)}
              </Grid.Column>
            </>
          ) : (
            appointment.time_car_app &&
            (moment.isMoment(appointment.time_car_app) || !appointment.time_car_app.startsWith("0001-01-01T00:00:00")) && (
              <Grid.Column width={4}>
                <FontAwesomeIcon icon={faCalendar} className="i-green mr-5" />
                {moment(appointment.time_car_app).format(DATE_FORMATS.dateMonthYear)}
              </Grid.Column>
            )
          )}
        </Grid>
      </div>
    </div>
  );
};
