import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Dropdown, DropdownDivider, DropdownItem, DropdownMenu, Icon } from "semantic-ui-react";

import { Can, LocationDropdown, ProgressBar } from "components";
import "components/NavBar/NavBar.scss";
import { appointmentDetailRegex, carDetailRegex, getRouteProperties, leadsRegex } from "components/NavBar/routes";
import { openZohoWidget } from "components/NavBar/utils";
import { useAuth, useDealersLocations, useNavAction, useUser } from "hooks";
import { PATHS } from "router/paths";
import ApiInstance from "util/Api";
import { ITranslation } from "util/interfaces";

type NavBarProps = {
  openSidebar?: () => void;
};

const dealerReportLanguageSupportLinks = {
  en: "https://support.claireit.eu/portal/en/kb/articles/article-17-1-2020",
  nl: "https://support.claireit.eu/portal/en/kb/articles/dealer-rapport",
  fr: "https://support.claireit.eu/portal/en/kb/articles/rapports-concessionnaire"
};

const locationReportLanguageSupportLinks = {
  en: "https://support.claireit.eu/portal/en/kb/articles/analysing-new-reports-for-single-location",
  nl: "https://support.claireit.eu/portal/en/kb/articles/locatie-rapporten",
  fr: "https://support.claireit.eu/portal/en/kb/articles/rapports-atelier"
};

const leadsSupportLinks = {
  en: "https://support.claireit.eu/portal/en/kb/articles/optimizing-the-leads-feature",
  nl: "https://support.claireit.eu/portal/en/kb/articles/optimaliseren-van-de-leads-functie",
  fr: "https://support.claireit.eu/portal/en/kb/articles/optimisation-de-la-fonction-leads"
};

type SupportedLanguages = keyof typeof dealerReportLanguageSupportLinks;

export const NavBar = ({ openSidebar }: NavBarProps) => {
  const location = useLocation();
  const action = useNavAction();
  const user = useUser();
  const history = useHistory();
  const { selectedLocation } = useDealersLocations();
  const { logout } = useAuth();
  const t = useTranslation().t as ITranslation;

  const pathsToHideDealerLocationsDropdowns = [appointmentDetailRegex, leadsRegex];
  const hideDealerLocationsDropdowns = pathsToHideDealerLocationsDropdowns.some(path => path.test(location.pathname));

  const profilePicture = user?.profile_picture;
  const profileName = `${user?.first_name || ""} ${user?.last_name || ""}`;

  const isDayPlannerEnabled = !carDetailRegex.test(location.pathname) && selectedLocation?.is_dayplanner_enabled;
  const isNotExcludedPath = ![
    PATHS.DAYPLANNER,
    PATHS.ACCOUNT,
    PATHS.DEALER_DOCUMENTATION,
    PATHS.DEALER_REPORTS,
    PATHS.LOCATION_REPORTS,
    PATHS.LOCATION_LICENSE_OVERVIEW,
    PATHS.LEADS_DASHBOARD
  ].includes(location.pathname);
  const currentLanguage: SupportedLanguages = i18next.language?.split("-")?.[0] as SupportedLanguages;
  const route = getRouteProperties(t, location);

  const dealerReportHelpLink = dealerReportLanguageSupportLinks[currentLanguage] || dealerReportLanguageSupportLinks.en;
  const locationReportHelpLink = locationReportLanguageSupportLinks[currentLanguage] || locationReportLanguageSupportLinks.en;
  const leadsHelpLink = leadsSupportLinks[currentLanguage] || leadsSupportLinks.en;

  return (
    <div className={`nav-bar-wrapper ${route.defaultClassname}`}>
      <div className="nav-bar-content">
        <ProgressBar />
        <div className="nav-bar-start">
          {!ApiInstance.loggedInWithQrCode && (
            <div className="pointer" onClick={openSidebar}>
              <Icon name="bars" color="black" />
            </div>
          )}
          {route.title}
        </div>

        <div id="navbar-portal-container" />

        <div className="nav-bar-start">
          {!ApiInstance.loggedInWithQrCode && (
            <>
              {!hideDealerLocationsDropdowns && <LocationDropdown />}
              {action && (
                <Link className="button" to="#" onClick={action.onClick}>
                  <Icon className="arrows rotate regular i-grey" />
                </Link>
              )}
              {appointmentDetailRegex.test(location.pathname) && (
                <div className="pointer" onClick={() => window.open(PATHS.DEALER_DOCUMENTATION, "_blank")}>
                  <Icon className="book open cover" color="black" />
                </div>
              )}
              {location.pathname === PATHS.DEALER_REPORTS && (
                <Link className="button" to="#" onClick={() => window.open(dealerReportHelpLink, "_blank")}>
                  <Icon className="question solid" />
                </Link>
              )}
              {location.pathname === PATHS.LOCATION_REPORTS && (
                <Link className="button" to="#" onClick={() => window.open(locationReportHelpLink, "_blank")}>
                  <Icon className="question solid" />
                </Link>
              )}
              {location.pathname === PATHS.LEADS_DASHBOARD && (
                <Link className="button" to="#" onClick={() => window.open(leadsHelpLink, "_blank")}>
                  <Icon className="question solid" />
                </Link>
              )}
              {isDayPlannerEnabled && isNotExcludedPath && (
                <Can I="browse" the="dayplanner-page">
                  <Link className="button" to={PATHS.DAYPLANNER}>
                    <Icon className="square kanban regular i-grey" />
                  </Link>
                </Can>
              )}
              {location.pathname === PATHS.DAYPLANNER && (
                <Link className="button" to={PATHS.APPOINTMENTS}>
                  <Icon className="list solid regular i-grey" />
                </Link>
              )}
            </>
          )}
          <div className="pointer">
            <Dropdown
              trigger={
                profilePicture ? (
                  <img title={profileName} className="small_profile_icon" src={profilePicture} />
                ) : (
                  <Icon title={profileName} className="circle user small_icon" />
                )
              }
              icon={null}
            >
              <DropdownMenu className="account_dropdown" direction="left">
                <DropdownItem className="profile_details">
                  {profilePicture ? (
                    <img title={profileName} className="profile_avatar" src={profilePicture} />
                  ) : (
                    <Icon title={profileName} size="huge" className="circle user" />
                  )}
                  <h1 className="mt-15">{profileName}</h1>
                  <p>{user?.email}</p>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem text={t("v8_account_settings").message || "Account Settings"} value="account_settings" onClick={() => history.push(PATHS.ACCOUNT)} />
                <DropdownDivider />
                <DropdownItem text={t("v8_tickets_help_center").message || "Tickets & Help Center"} value="tickets_help_center" onClick={openZohoWidget} />
                <DropdownDivider />
                <DropdownItem text={t("v8_logout").message || "Log out"} value="Logout" onClick={() => logout()} />
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};
