import { Icon } from "semantic-ui-react";

import { QUESTION_STATUSES } from "models";
import "modules/LeadsDashboard/components/QuestionStatusIcon/QuestionStatusIcon.scss";

type Props = {
  status: QUESTION_STATUSES | null;
};

const questionStatusIconMap: Partial<Record<QUESTION_STATUSES, { icon: string; color: string }>> = {
  [QUESTION_STATUSES.OK]: { icon: "file", color: "#21BA45" },
  [QUESTION_STATUSES.OKWithRemarks]: { icon: "file", color: "#21BA45" },
  [QUESTION_STATUSES.Advice]: { icon: "circle exclamation", color: "#FF0202" },
  [QUESTION_STATUSES.Necessary]: { icon: "triangle exclamation", color: "#FFBA39" }
};

export const QuestionStatusIcon = ({ status }: Props) => (
  <div className="question-status-card-user">
    {status && (
      <div className={`${questionStatusIconMap[status]?.icon === "file" && "question-status-card-icon-ok-container"}`}>
        <Icon
          className={`${questionStatusIconMap[status]?.icon} question-status-card-icon ${questionStatusIconMap[status]?.icon === "file" && "question-status-card-icon-ok"}`}
          style={{ color: questionStatusIconMap[status]?.color, fontSize: "1rem" }}
        />
      </div>
    )}
  </div>
);
