import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { DATE_FORMATS, ReactTable } from "components";
import { QUESTION_STATUSES } from "models";
import { QualityCheckReportData } from "modules/DealerReports/components/DealerQualityChecksTabular";
import "modules/DealerReports/components/DealerQualityChecksTabular/DealerQualityChecksTabular.scss";
import { questionStatusLookup } from "modules/DealerReports/components/DealerQualityChecksTabular/hooks";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

type Props = {
  reportData: QualityCheckReportData[];
};

export const DealerQualityChecksTable = ({ reportData }: Props) => {
  const t = useTranslation().t as ITranslation;
  const history = useHistory();

  if (reportData.length === 0) {
    return (
      <div className="Table__no-results">
        <p>{t("v8_no_data").message || "No data"}</p>
      </div>
    );
  }

  const onAppointmentClick = (appointment_id: number) => history.push(`${PATHS.APPOINTMENTS}/${appointment_id}`);

  const columnHelper = createColumnHelper<QualityCheckReportData>();
  const columns = [
    columnHelper.accessor(row => row.location, {
      id: "location",
      header: t("v8_location").message || "Location",
      cell: info => <span>{info.renderValue()}</span>,
      size: 100
    }),
    columnHelper.accessor(row => row.wo_date, {
      id: "wo_date",
      header: t("v8_wo_data").message || "WO Data",
      cell: info => <span>{info.renderValue() ? moment(info.renderValue()).format(DATE_FORMATS.dateMonthYear) : ""}</span>,
      size: 100
    }),
    columnHelper.accessor(row => row, {
      id: "wo#",
      header: t("v8_wo").message || "WO #",
      cell: ({ getValue }) => {
        const { appointment_id, wo_nr } = getValue();
        return (
          <span className="wo-nr" onClick={() => onAppointmentClick(appointment_id)}>
            {wo_nr}
          </span>
        );
      },
      size: 100
    }),
    columnHelper.accessor(row => row.check_date, {
      id: "check_data",
      header: t("v8_check_date").message || "Check Date",
      cell: info => {
        const checkDate = info.renderValue();
        return <span>{checkDate ? moment(checkDate).format(DATE_FORMATS.dateMonthYear) : ""}</span>;
      },
      size: 100
    }),
    columnHelper.accessor(row => row.mechanic, {
      id: "mechanic",
      header: t("v8_mechanic").message || "Mechanic",
      cell: info => <span> {info.renderValue()}</span>,
      size: 100
    }),
    columnHelper.accessor(row => row.qc_status, {
      id: "qc_status",
      header: t("v8_qc_status").message || "QC Status",
      cell: info => {
        const value: QUESTION_STATUSES | null = info.renderValue();
        const qcStatus = questionStatusLookup.find(element => element.key === value);
        return <span> {qcStatus ? t(qcStatus.translationKey).message || qcStatus.defaultMessage : ""}</span>;
      },
      size: 100
    }),
    columnHelper.accessor(row => row.qc_officer, {
      id: "qc_officer",
      header: t("v8_qc_officer").message || "QC Officer",
      cell: info => <span> {info.renderValue()}</span>,
      size: 100
    }),
    columnHelper.accessor(row => row.qc_checklist, {
      id: "qc_checklist",
      header: t("v8_qc_checklist").message || "QC Checklist",
      cell: info => <span> {info.renderValue()}</span>,
      size: 150
    }),
    columnHelper.accessor(row => row.ok_with_note_remark, {
      id: "ok_with_note_remark",
      header: t("v8_ok_with_note_remark").message || "OK With Note",
      cell: info => <span> {info.renderValue()}</span>,
      size: 150
    }),
    columnHelper.accessor(row => row.advised_remark, {
      id: "advised_remark",
      header: t("v8_advised_remark").message || "Advised Remarks",
      cell: info => <span> {info.renderValue()}</span>,
      size: 150
    }),
    columnHelper.accessor(row => row.critical_remark, {
      id: "critical_remark",
      header: t("v8_critical_remark").message || "Critical Remarks",
      cell: info => <span> {info.renderValue()}</span>,
      size: 150
    })
  ];

  return (
    <div className="table-container">
      <ReactTable columns={columns} data={reportData} />
    </div>
  );
};
