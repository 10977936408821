import { useState } from "react";

import { NOTE_TYPES, NoteSuggestion, NoteType } from "models";
import { NoteTypeWithSuggestion, updateNoteSuggestionMutation, useGetDealerLocationData } from "modules/NoteSuggestions/service";
export interface NoteSuggestionsType extends NoteType {
  icon: string;
  translation_key: string;
  default_message: string;
  note_suggestions: NoteSuggestion[];
}

export const noteTypeLookup = {
  [NOTE_TYPES.CallCustomer]: { icon: "phone", translation_key: "v8_call_customer_note", default_message: "Customer note" },
  [NOTE_TYPES.RemarkPin]: { icon: "thumbtack", translation_key: "v8_remark_pin", default_message: "Remark pin" },
  [NOTE_TYPES.WarrantyPin]: { icon: "shield alternate", translation_key: "v8_warranty_pin", default_message: "Warranty pin" },
  [NOTE_TYPES.RecallPin]: { icon: "registered", translation_key: "v8_recall_pin", default_message: "Recall pin" },
  [NOTE_TYPES.Parking]: { icon: "square parking", translation_key: "v8_parking_note", default_message: "Parking note" }
};
export const useNoteSuggestions = () => {
  const [modalId, setModalId] = useState<NOTE_TYPES | null>(null);
  const { mutate: updateNoteSuggestion, isPending: isUpdatingNoteSuggestion } = updateNoteSuggestionMutation();

  const { location, isLoading } = useGetDealerLocationData();

  const notes: NoteTypeWithSuggestion[] = [...(location?.note_types || [])].map((noteType: NoteType) => {
    const matchingNoteInfo = noteTypeLookup[noteType.note_type_id as NOTE_TYPES];
    const matchingSuggestions = [...(location?.note_suggestions || [])].filter((suggestion: NoteSuggestion) => suggestion.note_type_id === noteType.note_type_id);
    return { note_type: { ...noteType }, ...matchingNoteInfo, note_suggestions: matchingSuggestions };
  });

  const openModal = (id: NOTE_TYPES) => setModalId(id);
  const closeModal = () => setModalId(null);

  const handleNoteVisibility = (id: NOTE_TYPES) => {
    const matchingNoteType = notes.find(e => e.note_type.note_type_id === id);
    if (matchingNoteType) {
      matchingNoteType.note_type.active = !matchingNoteType.note_type.active;
      updateNoteSuggestion(matchingNoteType);
    }
  };

  return { isLoading, modalId, notes, openModal, closeModal, handleNoteVisibility, isUpdatingNoteSuggestion };
};
