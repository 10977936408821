import { Link } from "react-scroll";
import { Dropdown, Menu } from "semantic-ui-react";

import { useOverflowTabs } from "components/Tabs/hooks/useOverflowTabs";
import "components/Tabs/Tabs.scss";

export type TabData = {
  id: string;
  title?: string;
  data?: any;
  icon?: React.ReactNode;
};

type TabsProps = {
  data: Map<string, TabData>;
  offset?: number;
  hideBorder?: boolean;
};

export const Tabs = ({ data, offset = -100, hideBorder }: TabsProps) => {
  const { canSpy, setActiveTab, activeTab, handleTabClick, tabsContainerRef, visibleTabs, overflowTabs } = useOverflowTabs(data);

  const renderTab = ([_key, item]: [string, TabData]) => {
    if (!item.data) return null;

    const count = Array.isArray(item.data) ? item.data.length : typeof item.data === "object" ? Object.keys(item.data).length : 0;
    const fullTitle = item.title ? `${item.title} ${count > 0 ? `(${count})` : ""}` : "";

    return (
      <Link key={item.id} to={item.id} spy={canSpy} smooth={true} offset={offset} duration={500} onSetActive={() => setActiveTab(item.id)} containerId="containerId">
        <Menu.Item active={item.id === activeTab} onClick={() => handleTabClick(item.id)} title={fullTitle}>
          {item.title ? (
            fullTitle
          ) : (
            <>
              {item.icon} {count > 0 && <span>({count})</span>}
            </>
          )}
        </Menu.Item>
      </Link>
    );
  };

  return (
    <div ref={tabsContainerRef} className={`tabs-container ${hideBorder ? "hideBorder" : ""}`} style={{ position: "relative" }}>
      <Menu pointing secondary color="green">
        {visibleTabs.map(renderTab)}

        {overflowTabs.length > 0 && (
          <Dropdown item icon="ellipsis horizontal" className="overflow-dropdown" pointing="top right" style={{ marginLeft: "auto" }}>
            <Dropdown.Menu>
              {overflowTabs.map(([_key, item]) => (
                <Link
                  key={item.id}
                  to={item.id}
                  spy={canSpy}
                  smooth={true}
                  offset={offset}
                  duration={500}
                  onSetActive={() => setActiveTab(item.id)}
                  containerId="containerId"
                >
                  <Menu.Item className="dropdown-menu-item" active={item.id === activeTab} onClick={() => handleTabClick(item.id)}>
                    {item.title || item.icon}
                  </Menu.Item>
                </Link>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Menu>
    </div>
  );
};

export default Tabs;
