import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxProps, Dropdown } from "semantic-ui-react";

import { DealersAndLocationsDropdown } from "components";
import { useUser } from "hooks";
import { DOCUMENTATION_CATEGORIES, ROLES } from "models";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import "modules/DealerDocumentations/DealerDocumentations.scss";
import { CategoryOptions } from "modules/DealerDocumentations/hooks";
import { ITranslation } from "util/interfaces";

type Props = { categoryOptions: CategoryOptions[] };

export const DealerDocumentationsFilters = ({ categoryOptions }: Props) => {
  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const { categoryId, visibleToMechanic, selectedDealersLocations, handleSetCategoryId, handleSetSelectedDealersLocations, handleSetVisibleToMechanic } =
    useDealerDocumentationContext();

  const canSelectDealers = !!(user?.role_id !== ROLES.Mechanic);

  return (
    <>
      <DealersAndLocationsDropdown
        values={selectedDealersLocations}
        handleOnChange={handleSetSelectedDealersLocations}
        allowSelectDealers={canSelectDealers}
        allowSelectAllDealers={canSelectDealers}
      />
      <Dropdown
        noResultsMessage={t("v8_no_categories").message || "No categories"}
        placeholder={t("v8_select_category").message || "Select a category"}
        fluid
        selection
        clearable
        value={categoryId}
        options={categoryOptions}
        selectOnBlur={false}
        onChange={(_, { value }) => handleSetCategoryId(value as DOCUMENTATION_CATEGORIES)}
      />
      <div className="dealer-toggle-container">
        <span> {t("v8_visible_for_mechanic").message || "Visible for mechanic"} </span>
        <Checkbox
          toggle
          className="checkbox-is-visible"
          checked={visibleToMechanic}
          onChange={(_, { checked }: CheckboxProps) => handleSetVisibleToMechanic(!!checked)}
        />
      </div>
    </>
  );
};
