import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, Can, CustomConfirm } from "components";
import { Car, CarTyres, STATUS_IDENTIFIER } from "models";
import "modules/CarDetails/components/Tyres/Tyres.scss";
import { AppointmentHistory, useDeleteTyres } from "modules/CarDetails/hooks";
import { getTyreSeasonIcon } from "util/common";
import { ITranslation } from "util/interfaces";

type TyresProps = {
  car: Car;
  lastAppointment?: AppointmentHistory;
};
type CarTyreInfo = CarTyres | any;

export const Tyres = ({ car, lastAppointment }: TyresProps) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const { on_car_tyres: onCarTyres, in_storage_tyres: inStorageTyres } = car as { on_car_tyres: CarTyreInfo; in_storage_tyres: CarTyreInfo };
  const { mutate: deleteTyreMutation, isPending: isDeleting, openPortalDBB } = useDeleteTyres();
  const t = useTranslation().t as ITranslation;

  const isDeleteButtonDisabled = lastAppointment?.appointment_status_identifier === STATUS_IDENTIFIER.CarCheckStartedStatus;

  async function handleTyreDelete() {
    if (!car?.has_dbb) return setIsOpenDeleteModal(true);
    openPortalDBB(lastAppointment);
  }

  const renderTyreHeader = (title: string) => {
    return (
      <div className="header">
        <h4>{title}</h4>
      </div>
    );
  };

  const renderLeftTyreDetails = (profile: number, tyre: any) => {
    return (
      <div>
        <div className={`flex-row small-gap tyre-details-font start`}>
          <div className="tyre-indicator">
            <p>L</p>
          </div>
          <p>{`${profile} mm`}</p>
        </div>
        <div className="tyre-details tyre-details-font">
          <p>
            {tyre.manufacturer}
            <span>
              <Icon className={getTyreSeasonIcon(tyre.season)} />
            </span>
          </p>
          <p>{tyre.description}</p>
        </div>
      </div>
    );
  };

  const renderRightTyreDetails = (profile: number, tyre: any) => {
    return (
      <div>
        <div className="flex-row small-gap end tyre-details-font">
          <p>{`${profile} mm`}</p>

          <div className="tyre-indicator">
            <p>R</p>
          </div>
        </div>
        <div className="tyre-details tyre-details-font right">
          <p>
            <span>
              <Icon className={getTyreSeasonIcon(tyre.season)} />
            </span>
            {tyre.manufacturer}
          </p>
          <p>{tyre.description}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        {onCarTyres && (
          <div className="Tyres">
            <h4>{t("v8_on_car").message || "ON CAR"}</h4>
            <div className="flex-row">
              <div className="tyre-container">
                {renderTyreHeader(t("v8_front_tyres").message || "Front tyres")}
                <div className="flex-row padding">
                  {renderLeftTyreDetails(onCarTyres.fl_profile, onCarTyres.front_left_tyre)}
                  {renderRightTyreDetails(onCarTyres.fr_profile, onCarTyres.front_right_tyre)}
                </div>
              </div>

              <div className="tyre-container">
                {renderTyreHeader(t("v8_rear_tyres").message || "Rear tyres")}
                <div className="flex-row padding">
                  {renderLeftTyreDetails(onCarTyres.rl_profile, onCarTyres.rear_left_tyre)}
                  {renderRightTyreDetails(onCarTyres.rr_profile, onCarTyres.rear_right_tyre)}
                </div>
              </div>
            </div>
          </div>
        )}

        {inStorageTyres && (
          <div className="Tyres">
            <div className="delete-tyre-title-wrapper">
              <h4 className="inStorageTitle">{(t("v8_in_storage").message || "In Storage").toUpperCase()}</h4>
              {inStorageTyres && (
                <Can I="remove-storage-set" the="tyres">
                  <Button className="delete-tyre-btn" disabled={isDeleteButtonDisabled} onClick={handleTyreDelete} type="button" color="light">
                    <Icon className="trash red" />
                  </Button>
                </Can>
              )}
            </div>
            <div className="flex-row">
              <div className="tyre-container">
                {renderTyreHeader(t("v8_front_tyres").message || "Front tyres")}
                <div className="flex-row padding">
                  {renderLeftTyreDetails(inStorageTyres.fl_profile, inStorageTyres.front_left_tyre)}
                  {renderRightTyreDetails(inStorageTyres.fr_profile, inStorageTyres.front_right_tyre)}
                </div>
              </div>

              <div className="tyre-container">
                {renderTyreHeader(t("v8_rear_tyres").message || "Rear tyres")}
                <div className="flex-row padding">
                  {renderLeftTyreDetails(inStorageTyres.rl_profile, inStorageTyres.rear_left_tyre)}
                  {renderRightTyreDetails(inStorageTyres.rr_profile, inStorageTyres.rear_right_tyre)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomConfirm
        type={CUSTOM_CONFIRM_TYPES.Danger}
        isOpen={isOpenDeleteModal}
        isLoading={isDeleting}
        handleCancel={() => setIsOpenDeleteModal(false)}
        handleConfirm={() => {
          setIsOpenDeleteModal(false);
          deleteTyreMutation(car.id);
        }}
        confirmMsg={t("v8_delete_documentation_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action."}
      />
    </>
  );
};
