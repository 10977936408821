import moment from "moment";
import React from "react";
import DatePicker, { DatePickerProps } from "react-datepicker";
import { useTranslation } from "react-i18next";

import "components/Calendar/Calendar.scss";
import { DATE_FORMATS } from "components/DatePicker";
import { ITranslation } from "util/interfaces";

export const Calendar: React.FC<DatePickerProps> = props => {
  const t = useTranslation().t as ITranslation;

  return (
    <DatePicker
      className="CalendarInput"
      calendarClassName="CalendarDatepicker"
      todayButton={<span>{t("v8_today").message || "Today"}</span>}
      calendarStartDay={1}
      showPopperArrow={false}
      value={`${props.startDate ? moment(props.startDate).format(DATE_FORMATS.dateMonthYear) : ""} ${props.endDate ? `- ${moment(props.endDate).format(DATE_FORMATS.dateMonthYear)}` : ""}`}
      {...props}
    />
  );
};
