import { SemanticCOLORS } from "semantic-ui-react";

import {
  AppointmentNote,
  AppointmentStatusHistory,
  Car,
  Check,
  Checklist,
  CommunicationAgreement,
  Customer,
  CustomerCommunication,
  DealerLocation,
  DeskCommunication,
  FinalCheckImage,
  FinalCheckVideo,
  Intervention,
  KeylockerCommunication,
  KeylockerRemark,
  KioskCommunication,
  STATUS_IDENTIFIER,
  User
} from "models";
import { AcsesCommunication } from "models/AcsesCommunication";
import ApiLoadable from "models/ApiLoadable";

export class Appointment extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  dms_nr: string = "";
  dms_created_atd: string = "";
  wo_nr: string = "";
  ref_nr: string = "";
  importer_version: string = "";
  dms_status: string = "";
  car_check_started: boolean = false;
  car_profile_picture: string = "";
  last_user_profile_picture: string = "";
  check_paused_at: string | null = null;
  customer_waiting: boolean = false;
  is_local: boolean = false;
  is_money: boolean = false;
  is_star: boolean = false;
  is_star_color: SemanticCOLORS | string = "";
  is_shop: boolean = false;
  is_shop_color: SemanticCOLORS | string = "";
  is_pinned: boolean = false;
  is_pinned_manually: boolean = false;
  has_panic: boolean = false;
  has_extra_check: boolean = false;
  final_check_has_remarks: boolean = false;
  is_recurring?: boolean = false;
  move_count: number = 0;
  next_date: string = "";
  time_car_app: string = "";
  car_return_time: string | null = null;
  car_in_shop: string | null = null;
  car_out_of_shop: string | null = null;
  car_out_of_shop_set_by?: User;
  due_in: string | null = null;
  car_return_time_edited: boolean = false;
  dms_billed_updated: boolean = false;
  check_in_com_sent: boolean = false;
  check_in_com_answered: boolean = false;
  check_in_com_to_handle: boolean = false;
  last_dms_update: string | null = null;
  internal: boolean = false;
  extra_parts_list_id: number | null = null;
  extra_check_id: number | null = null;
  appointment_status_id: number = 0;
  customer_driver_id: number | null = null;
  customer_driver: Customer | null = null;
  customer_owner_id: number | null = null;
  customer_owner: Customer | null = null;
  customer_contract_id: number | null = null;
  customer_contract: Customer | null = null;
  dealer_location_id: number = 0;
  dealer_location?: DealerLocation;
  acses_communication?: AcsesCommunication | undefined = undefined;
  car_id: number = 0;
  vin_nr: string = "";
  car_model: string = "";
  car_make: string = "";
  reg_number: string = "";
  reg_number_esaped: string = "";
  current_km: number = 0;
  dms_km: number = 0;
  next_km: number = 0;
  car_apk_date: string | null = null;
  driver_initials: string = "";
  driver_title: string = "";
  driver_firstname: string = "";
  driver_surname: string = "";
  contractor_initials: string = "";
  contractor_title: string = "";
  contractor_firstname: string = "";
  contractor_surname: string = "";
  owner_initials: string = "";
  owner_title: string = "";
  owner_firstname: string = "";
  owner_surname: string = "";
  company: string = "";
  appointment_status_identifier: STATUS_IDENTIFIER = STATUS_IDENTIFIER.NewCarStatus;
  last_timestamp: string | null = null;
  last_user: string = "";
  num_approved_items_not_fixed: number = 0;
  has_dbb: boolean = false;
  dbb_appointment_date: string | null = null;
  car_check_dbb_status: number = 0;
  final_car_check_dbb_status: number = 0;
  car?: Car;
  customer_communication?: CustomerCommunication;
  dealer_name?: string;
  location_name?: string;
  location_phone?: string;
  dealer_domain_name?: string = "";
  pin_icon_color: string = "";
  warranty_pin_count: number = 0;
  key_dropped_at: string = "";
  key_picked_up_at: string = "";
  sharebox_key_dropped_at: string = "";
  sharebox_key_picked_up_at: string = "";
  sharebox_key_picked_up_by?: User;
  key_dropped_back_at: string = "";
  key_picked_up_back_at: string = "";
  key_picked_up_by?: User;
  key_dropped_back_by?: User;
  key_picked_up_back_by?: User;
  sharebox_key_dropped_back_at: string = "";
  sharebox_key_picked_up_back_at: string = "";
  sharebox_key_dropped_back_by?: User;
  sharebox_key_picked_up_back_by?: User | null = null;
  assigned_mechanic: number | null = null;
  assigned_mechanic_order: number | null = null;
  last_assigned_mechanic: number | null = null;
  planning_work_start: string | null = null;
  planning_work_stop: string | null = null;
  car_in_shop_set_by?: User | null = null;
  keylocker_remark?: KeylockerRemark;
  reg_number_escaped: string = "";
  is_lease_company: boolean = false;
  planning_mechanic: User | null = null;
  kiosk_checked_in_at: string = "";
  acses_key_dropped_at: string = "";
  acses_key_picked_up_at: string = "";
  acses_key_dropped_back_at: string = "";
  acses_key_picked_up_back_at: string = "";
  acses_key_picked_up_by?: User;
  acses_key_dropped_back_by?: User;
  acses_key_picked_up_back_by?: User;
  car_hu_date: string = "";
  kiosk_label_number: number = 0;
  warranty_pin_signature_missing_count: number = 0;
  warranty_pin_claim_nr_missing_count: number = 0;
  warranty_pin_support_nr_missing_count: number = 0;
  recall_pin_count: number = 0;
  recall_pin_support_nr_missing_count: number = 0;
  recall_pin_claim_nr_missing_count: number = 0;
  remark_pin_count: number = 0;
  check_initiator_name: string = "";
  check_initiator_email: string = "";
  last_receptionist_name: string = "";
  last_receptionist_profile_picture: string = "";
  receptionist_key?: string;
  due_in_time?: string;
  customer_owner_name?: string;
  customer_owner_profile_picture?: string;
  last_user_at: string | null = null;
  notes?: AppointmentNote[];
  online_checkin_agreements?: CommunicationAgreement[];
  diagnose_overview_agreements?: CommunicationAgreement[];
  final_check_images?: FinalCheckImage[];
  final_check_videos?: FinalCheckVideo[];
  status_history?: AppointmentStatusHistory[];
  interventions?: Intervention[];
  checks?: Check[];
  checklists?: Checklist[];
  desk_communications?: DeskCommunication[];
  keylocker_communications?: KeylockerCommunication[];
  kiosk_communications?: KioskCommunication[];
  predicted_next_date?: string | null = null;
  customer_ok_user?: User | null = null;
}

export class AppointmentAttachment extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  name: string = "";
  url: string = "";
  type: string = "";
  username: string = "";
  user_id: number = 0;
  appointment_id: number = 0;
}

export class CarCheckHistory extends ApiLoadable {
  private _appointments?: CarCheckAppointment[] | null = null;

  get appointments(): CarCheckAppointment[] {
    return this._appointments ? this._appointments : [];
  }

  set appointments(appointments: CarCheckAppointment[] | null) {
    this._appointments = appointments;
  }
}

export class CarCheckAppointment extends ApiLoadable {
  id: number = 0;
  date: string = "";
  current_km: number = 0;
  next_km: number = 0;
  next_date: string = "";
  private _checklists?: CarCheckChecklist[] | null = null;

  get checklists(): CarCheckChecklist[] {
    return this._checklists ? this._checklists : [];
  }

  set checklists(checklists: CarCheckChecklist[] | null) {
    this._checklists = checklists;
  }
}

export class CarCheckChecklist extends ApiLoadable {
  name: string = "";
  photo_count: number = 0;
  a_count: number = 0;
  a_count_sold: number = 0;
  n_count: number = 0;
  n_count_sold: number = 0;
}
