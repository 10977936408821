import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import "components/DatePicker/DatePicker.scss";
import { CustomDatePickerInput } from "modules/LeadsDashboard/components";
import { ITranslation } from "util/interfaces";

type Props = {
  onChange: (dates: [Date | null, Date | null]) => void;
  dateStart: Date | null;
  dateEnd: Date | null;
  clearable?: boolean;
};

export const TableDatePicker = ({ dateStart, dateEnd, onChange, clearable = false }: Props) => {
  const t = useTranslation().t as ITranslation;
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([dateStart, dateEnd]);

  const [startDate, endDate] = dateRange;
  const handleClear = () => {
    setDateRange([null, null]);
    onChange([null, null]);
  };
  const handleChange = (dates: [Date | null, Date | null]) => {
    setDateRange(dates);

    if (dates[1]) {
      setIsOpen(false);
      onChange([moment(dates[0]).endOf("day").toDate(), moment(dates[1]).endOf("day").toDate()]);
    }
  };

  return (
    <DatePicker
      calendarClassName="CalendarDatepicker"
      open={isOpen}
      selectsRange
      onInputClick={() => setIsOpen(true)}
      onClickOutside={() => setIsOpen(false)}
      startDate={startDate ? startDate : undefined}
      endDate={endDate ? endDate : undefined}
      onChange={handleChange}
      customInput={<CustomDatePickerInput />}
    >
      {clearable && (
        <div className="react-datepicker-clear-date-container">
          <Button
            content={t("v8_clear_date_range").message || "Clear date range"}
            icon="calendar"
            labelPosition="left"
            onClick={handleClear}
            className="react-datepicker-clear-date-button"
          />
        </div>
      )}
    </DatePicker>
  );
};
