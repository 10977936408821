import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import "components/Notes/AddUpdateNoteHeader/AddUpdateNoteHeader.scss";
import { APPOINTMENT_NOTE_TYPES, CAR_NOTE_TYPE } from "models";
import { ITranslation } from "util/interfaces";

type AddUpdateNoteHeaderProps = {
  type: APPOINTMENT_NOTE_TYPES | CAR_NOTE_TYPE | null;
  isCarNote?: boolean;
  isEditing: boolean;
};

export const AddUpdateNoteHeader = ({ type, isEditing, isCarNote = false }: AddUpdateNoteHeaderProps) => {
  const t = useTranslation().t as ITranslation;
  if (isCarNote)
    return (
      <div className="Add-update-appointment-note-header">
        <Icon className="car" style={{ color: "#EA2D62" }} />
        <span>{isEditing ? t("v8_edit_car_details_note").message || "Edit car details note" : t("v8_add_car_details_note").message || "Add car details note"}</span>
      </div>
    );

  switch (type) {
    case APPOINTMENT_NOTE_TYPES.Wo:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="file alt" style={{ color: "#4283CA" }} />
          <span>{isEditing ? t("v8_edit_note").message || "Edit Note" : t("v8_add_note").message || "Add Note"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Info:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="info" style={{ color: "#4283CA" }} />
          <span>{t("v8_set_info_status").message || "Set Info status"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BackOrder:
      return (
        <div className="Add-update-appointment-note-header">
          <div className="backorder-icon">
            <span>BO</span>
          </div>
          <span>{t("v8_back_order").message || "Back Order"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Main:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="sticky note" color="red" />
          <span>{isEditing ? t("v8_edit_main_note").message || "Edit Main Note" : t("v8_add_main_note").message || "Add Main Note"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.CallCustomer:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="phone alt" style={{ color: "#21BA45" }} />
          <span>
            {isEditing ? t("v8_edit_call_customer_note").message || "Edit Call Customer Note" : t("v8_add_call_customer_note").message || "Add Call Customer Note"}
          </span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.Attachment:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="paperclip" style={{ color: "#4283CA" }} />
          <span>{isEditing ? t("v8_edit_attachment_note").message || "Edit Attachment Note" : t("v8_add_attachment_note").message || "Add Attachment Note"}</span>
        </div>
      );
    case APPOINTMENT_NOTE_TYPES.BillNote:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="file invoice dollar" style={{ color: "#46B046" }} />
          <span>{isEditing ? t("v8_edit_customer_bill").message || "Edit Customer Bill" : t("v8_add_customer_bill").message || "Add Customer Bill"}</span>
        </div>
      );

    case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="steering wheel" color="black" />
          <span>{isEditing ? t("v8_edit_temporary_driver").message || "Edit Temporary Driver" : t("v8_add_temporary_driver").message || "Add Temporary Driver"}</span>
        </div>
      );

    case APPOINTMENT_NOTE_TYPES.RecurringCar:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="exclamation triangle" style={{ color: "#C83628" }} />
          <span>{isEditing ? t("v8_edit_recurring_car").message || "Edit recurring car" : t("v8_add_recurring_car").message || "Add Recurring Car"}</span>
        </div>
      );

    case APPOINTMENT_NOTE_TYPES.Parking:
      return (
        <div className="Add-update-appointment-note-header">
          <Icon className="square parking" style={{ color: "#21A1D0" }} />
          <span>{isEditing ? t("v8_edit_parking_location").message || "Edit parking location" : t("v8_add_parking_location").message || "Add parking location"}</span>
        </div>
      );

    default:
      return <span>{t("v8_add_note").message || "Add Note"}</span>;
  }
};
