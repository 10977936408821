import { ReactElement } from "react";
import { Grid, Icon, Search } from "semantic-ui-react";

import { LocationDropdown } from "components";
import "modules/Styleguide/components/TopBar/TopBar.scss";

export const TopBar = (): ReactElement => {
  return (
    <div id="header" className="Header">
      <Grid className="-contained-large">
        <Grid.Column className="Header__column -brand-column" width={4}>
          <Icon name="content" />
          <div className="Header__brand">
            <span>Claire V8</span>
          </div>
        </Grid.Column>
        <Grid.Column className="Header__column -search-column" width={5}>
          <div id="search-root" className="Header__search">
            <Search placeholder="Start searching appointments..." aligned="right" />
          </div>
        </Grid.Column>
        <Grid.Column className="Header__column -filter-column " width={7} floated="right" textAlign="right">
          <LocationDropdown />
          <Icon className="refresh regular" style={{ color: "white", fontSize: "18px", marginLeft: "70px", marginRight: "15px" }} />
          <Icon className="alarm snooze regular" style={{ color: "white", fontSize: "18px", marginRight: "15px" }} />
          <Icon className="user circle regular" style={{ color: "white", fontSize: "18px", marginRight: "15px" }} />
          <Icon className="question circle regular" style={{ color: "white", fontSize: "18px", marginRight: "15px" }} />
        </Grid.Column>
      </Grid>
    </div>
  );
};
