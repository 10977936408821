import { Appointment, Car, CommunicationAgreement, CommunicationResult, Customer, User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum DESK_COMMUNICATION_STATUS {
  DeskCheckInSent = 1,
  DeskCheckInReceived,
  DeskCheckInFailedToOpen,
  DeskCheckInOpened,
  DeskCheckInAnswered,
  DeskCheckInClosed
}

export enum DESK_COMMUNICATION_EVENT_TYPE {
  DeskCheckInSent = 1,
  DeskCheckInReceived,
  DeskCheckInFailedToOpen,
  DeskCheckInOpened,
  DeskCheckInCanceled,
  DeskCheckInAnswered
}

export class DeskCommunicationEvent extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  type: DESK_COMMUNICATION_EVENT_TYPE = DESK_COMMUNICATION_EVENT_TYPE.DeskCheckInSent;
  customer_name: string = "";
  customer_signature: string = "";
  desk_communication_id: number = 0;
}

export interface DeskIntervention {
  id: number;
  title: string;
  description: string;
  price: number;
  vat?: number;
  desk_communication_id: number;
}

export class DeskCommunication extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  receptionist_key: string = "";
  status: DESK_COMMUNICATION_STATUS = DESK_COMMUNICATION_STATUS.DeskCheckInSent;
  is_checking_out: boolean = false;
  customer_id: number | null = null;
  appointment_id: number = 0;
  car_id: number = 0;
  receptionist_id: number = 0;
  dealer_location_id: number = 0;

  customer?: Customer | null = null;
  appointment?: Appointment | null = null;
  car?: Car | null = null;
  user?: User | null = null;
  is_receptionist?: boolean = false;

  private _events?: DeskCommunicationEvent[] | null = null;

  get events(): DeskCommunicationEvent[] {
    return this._events ? this._events : [];
  }

  set events(results: DeskCommunicationEvent[] | null | undefined) {
    this._events = results;
  }

  private _agreed_interventions?: DeskIntervention[] | null = null;

  get agreed_interventions(): DeskIntervention[] {
    return this._agreed_interventions ? this._agreed_interventions : [];
  }

  set agreed_interventions(agreed_interventions: DeskIntervention[] | null | undefined) {
    this._agreed_interventions = agreed_interventions;
  }

  private _declined_interventions?: DeskIntervention[] | null = null;

  get declined_interventions(): DeskIntervention[] {
    return this._declined_interventions ? this._declined_interventions : [];
  }

  set declined_interventions(declined_interventions: DeskIntervention[] | null | undefined) {
    this._declined_interventions = declined_interventions;
  }

  private _agreements?: CommunicationAgreement[] | null = null;

  get agreements(): CommunicationAgreement[] {
    return this._agreements ? this._agreements : [];
  }

  set agreements(agreements: CommunicationAgreement[] | null | undefined) {
    this._agreements = agreements;
  }

  private _results?: CommunicationResult[] | null = null;

  get results(): CommunicationResult[] {
    return this._results ? this._results : [];
  }

  set results(results: CommunicationResult[] | null | undefined) {
    this._results = results;
  }

  settings?: Record<string, any>[] | null = null;
}
