import { OnChangeFn, PaginationState, SortingState, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { Pagination } from "components";
import "components/ReactTable/ReactTable.scss";
import { ITranslation } from "util/interfaces";

interface ReactTableProps {
  data: any;
  columns: any[];
  renderEmptyRow?: boolean;
  emptyRowMessage?: string;
  customHeader?: ReactNode;
  state?: { columnVisibility: Record<string, boolean> };
  onRowClick?: (d: any) => void;
  pageCount?: number;
  totalItems?: number;
  pageSize?: number;
  columnSortingEnabled?: boolean;
  pagination?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  overrideTableBodyMessage?: string;
}

export const ReactTable = ({
  data,
  columns,
  renderEmptyRow,
  customHeader,
  emptyRowMessage = "",
  state,
  onRowClick,
  pageCount,
  totalItems,
  pagination,
  pageSize,
  columnSortingEnabled,
  onPaginationChange,
  overrideTableBodyMessage
}: ReactTableProps) => {
  const t = useTranslation().t as ITranslation;
  const [sorting, setSorting] = useState<SortingState>(() => columns.map(c => ({ id: c.accessorKey, desc: false })));

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    ...(pagination
      ? {
          getPaginationRowModel: getPaginationRowModel(),
          state: {
            ...(state || { sorting }),
            pagination
          },
          onPaginationChange,
          manualPagination: true,
          pageCount
        }
      : {
          state: state || { sorting }
        })
  });

  const getColumnSortingState = (columnId: string) => sorting.find(s => s.id === columnId);

  return (
    <div className="ReactTable-wrapper">
      <div className="ReactTable-body">
        <table className="ReactTable">
          <thead>
            {customHeader ||
              table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    const columnSortingState = getColumnSortingState(header.column.id);

                    return (
                      <th style={{ width: header.column.columnDef.size }} key={header.id}>
                        {header.isPlaceholder ? null : (
                          <>
                            {columnSortingEnabled ? (
                              <div className={columnSortingEnabled ? "title-sorting-icons-header" : ""}>
                                <div className="title">{flexRender(header.column.columnDef.header, header.getContext())}</div>

                                <div className="icons">
                                  {columnSortingState?.desc ? (
                                    <Icon className="caret down anchor" onClick={header.column.getToggleSortingHandler()} />
                                  ) : (
                                    <Icon className="caret up anchor" onClick={header.column.getToggleSortingHandler()} />
                                  )}
                                </div>
                              </div>
                            ) : (
                              flexRender(header.column.columnDef.header, header.getContext())
                            )}
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
          </thead>
          <tbody>
            {overrideTableBodyMessage ? (
              <tr>
                <td colSpan={columns.length} className="no-results">
                  <span> {overrideTableBodyMessage} </span>
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map(row => {
                if (onRowClick) {
                  return (
                    <tr key={row.id} className="hover" onClick={() => onRowClick(row.original)}>
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className={`td-${cell.column.id}`}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  );
                }
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} className={`td-${cell.column.id}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
            {!renderEmptyRow && table.getRowModel().rows.length === 0 && (
              <tr>
                <td colSpan={columns.length} className="no-results">
                  <span> {t("v8_no_results").message || "No results"}. </span>
                </td>
              </tr>
            )}
            {renderEmptyRow && table.getRowModel() && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  {emptyRowMessage}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagination && (
        <div className="ReactTablePagination">
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            onPageChange={page => {
              table.setPageIndex(page - 1);
            }}
            pageSize={pageSize || 0}
            totalItems={totalItems || 0}
          />
        </div>
      )}
    </div>
  );
};
