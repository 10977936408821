import { Active, DndContext, Over, PointerSensor, TouchSensor, UniqueIdentifier, rectIntersection, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToWindowEdges, snapCenterToCursor } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import React, { ReactNode, useState } from "react";

import { NoteSuggestion } from "models";
import { DragHandle, SortableItem, SortableOverlay } from "modules/NoteSuggestions/components";
import "modules/NoteSuggestions/components/SortableList/SortableList.scss";

type BaseItem = {
  id: UniqueIdentifier;
};

export type SortableItems = NoteSuggestion & BaseItem;

interface SortableListProps {
  items: SortableItems[];
  onChange: (items: SortableItems[]) => void;
  renderItem: (item: SortableItems, active: Active | null) => ReactNode;
}

export const SortableList = ({ items, onChange, renderItem }: SortableListProps) => {
  const [active, setActive] = useState<Active | null>(null);
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor, { activationConstraint: { delay: 250, tolerance: 100 } }));

  const handleDragStart = (active: Active) => setActive(active);
  const handleOnDragEnd = (active: Active, over: Over | null) => {
    if (over && active.id !== over.id) {
      const activeIndex = items.findIndex(item => item.id === active.id);
      const overIndex = items.findIndex(item => item.id === over.id);
      onChange(arrayMove(items, activeIndex, overIndex));
    }
    setActive(null);
  };

  const handleDragCancel = () => setActive(null);
  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges, snapCenterToCursor]}
      collisionDetection={rectIntersection}
      onDragStart={({ active }) => handleDragStart(active)}
      onDragEnd={({ active, over }) => handleOnDragEnd(active, over)}
      onDragCancel={handleDragCancel}
    >
      <>
        <SortableContext items={items}>
          <ul className="note-container">
            {items.map(item => (
              <React.Fragment key={item.id}>{renderItem(item, active)}</React.Fragment>
            ))}
          </ul>
        </SortableContext>
        <SortableOverlay />
      </>
    </DndContext>
  );
};

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
