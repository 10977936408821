import { Icon } from "semantic-ui-react";

import "modules/LeadsDashboard/components/SnoozeButtonIcon/SnoozeButtonIcon.scss";

type SnoozeStatus = "snoozed" | "notSnoozed";

type Props = {
  handleClick: () => void;
  snoozedStatus: SnoozeStatus;
};

const colorLookup: Record<SnoozeStatus, { backgroundColor: string; color: string }> = {
  notSnoozed: { backgroundColor: "#e2e2e3", color: "#767676" },
  snoozed: { backgroundColor: "#2D84CE", color: "#FFFFFF" }
};

export const SnoozeButtonIcon = ({ handleClick, snoozedStatus }: Props) => (
  <div className="snooze-button-card-user" onClick={handleClick} style={{ backgroundColor: colorLookup[snoozedStatus].backgroundColor }}>
    <div>
      <Icon className="clock snooze-button-card-icon" style={{ color: colorLookup[snoozedStatus].color }} />
    </div>
  </div>
);
