import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";

import { Can, DATE_FORMATS, DetailsCards, MCC, PSA, ROB, TabData } from "components";
import { useDealersLocations } from "hooks";
import { Car } from "models";
import "modules/CarDetails/components/Details/Details.scss";
import { ITranslation } from "util/interfaces";

type DetailsProps = {
  tabData: TabData | undefined;
  car: Car | null;
  car_id: number;
};

export const Details = ({ tabData, car, car_id }: DetailsProps) => {
  const { selectedLocation } = useDealersLocations();
  const t = useTranslation().t as ITranslation;

  const printData = (date: string | null | undefined) => {
    if (date) return moment(date).format(DATE_FORMATS.dateMonthYear);
    return "";
  };

  return (
    <DetailsCards
      containerStyle="mt-15"
      icon="car mirrors"
      title="Details"
      showExpandArrow={false}
      id={tabData?.id}
      rightContent={
        <div className="full end">
          <Can I="wo_widget" the="servicebox">
            <PSA car_id={car_id} />
          </Can>
          <MCC carDetails={car} />
          <Can I="get-contract" the="robnet">
            <ROB car_id={car_id} />
          </Can>
        </div>
      }
    >
      <div className="Details">
        <div className="Details-img">
          {car?.profile_picture ? <img src={car.profile_picture} className="Details-img" /> : <Icon className="image i-grey" size="huge" />}
        </div>
        <div className="Details-container">
          <div className="group">
            <div>
              <p className="title">{t("v8_license_plate").message || "License Plate"}</p>
              <p>{car?.reg_nr}</p>
            </div>
            <div>
              <p className="title">{t("v8_brand").message || "Brand"}</p>
              <p className="title-data">{car?.make}</p>
            </div>
          </div>

          <div className="group">
            <div>
              <p className="title">{t("v8_model").message || "Model"}</p>
              <Popup content={car?.model} trigger={<p>{car?.model}</p>} />
            </div>
            <div>
              <p className="title">{t("v8_engine_number").message || "Engine Number"}</p>
              <Popup content={car?.engine_number} trigger={<p className="data">{car?.engine_number || "NA"}</p>} />
            </div>
          </div>

          {selectedLocation?.apk_visible && (
            <div className="group">
              <div>
                <p className="title">{t("v8_apk").message || "APK"}</p>
                <p className="title-data">{printData(car?.apk_date_dms)}</p>
              </div>
            </div>
          )}

          <div className="group">
            <div>
              <p className="title">{t("v8_vin_chasis_number").message || "Vin/Chasis number"}</p>
              <Popup content={car?.vin_nr} trigger={<p>{car?.vin_nr || "NA"}</p>} />
            </div>
            <div>
              <p className="title">{t("v8_dms_registration").message || "DMS registration"}</p>
              <p className="title-data">{printData(car?.dms_registration)}</p>
            </div>
          </div>

          <div className="group">
            <div>
              <p className="title">{t("v8_warranty_start").message || "Warranty start"}</p>
              <p className="title-data">{printData(car?.warranty_date_start)}</p>
            </div>
            <div>
              <p className="title">{t("v8_warranty_end").message || "Warranty end"}</p>
              <p className="title-data">{printData(car?.warranty_date_end)}</p>
            </div>
          </div>

          <div className="group">
            <div>
              <p className="title">{t("v8_extended_warranties").message || "Extended warranties"}</p>
              <p className="title-data">{printData(car?.warranty_extended)}</p>
            </div>
            <div>
              <p className="title">{t("v8_extended_road_warranties").message || "Extended road warranties"}</p>
              <p className="title-data">{printData(car?.warranty_extended_road)}</p>
            </div>
          </div>
        </div>
      </div>
    </DetailsCards>
  );
};
