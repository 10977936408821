import { createElement, useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";

import { TabData } from "components";
import { AppointmentHistory, useAppointmentHistory, useCarDetails, useCustomerInfo, usePinItems, useSnoozeItems } from "modules/CarDetails/hooks";
import { getPinWithHistory, getSnoozeWithHistory } from "modules/CarDetails/utils";

export const useTabData = (id: number) => {
  const [appointmentHistory, setAppointmentHistory] = useState<AppointmentHistory[]>([]);

  const { car } = useCarDetails(id);
  const { appointments: appointmentHistoryResponse } = useAppointmentHistory(id);
  const { data: pinItems = [] } = usePinItems(id);
  const { data: snoozedItems = [] } = useSnoozeItems(id);
  const { data: customerInfo, fetchCustomerInfo, itemsNotRepaired } = useCustomerInfo();

  const pinItemsWithHistory = getPinWithHistory(pinItems);
  const snoozeItemsWithHistory = getSnoozeWithHistory(snoozedItems);

  useEffect(() => {
    const historyData = appointmentHistoryResponse?.sort((a1: AppointmentHistory, a2: AppointmentHistory) => a2.date.localeCompare(a1.date)) ?? [];

    setAppointmentHistory(historyData);
    fetchCustomerInfo(appointmentHistoryResponse);
  }, [appointmentHistoryResponse?.length]);

  const [tabsData, setTabsData] = useState<Map<string, TabData>>(
    new Map([
      ["details", { id: "details", icon: createElement(Icon, { className: "car mirrors" }), data: [] }],
      ["carNotes", { id: "carNotes", icon: createElement(Icon, { className: "note sticky" }), data: [] }],
      ["customerInfo", { id: "customerInfo", icon: createElement(Icon, { className: "user tag" }) }],
      ["snoozedItems", { id: "snoozedItems", icon: createElement(Icon, { className: "clock" }) }],
      ["pinItems", { data: [], id: "pinItems", icon: createElement(Icon, { className: "bars" }) }],
      ["itemsNotRepaired", { id: "itemsNotRepaired", icon: createElement(Icon, { className: "clock rotate left" }) }],
      ["appointmentHistory", { id: "appointmentHistory", icon: createElement(Icon, { className: "calendar clock" }) }],
      ["tyres", { id: "tyres", icon: createElement(Icon, { className: "tire" }) }],
      ["drivingTrend", { id: "drivingTrend", icon: createElement(Icon, { className: "chart simple" }) }]
    ])
  );

  useEffect(() => {
    setTabsData((prev: Map<string, TabData>) => {
      const tabs = new Map<string, TabData>([...prev]);
      const carNotes = car?.notes || [];

      if (snoozeItemsWithHistory.length > 0) {
        const snoozedItemsTab = tabs.get("snoozedItems");
        if (snoozedItemsTab) {
          snoozedItemsTab.data = snoozeItemsWithHistory.length ? snoozeItemsWithHistory : null;
        }
      }
      if (pinItemsWithHistory.length > 0) {
        const pinItemsTab = tabs.get("pinItems");
        if (pinItemsTab) {
          pinItemsTab.data = pinItemsWithHistory.length ? pinItemsWithHistory : null;
        }
      }
      if (itemsNotRepaired.length > 0) {
        const itemsNotRepairedTab = tabs.get("itemsNotRepaired");
        if (itemsNotRepairedTab) {
          itemsNotRepairedTab.data = itemsNotRepaired.length ? itemsNotRepaired : null;
        }
      }

      const carNotesTab = tabs.get("carNotes");
      if (carNotesTab) {
        carNotesTab.data = carNotes.length ? carNotes : [];
      }

      if (appointmentHistory.length > 0) {
        const appointmentHistoryTab = tabs.get("appointmentHistory");
        if (appointmentHistoryTab) {
          appointmentHistoryTab.data = appointmentHistory.length ? appointmentHistory : null;
        }
      }

      const customerInfoTab = tabs.get("customerInfo");
      if (customerInfoTab) {
        customerInfoTab.data = customerInfo || {};
      }

      const tyresTab = tabs.get("tyres");
      if ((!!car?.truck_tyres?.length || !!car?.on_car_tyres || !!car?.in_storage_tyres) && tyresTab) {
        tyresTab.data = [];
      }

      if (appointmentHistory.length > 0) {
        const drivingTrendTab = tabs.get("drivingTrend");
        if (drivingTrendTab) {
          drivingTrendTab.data = [];
        }
      }

      return tabs;
    });
  }, [snoozeItemsWithHistory.length, pinItemsWithHistory.length, itemsNotRepaired.length, appointmentHistory.length, customerInfo, car]);

  return { tabsData };
};
