import { useEffect } from "react";

import { useDealersLocations } from "hooks";
import { CarsLeadsFilters, CarsLeadsTable } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import { useCarsFiltersQuery } from "modules/LeadsDashboard/Leads/CarsLeads/hooks";
import { useLeadsCarsQuery } from "modules/LeadsDashboard/service";

export const CarsLeads = () => {
  const { locations } = useDealersLocations();
  const { carsFilters } = useCarsFiltersQuery();
  const { refetch } = useLeadsCarsQuery(carsFilters);

  useEffect(() => {
    const hasDealerLocationSelected = carsFilters.location_ids?.length > 0 || carsFilters.dealer_ids?.length > 0;
    const hasDateRangeSelected = carsFilters.date_from && carsFilters.date_to;

    if (hasDealerLocationSelected && hasDateRangeSelected) refetch();
  }, [carsFilters]);

  return (
    <>
      <CarsLeadsFilters filters={carsFilters} />
      <CarsLeadsTable locations={locations} />
    </>
  );
};
