import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DATE_FORMATS } from "components";
import { useDealersLocations } from "hooks";
import { TYRE_TEAM_CHANNEL } from "models";
import { Delivery } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal";
import "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/TyreReplacementModal.scss";
import useTyre from "modules/AppointmentDetails/components/Tyre/hooks/useTyre";
import { ITranslation } from "util/interfaces";

type Props = {
  appointmentId: number;
};

const deliveryChannels: Record<TYRE_TEAM_CHANNEL, string> = {
  [TYRE_TEAM_CHANNEL.WheelTyre]: "Wheel-Tyre",
  [TYRE_TEAM_CHANNEL.BandenExpress]: "Banden Express"
};

export const useTyreTeam = ({ appointmentId }: Props) => {
  const t = useTranslation().t as ITranslation;
  const { deliveryQuery } = useTyre(appointmentId);
  const { selectedLocation } = useDealersLocations();

  const [inStock, setInStock] = useState(true);
  const [delivery, setDelivery] = useState<Delivery[]>([]);
  const isTyreTeamEnabled = selectedLocation?.is_tyre_team_enabled;

  useEffect(() => {
    if (deliveryQuery.data) {
      setDelivery(deliveryQuery.data.filter((delivery: Delivery) => !delivery.id.includes("PICKUP")));
    }
  }, [deliveryQuery.data]);

  const formatTime = (time: string | null) => (time ? moment(time).format(DATE_FORMATS.time) : null);
  const getDeliveryChannel = (channel: TYRE_TEAM_CHANNEL) => deliveryChannels[channel] || t("v8_unknown_channel").message || "Unknown channel";

  const getFormattedDeliveryTimes = (delivery: Delivery) => ({
    order_before: formatTime(delivery.order_before),
    delivery_before: formatTime(delivery.delivery_before),
    delivery_from: formatTime(delivery.delivery_from)
  });

  const getDeliveryLong = (delivery: Delivery) => {
    const { delivery_before, delivery_from, order_before } = getFormattedDeliveryTimes(delivery);

    const deliveryTypes: Record<string, string> = {
      PICKUPSAMEDAY: `${t("v8_tyre_team_pickup_sameday").message || t("v8_pickup_same_day").message || "Pickup same day"} ${order_before} ${delivery_from}`,
      SAMEDAY: `${t("v8_tyre_team_sameday").message || t("v8_same_day").message || "Same day"} ${order_before} ${delivery_before}`,
      PICKUPOVERNIGHT: `${t("v8_tyre_team_pickupovernight").message || t("v8_pickup_overnight").message || "Pickup overnight"} ${order_before} ${delivery_from}`,
      OVERNIGHT: `${t("v8_tyre_team_overnight").message || t("v8_overnight").message || "Overnight"} ${order_before} ${delivery_before}`
    };

    const deliveryType = Object.keys(deliveryTypes).find(key => delivery.id.includes(key));
    return deliveryType ? deliveryTypes[deliveryType] : "";
  };

  const getDelivery = (delivery: string) => {
    const selectedDelivery = deliveryQuery.data?.find((d: Delivery) => d.id === delivery) || {};
    if (!selectedDelivery) return "";

    const { delivery_before, delivery_from, order_before } = getFormattedDeliveryTimes(selectedDelivery);
    const deliveryTypes: Record<string, { trigger: string; content: string }> = {
      PICKUPSAMEDAY: {
        trigger: t("v8_pickup_same_day").message || "Pickup same day",
        content: `${t("v8_tyre_team_pickup_same_day").message || t("v8_pickup_same_day").message || "Pickup same day"} ${order_before} ${delivery_from}`
      },
      SAMEDAY: {
        trigger: t("v8_same_day").message || "Same day",
        content: `${t("v8_tyre_team_same_day").message || t("v8_same_day").message || "Same day"} ${order_before} ${delivery_before}`
      },
      PICKUPOVERNIGHT: {
        content: `${t("v8_tyre_team_pickup_overnight").message || t("v8_pickup_overnight").message || "Pickup overnight"} ${order_before} ${delivery_from}`,
        trigger: t("v8_pickup_overnight").message || "Pickup Overnight"
      },
      OVERNIGHT: {
        content: `${t("v8_tyre_team_overnight").message || t("v8_overnight").message || "Overnight"} ${order_before} ${delivery_before}`,
        trigger: t("v8_overnight").message || "Overnight"
      }
    };

    const type = Object.keys(deliveryTypes).find(key => delivery.includes(key));
    return type ? deliveryTypes[type] : "";
  };

  const getDeliveriesData = () =>
    (deliveryQuery?.data || []).map((item: Delivery) => ({
      key: item,
      value: item,
      text: getDeliveryLong(item)
    }));

  return {
    inStock,
    delivery,
    deliveryQuery,
    isTyreTeamEnabled,
    setInStock,
    getDelivery,
    setDelivery,
    getDeliveriesData,
    getDeliveryChannel
  };
};
