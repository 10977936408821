import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Icon, Loader } from "semantic-ui-react";

import { ReactTable } from "components";
import "modules/LocationLicenseOverview/components/InvoiceTable/InvoiceTable.scss";
import { HistoricalInvoice, PaginatedHistoricalInvoices } from "modules/LocationLicenseOverview/types";
import { ITranslation } from "util/interfaces";

type InvoiceTableProps = {
  invoices: PaginatedHistoricalInvoices | undefined;
  currentInvoicePage: number;
  onInvoiceRowClick: (rowData: HistoricalInvoice) => void;
  onPageChange: (pageIndex: number) => void;
  isLoadingInvoices: boolean;
};

export const InvoiceTable = ({ invoices, onInvoiceRowClick, isLoadingInvoices, currentInvoicePage, onPageChange }: InvoiceTableProps) => {
  const t = useTranslation().t as ITranslation;

  const columnHelper = createColumnHelper<HistoricalInvoice>();
  const columns = [
    columnHelper.accessor(row => row.Subject, {
      id: "subject",
      header: t("v8_invoice_name").message || "Invoice name",
      cell: info => <div>{info.renderValue()}</div>
    }),
    columnHelper.accessor(row => row.Modified, {
      id: "modified",
      header: t("v8_invoice_date").message || "Invoice date",
      cell: info => {
        return <span>{info.renderValue()}</span>;
      }
    }),
    columnHelper.accessor(row => row.AmountFC, {
      id: "amount_fc",
      header: t("v8_total_price").message || "Total price",
      cell: info => <div>{`€ ${info.renderValue()}`}</div>
    })
  ];

  if (isLoadingInvoices) return <Loader active inline size="small" />;

  return (
    <div className="InvoiceTable">
      <ReactTable
        columns={columns}
        data={invoices?.results || []}
        renderEmptyRow={!invoices?.results?.length}
        emptyRowMessage={t("v8_no_invoices_found").message || "No invoices found"}
        onRowClick={onInvoiceRowClick}
      />
      <div className="ReactTablePagination">
        <div className="pagination-container">
          <div className="pagination-controls">
            <button className="nav-button" disabled={currentInvoicePage === 0} onClick={() => onPageChange(currentInvoicePage - 1)}>
              <Icon className="angle left" />
            </button>
            <button className="nav-button" disabled={!invoices?.__next} onClick={() => onPageChange(currentInvoicePage + 1)}>
              <Icon className="angle right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
