import { PublicClientApplication } from "@azure/msal-browser";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Divider, Form, Icon, Input } from "semantic-ui-react";

import ENV from "config/Env";
import { useAuth } from "hooks";
import "modules/Auth/components/Login/Login.scss";
import QrLogin from "modules/Auth/components/QrLogin";
import { PATHS } from "router/paths";
import { getBrowserLanguage } from "util/common";
import { ITranslation } from "util/interfaces";

type LoginProps = {
  isModalOpen?: boolean;
};

export const Login = ({ isModalOpen = false }: LoginProps) => {
  const t = useTranslation().t as ITranslation;
  const clientId = ENV.microsoftClientID;
  const redirectUri = window.location.href;

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [googleButtonWidth, setGoogleButtonWidth] = useState(0);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [credentials, setCredentials] = useState({ username: "", password: "" });

  const msalInstance = new PublicClientApplication({
    auth: { ...(redirectUri && { redirectUri }), clientId, navigateToLoginRequestUrl: false },
    cache: { ...{ cacheLocation: "sessionStorage" } }
  });

  const { loginWithCredentials, loginWithGoogle, loginWithMicrosoft } = useAuth();
  const { mutate: googleLogin } = loginWithGoogle;
  const { mutate: microsoftLogin, isPending: microsoftLoading } = loginWithMicrosoft;
  const { mutate: credentialsLogin, isPending: credentialsLoading, error: credentialsError } = loginWithCredentials;

  const onSetButtonWidth = () => {
    const loginButton = document.getElementById("google-login-button");
    setGoogleButtonWidth(loginButton ? loginButton.clientWidth : 341);
  };

  useEffect(() => {
    setErrorMsg("");
  }, [credentials]);

  useEffect(() => {
    onSetButtonWidth();
    window.addEventListener("resize", onSetButtonWidth);
    return () => {
      window.removeEventListener("resize", onSetButtonWidth);
    };
  }, []);

  useEffect(() => {
    if (credentialsError) setErrorMsg(t(credentialsError.message)?.message || credentialsError.message);
  }, [credentialsError]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (credentials.password && credentials.username) return credentialsLogin(credentials);
    setErrorMsg(t("v8_fill_username_password").message || "Fill in username and password");
  };

  const handleGoogleResponse = (token: CredentialResponse) => {
    if (!token) {
      return;
    }
    if (token.credential) {
      googleLogin({ id_token: token.credential });
    }
  };

  const handleMicrosoftResponse = async () => {
    try {
      const scopes = ["openid", "email", "profile", "user.read"];
      if (!msalInstance) return null;

      await msalInstance.initialize();
      const data = await msalInstance.loginPopup({ scopes });

      if (data?.idToken) microsoftLogin({ id_token: data.idToken });
    } catch (e: Error | unknown) {
      let errMessage = "";
      if (e instanceof Error) {
        errMessage = e.message;
      } else {
        errMessage = t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.";
      }
      toast.error(errMessage);
    }
  };

  return (
    <div className="LoginContainer">
      <Form onSubmit={onSubmit}>
        <Form.Field error={!!errorMsg}>
          <Input
            name="username"
            id="username"
            value={credentials.username}
            onChange={onInputChange}
            icon={`user ${errorMsg ? "" : "green"}`}
            iconPosition="left"
            type="text"
            placeholder={t("v8_login").message || "Login"}
            error={!!errorMsg}
            autoComplete="username"
          />
        </Form.Field>
        <div className="mt-15">
          <Form.Field className="password" error={!!errorMsg}>
            <Input
              name="password"
              id="password"
              value={credentials.password}
              onChange={onInputChange}
              icon={`key ${errorMsg ? "" : "green"}`}
              iconPosition="left"
              placeholder={t("v8_password").message || "Password"}
              type={showPassword ? "text" : "password"}
              error={!!errorMsg}
              autoComplete={errorMsg ? "new-password" : "current-password"}
            />
            <Icon
              className={showPassword ? "eye grey password-icon" : "eye slash grey password-icon"}
              onMouseDown={() => setShowPassword(true)}
              onMouseUp={() => setShowPassword(false)}
            />
          </Form.Field>
        </div>

        {!isModalOpen && (
          <div className="mt-15 reset-password-container">
            <Link to={PATHS.RESET_PASSWORD_REQUEST}>{t("v8_forgot_username_password").message || "Forgot username / password?"}</Link>
          </div>
        )}
        <div className="mt-25">
          <Button disabled={credentialsLoading} loading={credentialsLoading} type="submit" id="submit-login" color="green" fluid>
            {t("v8_sign_in").message || "Sign in"}
          </Button>
          {errorMsg && (
            <div className="mt-15">
              <Form.Field error>
                <Input value={errorMsg} readOnly />
              </Form.Field>
            </div>
          )}
        </div>
      </Form>

      <div className="mt-15">
        <Divider />
      </div>
      <div className="mt-15" id="google-login-button">
        <GoogleLogin width={googleButtonWidth} onSuccess={handleGoogleResponse} locale={getBrowserLanguage()} />
      </div>
      <div className="mt-15" id="microsoft-login-button">
        <Button color="light" loading={microsoftLoading} fluid onClick={handleMicrosoftResponse}>
          {t("v8_sign_with_microsoft").message || "Sign in with Microsoft"}
        </Button>
      </div>
      <div className="mt-15">
        <QrLogin loginViaQR />
      </div>
      {isModalOpen && (
        <div className="mt-25">
          <div className="warning-message">
            <p>{t("v8_session_expired_login_again").message || "Your session has expired, please log in again."}</p>
          </div>
        </div>
      )}
    </div>
  );
};
