import _forEach from "lodash/forEach";
import _mapValues from "lodash/mapValues";
import _uniqBy from "lodash/uniqBy";
import { useEffect, useState } from "react";

import { useDealersLocations } from "hooks";
import { Appointment, QuestionResult, SearchTyreReplacement, TYRE_POSITIONS, TYRE_SEASONS, Tyre } from "models";
import { TyreLocationData } from "modules/AppointmentDetails/components";
import { TyreData } from "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal";
import "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/TyreReplacementModal.scss";
import useTyre from "modules/AppointmentDetails/components/Tyre/hooks/useTyre";

interface Props {
  data: QuestionResult | null;
  onRequestClose?: () => void;
  appointment: Appointment;
  tireData: TyreLocationData;
}

const oppositeAxleTireMap: Record<TYRE_POSITIONS, TYRE_POSITIONS> = {
  [TYRE_POSITIONS.CarFrontLeft]: TYRE_POSITIONS.CarFrontRight,
  [TYRE_POSITIONS.CarFrontRight]: TYRE_POSITIONS.CarFrontLeft,
  [TYRE_POSITIONS.CarRearLeft]: TYRE_POSITIONS.CarRearRight,
  [TYRE_POSITIONS.CarRearRight]: TYRE_POSITIONS.CarRearLeft,
  [TYRE_POSITIONS.StorageFrontLeft]: TYRE_POSITIONS.StorageFrontRight,
  [TYRE_POSITIONS.StorageFrontRight]: TYRE_POSITIONS.StorageFrontLeft,
  [TYRE_POSITIONS.StorageRearLeft]: TYRE_POSITIONS.StorageRearRight,
  [TYRE_POSITIONS.StorageRearRight]: TYRE_POSITIONS.StorageRearLeft,
  [TYRE_POSITIONS.TruckLeft]: TYRE_POSITIONS.TruckRight,
  [TYRE_POSITIONS.TruckRight]: TYRE_POSITIONS.TruckLeft,
  [TYRE_POSITIONS.TruckExteriorLeft]: TYRE_POSITIONS.TruckExteriorRight,
  [TYRE_POSITIONS.TruckExteriorRight]: TYRE_POSITIONS.TruckExteriorLeft,
  [TYRE_POSITIONS.TruckInteriorLeft]: TYRE_POSITIONS.TruckInteriorRight,
  [TYRE_POSITIONS.TruckInteriorRight]: TYRE_POSITIONS.TruckInteriorLeft,
  [TYRE_POSITIONS.NotTyre]: TYRE_POSITIONS.NotTyre,
  [TYRE_POSITIONS.TruckSpare]: TYRE_POSITIONS.TruckSpare,
  [TYRE_POSITIONS.TyrePositionLast]: TYRE_POSITIONS.TyrePositionLast
};

const LIMIT_SIZE = 1000;

export const useTyreReplacementModal = ({ data, onRequestClose, appointment, tireData }: Props) => {
  const [size, setSize] = useState<number | null>(data?.tyre?.size || null);
  const [season, setSeason] = useState<TYRE_SEASONS>(TYRE_SEASONS.AllSeason);
  const [width, setWidth] = useState<number | null>(data?.tyre?.width || null);
  const [height, setHeight] = useState<number | null>(data?.tyre?.height || null);
  const [manufacturer, setManufacturer] = useState(data?.tyre?.manufacturer || "");
  const [selection, setSelection] = useState<Record<string, TyreData> | null>(null);
  const [searchResult, setSearchResult] = useState<SearchTyreReplacement[]>([]);

  const { selectedLocation } = useDealersLocations();
  const { brandsQuery, tyreSearchMutation, addTyres } = useTyre(appointment.id);
  const tyreReplacements = data?.tyre_replacements?.map(el => el?.tyre) || [];

  // const { delivery, deliveryQuery, getDeliveriesData, getDelivery, getDeliveryChannel, inStock, isTyreTeamEnabled, setDelivery, setInStock } = useTyreTeam({
  //   appointmentId: appointment.id
  // });

  const resetState = () => {
    setManufacturer("");
    setSeason(TYRE_SEASONS.AllSeason);
    setWidth(null);
    setHeight(null);
    setSize(null);
    setSearchResult([]);
    setSelection(null);
  };

  const getMatchingOppositeTyreReplacements = () => {
    const tireKeys = Object.keys(tireData) as (keyof TyreLocationData)[];
    const tirePosition = data?.tyre_position as keyof typeof oppositeAxleTireMap;
    const tirePositionToMatch = tirePosition ? oppositeAxleTireMap[tirePosition] : TYRE_POSITIONS.NotTyre;

    const matchingTire = tireKeys?.flatMap(location => tireData[location].filter(item => item.tyre_position === tirePositionToMatch)) ?? [];
    return matchingTire?.flatMap(questionResult => questionResult?.tyre_replacements?.map(replacement => replacement?.tyre)) ?? [];
  };

  const setSelectedSelection = () => {
    const allTyreReplacements = [...(tyreReplacements || []), ...getMatchingOppositeTyreReplacements()];
    const searchTyreReplacementsWithEan = allTyreReplacements.filter(tyre => tyre?.ean) as SearchTyreReplacement[];
    const tyreReplacementsWithoutDuplicates = _uniqBy(searchTyreReplacementsWithEan, tyre => tyre?.ean);

    const tyreSelectionData = tyreReplacementsWithoutDuplicates.reduce<Record<string, TyreData>>(
      (acc, element) => {
        const isPreselectedOnOppositeAxle = tyreReplacements.some(el => el?.ean === element?.ean);
        const tyreTeam = tyreReplacementsWithoutDuplicates.find(el => el.id === element?.id) ?? ({} as SearchTyreReplacement);

        if (element?.ean)
          acc[element.ean] = {
            price: data?.price || 0,
            question_result_id: Number(data?.id),
            tyre_team: tyreTeam,
            preselected: isPreselectedOnOppositeAxle,
            tyre_id: element.id
          };

        return acc;
      },
      {} as Record<string, TyreData>
    );

    let preselectedTyres: Record<string, TyreData> = {};

    if (tyreReplacements.length > 0) {
      _forEach(tyreSelectionData, (value, key) => {
        if (value.preselected) preselectedTyres[key] = value;
      });
    } else preselectedTyres = { ...tyreSelectionData };

    setSelection(preselectedTyres);

    const preselectedTyresIds: number[] = Object.keys(tyreSelectionData).reduce((acc, key) => {
      if (tyreSelectionData[key].preselected && tyreSelectionData[key].tyre_id) {
        acc.push(tyreSelectionData[key].tyre_id);
      }
      return acc;
    }, [] as number[]);

    return preselectedTyresIds;
  };

  const updateSearchResultAndSelection = (tyreSearchMutationData: Tyre[] = []) => {
    const preselectedTyres = setSelectedSelection();
    const matchingOppositeTyreReplacements =
      tyreReplacements.length > 0 && preselectedTyres.length > 0
        ? getMatchingOppositeTyreReplacements().filter(element => element?.id && preselectedTyres.includes(element.id))
        : getMatchingOppositeTyreReplacements();

    const allTyreReplacements = [...(tyreReplacements || []), ...matchingOppositeTyreReplacements, ...(tyreSearchMutationData ?? [])];
    const searchTyreReplacementsWithEan = allTyreReplacements.filter(tyre => tyre?.ean !== undefined) as SearchTyreReplacement[];
    const tyreReplacementsWithoutDuplicates = _uniqBy(searchTyreReplacementsWithEan, tyre => tyre?.ean);

    setSearchResult(tyreReplacementsWithoutDuplicates);
  };

  const handleSearch = () => {
    const searchParams = {
      manufacturer,
      season,
      // tyre_team_delivery_ids: delivery.map(item => item.id),
      // in_stock: inStock,
      tyre_team_delivery_ids: [],
      in_stock: true,
      height: height || 0,
      width: width || 0,
      size: size || 0,
      limit: LIMIT_SIZE,
      id: Number(selectedLocation?.id)
    };

    tyreSearchMutation.mutate(searchParams, {
      onSuccess(data) {
        if (!data?.length) {
          setSearchResult([]);
          setSelection({});
        } else {
          updateSearchResultAndSelection(data);
        }
      }
    });
  };

  useEffect(() => {
    if (!data?.tyre) return;

    const tyreData = data.tyre;
    const searchParams = {
      manufacturer: tyreData.manufacturer,
      season: tyreData.season,
      tyre_team_delivery_ids: [],
      in_stock: true,
      height: tyreData.height,
      width: tyreData.width,
      size: tyreData.size,
      limit: LIMIT_SIZE,
      id: Number(selectedLocation?.id)
    };

    setManufacturer(tyreData.manufacturer);
    setSeason(tyreData.season);
    setWidth(tyreData.width);
    setHeight(tyreData.height);
    setSize(tyreData.size);

    tyreSearchMutation.mutate(searchParams, {
      onSuccess(data) {
        if (data?.length) updateSearchResultAndSelection(data);
      }
    });

    return () => {
      resetState();
    };
  }, [data]);

  const addTyreToSelection = (selection: Record<string, TyreData> | null, tyreTeam: SearchTyreReplacement) => {
    if (selection)
      selection[tyreTeam.ean] = {
        tyre_id: tyreTeam.id,
        price: tyreTeam.price || tyreTeam.gross_price || 0,
        question_result_id: Number(data?.id),
        tyre_team: tyreTeam
      };
  };

  const removeTyreFromSelection = (selection: Record<string, TyreData> | null, tyreTeam: SearchTyreReplacement) => {
    if (selection) delete selection[tyreTeam.ean];
  };

  const getUpdatedSelection = (prevSelection: Record<string, TyreData> | null, tyreTeam: SearchTyreReplacement, checked: boolean) => {
    const updatedSelection = { ...prevSelection };
    checked ? addTyreToSelection(updatedSelection, tyreTeam) : removeTyreFromSelection(updatedSelection, tyreTeam);

    return updatedSelection;
  };

  const handleSelect = (index: number, checked: boolean) => {
    const tyreTeam = searchResult[index];
    setSelection(prev => getUpdatedSelection(prev, tyreTeam, checked));
  };

  const handleAddTyre = async () => {
    if (!selection) return;

    const nonPreselectedTyres = Object.keys(selection).reduce((acc: Record<string, TyreData>, key: keyof Record<string, TyreData>) => {
      if (!selection[key].preselected) acc[key] = selection[key];

      return acc;
    }, {});

    addTyres(nonPreselectedTyres).then(() => onRequestClose?.());
  };

  const updateSearchResultPrice = (prevData: SearchTyreReplacement[], index: number, price: string) =>
    prevData.map((item, idx) => (idx === index ? { ...item, price: Number(price) } : item));

  const updateSelectionResultPrice = (prevData: Record<string, TyreData> | null, tyreId: number, price: string) => {
    if (!prevData) return;
    return _mapValues(prevData, tyre => ({ ...tyre, price: tyre.tyre_id === tyreId ? Number(price) : tyre.price }));
  };

  const handlePriceUpdate = (price: string, tyreId: number, index: number) => {
    const updatedSearchResultPrice = updateSearchResultPrice(searchResult, index, price);
    const updatedSelectionPrice = updateSelectionResultPrice(selection, tyreId, price);

    setSearchResult(updatedSearchResultPrice);
    updatedSelectionPrice && setSelection(updatedSelectionPrice);
  };

  const getBrandsData = () => brandsQuery?.data?.map((item: string) => ({ key: item, value: item, text: item })) ?? [];

  return {
    tyreSearchMutation,
    tyreReplacements,
    searchResult,
    selection,
    brandsQuery,
    handleSearch,
    handleSelect,
    handleAddTyre,
    handlePriceUpdate,
    getBrandsData,
    setWidth,
    width,
    setHeight,
    height,
    size,
    setSize,
    manufacturer,
    setManufacturer,
    season,
    setSeason
    // TYRE TEAM TO BE IMPLEMENTED IN THE FUTURE
    // delivery,
    // deliveryQuery,
    // getDeliveriesData,
    // getDelivery,
    // getDeliveryChannel,
    // inStock,
    // isTyreTeamEnabled,
    // setDelivery,
    // setInStock
  };
};
