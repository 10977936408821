import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Icon, Input, Modal, TextArea } from "semantic-ui-react";

import { DATE_FORMATS, DatePicker, UploadFilesZone, UploadedFilesPreview } from "components";
import "components/AppointmentNoteModal/AppointmentNoteModal.scss";
import { AddUpdateNoteHeader } from "components/Notes";
import ENV from "config/Env";
import { useDealersLocations, useUploadAttachment, useUser } from "hooks";
import { APPOINTMENT_NOTE_TYPES, Appointment, AppointmentNote, AppointmentNoteAttachment, NOTE_TYPES, STATUS_IDENTIFIER } from "models";
import { useGetDropdownOptions } from "modules/AppointmentDetails/components/AppointmentNotes/hooks";
import { useAppointmentNotes, useGetNotes } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type AppointmentNoteModalProps = {
  selectedNote: AppointmentNote | null;
  noteType: APPOINTMENT_NOTE_TYPES | null;
  resetNoteType: () => void;
  onSetSelectedAppointmentNote: (note: AppointmentNote | null) => void;
  onUnselectNote: () => void;
  appointment: Appointment;
  onSave?: (noteType: number, id: number) => void;
};

const SuggestionNoteTypeMap = {
  [APPOINTMENT_NOTE_TYPES.Parking]: NOTE_TYPES.Parking,
  [APPOINTMENT_NOTE_TYPES.CallCustomer]: NOTE_TYPES.CallCustomer
};

export const AppointmentNoteModal = ({
  selectedNote,
  noteType,
  resetNoteType,
  onSetSelectedAppointmentNote,
  onUnselectNote,
  appointment,
  onSave
}: AppointmentNoteModalProps) => {
  const t = useTranslation().t as ITranslation;
  const user = useUser();
  const { addAppointmentNote, updateAppointmentNote, deleteAppointmentNote } = useAppointmentNotes(appointment.id);
  const { handleUploadFileAttachment } = useUploadAttachment("/files/upload_appointment_note_attachment", ENV.apiBase);
  const { selectedLocation } = useDealersLocations();
  const { phoneOptions } = useGetDropdownOptions({ appointment, t });

  const [note, setNote] = useState("");
  const [visibleToMechanic, setVisibleToMechanic] = useState(false);
  const [date, setDate] = useState<Date | null>(null);
  const [phoneNr, setPhoneNr] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [attachments, setAttachments] = useState<Partial<AppointmentNoteAttachment>[] | null>(null);
  const [defaultPhoneDropdownValue, setDefaultPhoneDropdownValue] = useState("");
  const [noteDropdownValue, setNoteDropdownValue] = useState<number | undefined>(undefined);
  const [hasValidationError, setHasValidationError] = useState(false);

  const suggestionOptions = useGetNotes(SuggestionNoteTypeMap[noteType as keyof typeof SuggestionNoteTypeMap]);

  useEffect(() => {
    const contact = phoneOptions?.find(item => item.text === phoneNr);
    if (contact) {
      setDefaultPhoneDropdownValue(contact?.value);
    } else if (phoneOptions && phoneOptions.length > 0) {
      setDefaultPhoneDropdownValue(phoneOptions[phoneOptions?.length - 1].value || "other");
    }
  }, [phoneNr, phoneOptions]);

  useEffect(() => {
    const note = selectedNote?.note || "";
    const visibleToMechanic = selectedNote?.visible_for_mechanic || false;
    const date = selectedNote?.backorder_date ? moment(selectedNote.backorder_date).toDate() : moment().toDate();
    const phoneNr = selectedNote?.phone_nr || "";
    const name = selectedNote?.name || "";
    const email = selectedNote?.email || "";
    const attachments = selectedNote?.attachments || null;

    setNote(note);
    setVisibleToMechanic(visibleToMechanic);
    setDate(date);
    setPhoneNr(phoneNr);
    setName(name);
    setEmail(email);
    setAttachments(attachments);
  }, [selectedNote]);

  const handleResetAppointmentNotesState = () => {
    setNote("");
    setVisibleToMechanic(false);
    setDate(null);
    setPhoneNr("");
    setName("");
    setEmail("");
    setAttachments(null);
    setHasValidationError(false);
  };

  const handleModalClose = () => {
    resetNoteType();
    setHasValidationError(false);
    handleResetAppointmentNotesState();
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.name === "phoneNr") setPhoneNr(evt.target.value);
    if (evt.target.name === "name") setName(evt.target.value);
    if (evt.target.name === "email") setEmail(evt.target.value);
  };

  const handleTextAreaChange = (data: string) => {
    setNote(data);
  };

  const handleCheckboxChange = (checked: boolean | undefined) => {
    setVisibleToMechanic(!!checked);
  };

  const handleChangeDate = (date: Date | null) => {
    setDate(date);
  };

  const handleDropdownChange = (name: string, value: string | number | boolean | (string | number | boolean)[] | undefined) => {
    if (name === "phoneNr") {
      const [selectedCustomerPhone] = String(value).split(";");
      setPhoneNr(selectedCustomerPhone);
    }
  };

  const handleUploadAttachment = async (file: File) => {
    if (!file) return;

    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });
    const formData = new FormData();

    const date = moment(appointment.created_on).format(DATE_FORMATS.yearMonthDateSlash);
    const path = `${selectedLocation?.id}_${selectedLocation?.name}/${date}/${appointment.id}_${appointment.wo_nr}`;

    formData.append("file", uploadFile);
    formData.append("path", path);

    const attachmentUrl = await handleUploadFileAttachment({ formData });

    if (attachmentUrl) {
      const newFile = {
        url: attachmentUrl,
        type: file.type || extension,
        name: file.name,
        created_on: moment().utc().format(DATE_FORMATS.ISOFormat),
        updated_on: moment().utc().format(DATE_FORMATS.ISOFormat),
        username: `${user?.first_name || ""} ${user?.last_name || ""}`
      };

      if (noteType === APPOINTMENT_NOTE_TYPES.BillNote) {
        setAttachments([newFile]);
      } else {
        setAttachments(attach => (attach ? [...attach, newFile] : [newFile]));
      }
    }
  };

  const handleDeleteAttachment = (url: string) => {
    if (attachments?.length) setAttachments(attach => (attach ? attach.filter(attachment => attachment.url !== url) : null));
  };

  const handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files) {
      [...evt.target.files].forEach(file => {
        handleUploadAttachment(file);
      });
    }
  };

  const handleFileDragOver = (evt: React.DragEvent<HTMLInputElement>) => {
    evt.preventDefault();
  };

  const handleFileDrop = (evt: React.DragEvent<HTMLInputElement>) => {
    evt.preventDefault();

    [...evt.dataTransfer.files].forEach(file => {
      handleUploadAttachment(file);
    });
  };

  const handleConstructCreateNoteRequest = (type: APPOINTMENT_NOTE_TYPES) => {
    switch (type) {
      case APPOINTMENT_NOTE_TYPES.Info:
        return {
          path: "/appointments/new_status",
          payload: {
            appointment_id: appointment.id,
            new_status_identifier: STATUS_IDENTIFIER.DiagnoseStatus,
            sa_remarks: note,
            note_attachments: attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.BackOrder:
        return {
          path: "/appointments/new_status",
          payload: {
            appointment_id: appointment.id,
            new_status_identifier: STATUS_IDENTIFIER.BackOrderStatus,
            sa_remarks: note,
            back_order_time_car_app: date,
            note_attachments: attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.Wo:
        return { path: "/notes/wo/create", payload: { appointment_id: appointment.id, note, visible_for_mechanic: visibleToMechanic, attachments } };
      case APPOINTMENT_NOTE_TYPES.Main:
        return { path: "/notes/main/create", payload: { appointment_id: appointment.id, note, visible_for_mechanic: visibleToMechanic, attachments } };
      case APPOINTMENT_NOTE_TYPES.CallCustomer:
        return {
          path: "/notes/call/create",
          payload: { appointment_id: appointment.id, note, phone_nr: phoneNr, visible_for_mechanic: visibleToMechanic, attachments }
        };
      case APPOINTMENT_NOTE_TYPES.BillNote:
        return { path: "/notes/bill/create", payload: { appointment_id: appointment.id, note, attachment: attachments?.[0] } };
      case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
        return { path: "/notes/temp_driver/create", payload: { appointment_id: appointment.id, note, name, email, phone_nr: phoneNr, attachments } };
      case APPOINTMENT_NOTE_TYPES.RecurringCar:
        return { path: "/notes/recurring_car/create", payload: { appointment_id: appointment.id, note, attachments } };
      case APPOINTMENT_NOTE_TYPES.Attachment:
        return { path: "/notes/attachment/create", payload: { appointment_id: appointment.id, note, attachments } };
      case APPOINTMENT_NOTE_TYPES.Parking:
        return { path: "/notes/parking_note/create", payload: { appointment_id: appointment.id, note } };
      default:
        return null;
    }
  };

  const handleConstructUpdateNoteRequest = (type: APPOINTMENT_NOTE_TYPES) => {
    switch (type) {
      case APPOINTMENT_NOTE_TYPES.Info:
        return {
          path: "/appointments/new_status",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            new_status_identifier: STATUS_IDENTIFIER.DiagnoseStatus,
            sa_remarks: note,
            note_attachments: attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.BackOrder:
        return {
          path: "/appointments/new_status",

          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            new_status_identifier: STATUS_IDENTIFIER.BackOrderStatus,
            sa_remarks: note,
            back_order_time_car_app: date,
            note_attachments: attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.Wo:
        return {
          path: "/notes/wo/update",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            note,
            visible_for_mechanic: visibleToMechanic,
            updated_on: moment().toDate(),
            attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.Main:
        return {
          path: "/notes/main/update",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            note,
            visible_for_mechanic: visibleToMechanic,
            updated_on: moment().toDate(),
            attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.CallCustomer:
        return {
          path: "/notes/call/update",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            note,
            phone_nr: phoneNr,
            visible_for_mechanic: visibleToMechanic,
            updated_on: moment().toDate(),
            attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.BillNote:
        return {
          path: "/notes/bill/update",
          payload: { appointment_note_id: selectedNote?.id, appointment_id: appointment.id, note, updated_on: moment().toDate(), attachment: attachments?.[0] }
        };
      case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
        return {
          path: "/notes/temp_driver/update",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            note,
            name,
            email,
            phone_nr: phoneNr,
            updated_on: moment().toDate(),
            attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.RecurringCar:
        return {
          path: "/notes/recurring_car/update",
          payload: { appointment_note_id: selectedNote?.id, appointment_id: appointment.id, note, updated_on: moment().toDate(), attachments }
        };
      case APPOINTMENT_NOTE_TYPES.Attachment:
        return {
          path: "/notes/attachment/update",
          payload: { appointment_note_id: selectedNote?.id, appointment_id: appointment.id, note, updated_on: moment().toDate(), attachments }
        };
      case APPOINTMENT_NOTE_TYPES.Parking:
        return {
          path: "/notes/parking_note/update",
          payload: { appointment_note_id: selectedNote?.id, appointment_id: appointment.id, note, updated_on: moment().toDate() }
        };
      default:
        return null;
    }
  };

  const validateRequest = (type: number) => {
    switch (type) {
      case APPOINTMENT_NOTE_TYPES.Info:
      case APPOINTMENT_NOTE_TYPES.Wo:
      case APPOINTMENT_NOTE_TYPES.Main:
      case APPOINTMENT_NOTE_TYPES.RecurringCar:
      case APPOINTMENT_NOTE_TYPES.BackOrder:
      case APPOINTMENT_NOTE_TYPES.Parking:
        if (!note) {
          setHasValidationError(true);
          return false;
        }

        return true;

      case APPOINTMENT_NOTE_TYPES.CallCustomer:
      case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
        if (!note || !phoneNr) {
          setHasValidationError(true);
          return false;
        }

        return true;
      case APPOINTMENT_NOTE_TYPES.BillNote:
        if (!attachments?.length) {
          setHasValidationError(true);
          return false;
        }

        return true;

      case APPOINTMENT_NOTE_TYPES.Attachment:
        if (!attachments?.length) {
          setHasValidationError(true);
          return false;
        }

        return true;

      default:
        setHasValidationError(false);
        return true;
    }
  };

  const handleCreateNote = async (type: number | null) => {
    if (!type) return;

    const request = handleConstructCreateNoteRequest(type);

    if (!request) return;
    const { path, payload } = request;
    const isValid = validateRequest(type);

    const additionalFields = {
      appointment_note_type_id: noteType,
      created_on: moment().toISOString(),
      user_id: user?.id
    };

    if (isValid) {
      const id = await addAppointmentNote.mutateAsync({ path, payload: { ...payload, ...additionalFields } });
      onSave?.(type, id);
      resetNoteType();
      handleResetAppointmentNotesState();
    }
  };

  const handleUpdateNote = (type: number | null) => {
    if (!type) return;

    const request = handleConstructUpdateNoteRequest(type);

    if (!request) return;
    const { path, payload } = request;
    const isValid = validateRequest(type);

    const additionalFields = {
      updated_on: moment().toISOString(),
      user_id: user?.id,
      updated_by: user,
      user
    };

    if (isValid) {
      updateAppointmentNote.mutate({ path, payload: { ...payload, ...additionalFields } });
      resetNoteType();
      onSetSelectedAppointmentNote(null);
      handleResetAppointmentNotesState();
    }
  };

  const handleDeleteNote = (noteID: number) => {
    deleteAppointmentNote.mutate({ noteID });
    onSetSelectedAppointmentNote(null);
  };

  const VisibleToMechanic =
    noteType && [APPOINTMENT_NOTE_TYPES.Wo, APPOINTMENT_NOTE_TYPES.Main].includes(noteType) ? (
      <div className="add-update-appointment-note-checkbox-field">
        <Checkbox
          toggle
          checked={visibleToMechanic}
          name="visibleToMechanic"
          label={t("v8_note_visible_to_mechanic").message || "Note visible to mechanic"}
          onChange={(_, { checked }) => handleCheckboxChange(checked)}
        />
      </div>
    ) : null;

  const AddPhoneNumber =
    noteType === APPOINTMENT_NOTE_TYPES.CallCustomer ? (
      <div className="add-update-appointment-center-container">
        <Dropdown
          className="add-update-appointment-note-phone-options-dropdown"
          selectOnBlur={false}
          selection
          name="phoneNr"
          options={phoneOptions}
          value={defaultPhoneDropdownValue}
          onChange={(_, { name, value }) => {
            if (value === "other") {
              handleDropdownChange(name, "");
            } else {
              handleDropdownChange(name, value);
            }
          }}
        />{" "}
        {defaultPhoneDropdownValue === "other" && (
          <Form.Field error={hasValidationError && !name} className="add-update-appointment-note-phone-input" required>
            <Input value={phoneNr} onChange={handleInputChange} name="phoneNr" placeholder={t("v8_enter_phone_number").message || "Enter phone number"} />
          </Form.Field>
        )}
      </div>
    ) : null;

  const AddDate = noteType === APPOINTMENT_NOTE_TYPES.BackOrder ? <DatePicker date={date} onDateChange={handleChangeDate} showLabel /> : null;

  const TemporaryDriverNoteField =
    noteType === APPOINTMENT_NOTE_TYPES.TemporaryDriverNote ? (
      <Form.Group widths="equal">
        <Form.Field error={hasValidationError && !name} className="add-update-appointment-note-form-field" required>
          <label>{t("v8_name").message || "Name"}</label>
          <Input fluid className="add-update-appointment-note-input-field" value={name} name="name" onChange={handleInputChange} />
        </Form.Field>
        <Form.Field error={hasValidationError && !phoneNr} className="add-update-appointment-note-form-field" required>
          <label>{t("v8_phone_number").message || "Phone Number"}</label>
          <Input fluid className="add-update-appointment-note-input-field" value={phoneNr} name="phoneNr" onChange={handleInputChange} />
        </Form.Field>
        <Form.Field error={hasValidationError && !email} className="add-update-appointment-note-form-field" required>
          <label>{t("v8_email").message || "Email"}</label>
          <Input fluid className="add-update-appointment-note-input-field" value={email} name="email" onChange={handleInputChange} />
        </Form.Field>
      </Form.Group>
    ) : null;

  return (
    <Modal open className="AddUpdateAppointmentNote">
      <Modal.Header className="add-update-appointment-note-modal-header">
        <AddUpdateNoteHeader type={noteType} isEditing={!!selectedNote} />{" "}
      </Modal.Header>
      <Modal.Content>
        <Form>
          {suggestionOptions.length > 0 && (
            <Dropdown
              className="mb-10"
              selection
              fluid
              selectOnBlur={false}
              options={suggestionOptions}
              value={noteDropdownValue}
              name="note_suggestion_id"
              placeholder={t("choose_answer").message || "Choose answer"}
              onChange={(_, { value }) => {
                setNoteDropdownValue(value as number);
                handleTextAreaChange(suggestionOptions.find(item => item.value === value)?.text || "");
              }}
            />
          )}
          {TemporaryDriverNoteField}
          <Form.Field
            error={hasValidationError && !note && ![APPOINTMENT_NOTE_TYPES.Attachment, APPOINTMENT_NOTE_TYPES.BillNote].includes(Number(noteType))}
            required={![APPOINTMENT_NOTE_TYPES.Attachment, APPOINTMENT_NOTE_TYPES.BillNote].includes(Number(noteType))}
          >
            <label>{t("v8_note").message || "Note"}</label>
            <TextArea
              className="add-update-appointment-note-textare-field"
              rows={5}
              value={note}
              name="note"
              onChange={evt => handleTextAreaChange(evt.target.value)}
              placeholder={`${t("v8_type_note_here").message || "Type note here"}...`}
            />
          </Form.Field>
          {VisibleToMechanic}
          {AddPhoneNumber}
          {AddDate}
          {noteType === APPOINTMENT_NOTE_TYPES.BillNote && !!attachments?.length ? (
            <div className="attachment-uploaded">{t("v8_file_uploaded").message || "File Uploaded"}</div>
          ) : (
            <Form.Field
              error={hasValidationError && !attachments?.length && [APPOINTMENT_NOTE_TYPES.Attachment, APPOINTMENT_NOTE_TYPES.BillNote].includes(Number(noteType))}
              required={[APPOINTMENT_NOTE_TYPES.Attachment, APPOINTMENT_NOTE_TYPES.BillNote].includes(Number(noteType))}
            >
              <UploadFilesZone
                hasError={hasValidationError && !attachments?.length && [APPOINTMENT_NOTE_TYPES.Attachment, APPOINTMENT_NOTE_TYPES.BillNote].includes(Number(noteType))}
                display={![APPOINTMENT_NOTE_TYPES.Parking, APPOINTMENT_NOTE_TYPES.TemporaryDriverNote].includes(Number(noteType))}
                onFileInputChange={handleFileChange}
                onFileDragOver={handleFileDragOver}
                onFileDrop={handleFileDrop}
              />
            </Form.Field>
          )}
          <UploadedFilesPreview attachments={attachments} onDeleteAttachment={handleDeleteAttachment} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <div className={`${selectedNote ? "update-appointment-note-modal-actions" : "add-appointment-note-modal-actions"}`}>
          {!!selectedNote && (
            <div>
              <Button
                onClick={() => handleDeleteNote(selectedNote?.id)}
                color="red"
                loading={deleteAppointmentNote.isPending}
                disabled={addAppointmentNote.isPending || updateAppointmentNote.isPending}
              >
                {t("v8_delete").message || "Delete"}
              </Button>
            </div>
          )}

          <div>
            <Button color="light" onClick={selectedNote && selectedNote.id > 0 ? onUnselectNote : handleModalClose}>
              {t("v8_cancel").message || "Cancel"}
              <Icon className="xmark" />
            </Button>
            <Button
              type="submit"
              color="green"
              onClick={selectedNote && selectedNote.id > 0 ? () => handleUpdateNote(noteType) : () => handleCreateNote(noteType)}
              disabled={deleteAppointmentNote.isPending}
              loading={addAppointmentNote.isPending || updateAppointmentNote.isPending}
            >
              {t("v8_save").message || "Save"}
            </Button>
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
