import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { Appointment } from "models";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import { AppointmentsKeys } from "modules/Appointments/queryKeys";
import ApiInstance from "util/Api";

type CustomerWaitingArgs = {
  appointment_id: number;
  customer_waiting: boolean;
};

export const useCustomerWaitingHandle = ({ appointment_id, customer_waiting }: CustomerWaitingArgs) => {
  const queryClient = useQueryClient();

  const updateCustomerWaiting = async () => {
    const response = await ApiInstance.post("/appointments/set_customer_waiting", { appointment_id, customer_waiting }, ENV.appointmentBaseURL);
    return response;
  };

  const onSuccessFn = () => {
    const appointmentQueryKey = AppointmentDetailsKeys.view(appointment_id);
    const appointment: Appointment | undefined = queryClient.getQueryData(appointmentQueryKey);

    if (appointment)
      queryClient.setQueryData(appointmentQueryKey, {
        ...appointment,
        customer_waiting
      });

    const appointmentsList: Appointment[] | undefined = queryClient.getQueryData(AppointmentsKeys.list);

    if (appointmentsList) {
      const updatedList = appointmentsList.map(app => (app.id === appointment_id ? { ...app, customer_waiting } : app));
      queryClient.setQueryData(AppointmentsKeys.list, updatedList);
    }
  };

  const { mutate: customerWaitingMutation, isPending: isLoading } = useMutation({
    mutationFn: updateCustomerWaiting,
    onSuccess: onSuccessFn,
    onError: error => {
      toast.error(error.message);
    }
  });

  return { customerWaitingMutation, isLoading };
};
