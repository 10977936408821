import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import "@dnd-kit/utilities";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties, ReactNode } from "react";
import { Icon } from "semantic-ui-react";

type SortableItemProps = {
  id: UniqueIdentifier;
  children: ReactNode;
};

export const DragHandle = ({ id }: { id: UniqueIdentifier }) => {
  const { attributes, listeners, setActivatorNodeRef } = useSortable({ id });

  return (
    <button {...attributes} {...listeners} ref={setActivatorNodeRef} className="drag-note-suggestion">
      <Icon className="move" />
    </button>
  );
};

export const SortableItem = ({ id, children }: SortableItemProps) => {
  const { isDragging, setNodeRef, transform, transition } = useSortable({ id });
  const style: CSSProperties = { opacity: isDragging ? 0.4 : 1, transform: CSS.Translate.toString(transform), transition };

  return (
    <li className="sortable-item-note-suggestion" ref={setNodeRef} style={style}>
      {children}
    </li>
  );
};
