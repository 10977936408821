import moment from "moment";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";

import { DATE_FORMATS } from "components/DatePicker";
import "components/DBB/DBB.scss";
import useDBB from "components/DBB/hooks/useDBB";
import { Appointment } from "models";
import { ITranslation } from "util/interfaces";

type DBBProps = {
  appointment: Appointment;
};

export const DBB = ({ appointment }: DBBProps) => {
  const t = useTranslation().t as ITranslation;
  const { dbbMutation, refreshDBBMutation } = useDBB(appointment.id);

  const handleRefreshDBB = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    refreshDBBMutation.mutate();
  };

  const handleDBB = () => {
    window.open(dbbMutation.data, "_blank");
  };

  let status = "no-dbb";
  let statusColor = "white";
  let icon = false;
  let iconColor = "red";
  let popupText = null;

  if (appointment?.car?.has_dbb) {
    if (appointment.car.dbb_appointment_date) {
      if (moment().isAfter(appointment.car.dbb_appointment_date)) {
        status = "valid-appointment";
        statusColor = "green";
      } else {
        status = "waiting-appointment";
        statusColor = "orange";
      }

      if (appointment.final_car_check_dbb_status > 0) {
        icon = true;
        if (appointment.final_car_check_dbb_status === 1) {
          iconColor = "green";
        } else if (appointment.final_car_check_dbb_status === 2) {
          iconColor = "orange";
          popupText = {
            bold: t("v8_miniportal_approval").message || "Miniportal is pending your approval,",
            normal: t("v8_for_issues_reach_dbb_support").message || "for issues please reach out to DBB Support."
          };
        } else {
          iconColor = "red";
          popupText = {
            bold: t("v8_unexpected_error").message || "An unexpected error has occurred,",
            normal: t("v8_for_issues_reach_us").message || "for issues please reach out to us."
          };
        }
      }
    } else {
      status = "no-appointment";
      statusColor = "red";
      popupText = {
        bold: t("v8_unexpected_error").message || "An unexpected error has occurred,",
        normal: t("v8_for_issues_reach_us").message || "for issues please reach out to us."
      };
    }
  }

  return (
    <Popup
      hoverable
      wide
      disabled={!popupText}
      className={`DBBbutton ${icon ? iconColor : statusColor}`}
      content={
        popupText ? (
          <div className="DBBPopup">
            <span>{popupText.bold}</span> {popupText.normal}
          </div>
        ) : null
      }
      trigger={
        <Button className={`DBB ${statusColor} -appointment-status`} size="small" onMouseDown={handleDBB}>
          {icon && (
            <div className={`checkedTire ${iconColor}`}>
              <Icon className="check" color={iconColor} />
              <Icon className="tire" color={iconColor} />
            </div>
          )}
          {status !== "no-dbb" && status !== "no-appointment" && (
            <div className="button_app_date">{moment(appointment?.car?.dbb_appointment_date).format(DATE_FORMATS.dateMonthYear)}</div>
          )}
          <strong className="DBB-label">DBB</strong>
          <Icon className="arrow up right from square" color="green" />
          <div className="DBB-secondary-button">
            <Icon className="arrow rotate" color="black" onMouseDown={handleRefreshDBB} />
          </div>
        </Button>
      }
    />
  );
};
