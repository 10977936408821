import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";

import { AppointmentStatusCell, CurrentStatus, DATE_FORMATS, DetailsCards, Pin, ReactTable, TabData } from "components";
import { useDealersLocations, usePinOptions } from "hooks";
import { PIN_STATUS, PIN_TYPE, PinOptions } from "models";
import "modules/CarDetails/components/PinItems/PinItems.scss";
import { ITranslation } from "util/interfaces";

type PinItemsProps = {
  tabData: TabData;
  isExpanded: boolean;
};

export const PinItems = ({ tabData, isExpanded }: PinItemsProps) => {
  const t = useTranslation().t as ITranslation;
  const { data: pinOptions } = usePinOptions();

  const { locations, dealers } = useDealersLocations();
  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor(row => row.appointment.created_on, {
      id: "date",
      header: t("v8_appointment_date").message || "Appointment Date",
      cell: info => moment(info.renderValue()).format(DATE_FORMATS.dateMonthYear),
      size: 185
    }),
    columnHelper.accessor(row => row.appointment, {
      id: "appointment",
      header: t("v8_status").message || "Status",
      size: 40,
      cell: info => {
        const appointment = info.renderValue();
        if (appointment) {
          return (
            <div className="small-status">
              <AppointmentStatusCell value={{ id: appointment.appointment_status_identifier }} />
            </div>
          );
        }
        return null;
      }
    }),
    columnHelper.accessor(row => row.appointment.wo_nr, {
      id: "workOrder",
      header: "WO #",
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.created_on, {
      id: "pinDate",
      header: t("v8_pin_date").message || "Pin Date",
      cell: info => moment(info.renderValue()).format(DATE_FORMATS.dateMonthYear)
    }),
    columnHelper.accessor(row => row.pin_type_id, {
      id: "pinType",
      header: t("v8_pin_type").message || "Pin Type",
      cell: info => PIN_TYPE[info.renderValue()]
    }),
    columnHelper.accessor(row => row.warranty_type_id, {
      id: "warrantyType",
      header: t("v8_warranty_type").message || "Warranty Type",
      cell: info => {
        const warrantyTypeId = info.renderValue();
        const pinWarrantyData = pinOptions?.warranty_types?.map((pinWarranty: PinOptions) => ({
          key: pinWarranty.name,
          text: t(`${pinWarranty.name}`).message,
          value: pinWarranty.id
        }));
        return pinWarrantyData?.find(warranty_type => warranty_type.value === warrantyTypeId)?.text;
      }
    }),
    columnHelper.accessor(row => row.pin_status_id, {
      id: "pinStatus",
      header: t("v8_pin_status").message || "Pin Status",
      cell: info => PIN_STATUS[info.renderValue()]
    }),
    columnHelper.accessor(row => row.support_nr, {
      id: "support",
      header: `${t("v8_support").message || "Support"} #`,
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.claim_nr, {
      id: "claim",
      header: `${t("v8_claim").message || "Claim"} #`,
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.ref_nr, {
      id: "ref",
      header: `${t("v8_ref").message || "Ref"} #`,
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row.appointment.car_make, {
      id: "brand",
      header: t("v8_brand").message || "Brand",
      cell: info => info.renderValue()
    }),
    columnHelper.accessor(row => row, {
      id: "intervention",
      header: t("v8_intervention").message || "Intervention",
      cell: info => {
        const data = info.renderValue();
        return (
          <Popup
            hoverable
            content={data.question_result?.title || data.intervention?.title}
            trigger={<p className="ellipsis medium">{data.question_result?.title || data.intervention?.title}</p>}
          />
        );
      },
      size: 180
    }),
    columnHelper.accessor(row => row, {
      id: "icons",
      header: "",
      cell: info => {
        const data = info.renderValue();
        const locationInfo = locations?.find(location => location.id === data.dealer_location_id);
        return (
          <div>
            {(data.question_result?.status || data.intervention?.question_result_status) && (
              <div className="pin-status">
                <CurrentStatus status={data.intervention?.question_result_status || data.question_result?.status} />
              </div>
            )}
            <div className="pin-type">
              <Pin pin={data} pinType={{ value: data.pin_type_id }} enableModal />
            </div>
            <Popup
              hoverable
              content={
                <div className="location-info-popup">
                  <p>{dealers?.find(location => location.id === locationInfo?.dealer_id)?.name}</p>
                  <p> {locationInfo?.name || ""}</p>
                </div>
              }
              header={`${t("v8_dealer_location").message || "Dealer/Location"}`}
              trigger={
                <Button className="tiLightPurple af" color="orange">
                  <Icon className="house chimney" />
                </Button>
              }
            />
          </div>
        );
      }
    })
  ];

  return (
    <DetailsCards
      containerStyle="mt-20"
      isExpanded={isExpanded}
      id={tabData.id}
      icon="bars"
      title={`${t("v8_pin_items").message || "Pin Items"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
    >
      <div className="PinItems">
        <ReactTable columns={columns} data={tabData.data} />
      </div>
    </DetailsCards>
  );
};
