import { DefaultError, useQuery } from "@tanstack/react-query";

import { STATUS_IDENTIFIER } from "models";
import { CarDetailsKeys } from "modules/CarDetails/queryKeys";
import ApiInstance from "util/Api";
import { BackendQueryKey } from "util/keyFactory";

export type Checklist = {
  name: string;
  photo_count: number;
  n_count: number;
  n_count_sold: number;
  a_count: number;
  a_count_sold: number;
};

export type AppointmentHistory = {
  date: string;
  location_name: string;
  dealer_location_id: number;
  next_date: Date;
  current_km: number;
  next_km: number | null;
  reg_number: string;
  checklists: Checklist[];
  id: number;
  appointment_status_identifier: STATUS_IDENTIFIER;
  is_money: boolean;
  wo_nr: number;
};

type CheckListDataProps = {
  loading: boolean;
  appointments?: AppointmentHistory[] | undefined;
  error: DefaultError | null;
};

export const useAppointmentHistory = (id: number): CheckListDataProps => {
  const queryKey = CarDetailsKeys.appointmentHistory(id);

  const query = useQuery({
    queryKey,
    queryFn: () => {
      const { baseUrl, endpoint } = CarDetailsKeys.appointmentHistory(id)[0] as BackendQueryKey;

      return ApiInstance.post(endpoint, { id: Number(id) }, baseUrl);
    }
  });

  return { appointments: query.data?.data?.appointments, loading: query.isFetching, error: query.error };
};
