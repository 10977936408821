import { Redirect, Route, RouteProps } from "react-router-dom";

import { LandingPageMapping, useUser } from "hooks";
import { LANDING_PAGE_ID } from "models";
import ApiInstance from "util/Api";

export const PublicRoute = (props: RouteProps) => {
  const user = useUser();

  if (ApiInstance.authToken && !ApiInstance.loggedInWithQrCode) return <Redirect to={LandingPageMapping.get(user?.landing_page_id || LANDING_PAGE_ID.Main)} />;
  return <Route {...props} />;
};
