import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import ENV from "config/Env";
import { Checklist, LeadsCars, LeadsNotSnoozed, LeadsSnoozed, Tag } from "models";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { LeadsSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import ApiInstance from "util/Api";
import { ITranslation, SelectOption } from "util/interfaces";

type ChecklistProps = {
  location_ids: number[] | null;
  dealer_ids: number[] | null;
};

export interface InterventionQuestionType extends SelectOption<number | string> {
  filterValue: number;
}

export type ExportDataResponseStatus = {
  status: "success" | "error";
  error: unknown | Error;
};

export const useGetTagsQuery = () => {
  const getTags = async () => {
    const { data } = await ApiInstance.get(LeadsQueryKeys.tags.endpoint, LeadsQueryKeys.tags.baseUrl);
    return data.tags.reduce((acc: SelectOption<number>[], tag: Tag) => {
      if (tag.system)
        acc.push({
          key: tag.id,
          value: tag.id,
          text: tag.name
        });

      return acc;
    }, []);
  };

  const { data } = useQuery({ queryKey: [LeadsQueryKeys.tags], queryFn: getTags, enabled: true });
  return data;
};

export const useGetChecklistsQuestionsQuery = ({ dealer_ids, location_ids }: ChecklistProps) => {
  const t = useTranslation().t as ITranslation;
  const payload = { dealer_ids, location_ids, embed: "question" };
  const isEnabled = (location_ids && location_ids?.length > 0) || (dealer_ids && dealer_ids?.length > 0);
  const selectAllOption = { key: "all", value: "all", text: t("v8_all").message || "All", filterValue: null };

  const getQuestions = (checklists: Checklist[] | undefined) => {
    if (!checklists) return [];

    const questions = checklists
      .filter(el => el.active && el.questions?.length)
      .reduce((acc, checklist) => {
        checklist.questions?.forEach(question => {
          const key = `${checklist.id}-${question.id}`;
          if (!acc.some(el => el.key === key)) {
            acc.push({ key, value: key, text: question.title || "", filterValue: question.id });
          }
        });
        return acc;
      }, [] as InterventionQuestionType[]);

    return [selectAllOption, ...questions];
  };

  const getChecklists = async () => {
    const { data } = await ApiInstance.post(LeadsQueryKeys.checklists.endpoint, payload, LeadsQueryKeys.checklists.baseUrl);
    const checklists: Checklist[] | undefined = data.checklists ?? [];
    return getQuestions(checklists);
  };

  const { data } = useQuery({ queryKey: [LeadsQueryKeys.checklists], queryFn: getChecklists, enabled: !!isEnabled });
  return data;
};

export const useLeadsCarsQuery = (filters: LeadsCarsFilter) => {
  const isEnabled = filters.location_ids?.length > 0 || filters?.dealer_ids?.length > 0;

  const getLeadsCarsData = async () => {
    const res = await ApiInstance.post(LeadsQueryKeys.listCars.endpoint, filters, LeadsQueryKeys.listCars.baseUrl);
    return res.data as LeadsCars;
  };

  const { data, refetch } = useQuery({
    queryKey: [LeadsQueryKeys.listCars],
    queryFn: getLeadsCarsData,
    enabled: !!isEnabled
  });
  return { data, refetch };
};

export const useLeadsNotSnoozedQuery = (filters: LeadsNotSnoozedFilters) => {
  const isEnabled = filters.location_ids?.length > 0 || filters?.dealer_ids?.length > 0;

  const getLeadsNotSnoozedData = async () => {
    const res = await ApiInstance.post(LeadsQueryKeys.listNotSnoozed.endpoint, filters, LeadsQueryKeys.listNotSnoozed.baseUrl);
    return res.data as LeadsNotSnoozed;
  };

  const { data, refetch } = useQuery({
    queryKey: [LeadsQueryKeys.listNotSnoozed],
    queryFn: getLeadsNotSnoozedData,
    enabled: !!isEnabled
  });
  return { data, refetch };
};

export const useLeadsSnoozedQuery = (filters: LeadsSnoozedFilters) => {
  const isEnabled = filters.location_ids?.length > 0 || filters?.dealer_ids?.length > 0;

  const getLeadsSnoozedData = async () => {
    const res = await ApiInstance.post(LeadsQueryKeys.listSnoozed.endpoint, filters, LeadsQueryKeys.listSnoozed.baseUrl);
    return res.data as LeadsSnoozed;
  };

  const { data, refetch } = useQuery({
    queryKey: [LeadsQueryKeys.listSnoozed],
    queryFn: getLeadsSnoozedData,
    enabled: !!isEnabled
  });
  return { data, refetch };
};

export const exportLeadsCars = async (carsFilters: LeadsCarsFilter): Promise<ExportDataResponseStatus> => {
  try {
    const response = await ApiInstance.post("/leads/export_cars", carsFilters, ENV.apiBase);
    window.open(response.data, "_blank");
    return { status: "success", error: null };
  } catch (error) {
    return { status: "error", error };
  }
};

export const exportLeadsNotSnoozed = async ({ location_ids, ...rest }: LeadsNotSnoozedFilters): Promise<ExportDataResponseStatus> => {
  try {
    const response = await ApiInstance.post("/leads/export/not_snoozed", { ...rest, location_id: location_ids[0] }, ENV.appointmentBaseURL);
    window.open(response.data.url, "_blank");
    return { status: "success", error: null };
  } catch (error) {
    return { status: "error", error };
  }
};

export const exportLeadsSnoozed = async ({ location_ids, ...rest }: LeadsSnoozedFilters): Promise<ExportDataResponseStatus> => {
  try {
    const response = await ApiInstance.post("/leads/export/snoozed", { ...rest, location_id: location_ids[0] }, ENV.appointmentBaseURL);
    window.open(response.data.url, "_blank");
    return { status: "success", error: null };
  } catch (error) {
    return { status: "error", error };
  }
};
