import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Loader } from "semantic-ui-react";

import { ReactTable } from "components";
import { LicenseData, LicenseItem, Licenses } from "modules/LocationLicenseOverview/types";
import { combinedLicenseData } from "modules/LocationLicenseOverview/utils";
import { ITranslation } from "util/interfaces";

type MonthlyTableProps = {
  licensesData: Licenses | undefined;
  isLoadingLicenses: boolean;
  items: LicenseItem[];
  onLicenseRowClick: (rowData: LicenseData) => void;
  handleDeleteLicense: (id: number) => void;
};

export const MonthlyTable = ({ licensesData, isLoadingLicenses, items, onLicenseRowClick, handleDeleteLicense }: MonthlyTableProps) => {
  const t = useTranslation().t as ITranslation;

  const monthlyData = useMemo(() => {
    return combinedLicenseData(licensesData, items, t, true);
  }, [licensesData, items]);

  const columnHelper = createColumnHelper<LicenseData>();
  const columns = [
    columnHelper.accessor(row => row.code, {
      id: "code",
      header: t("v8_code").message || "Code",
      cell: info =>
        info.renderValue() ? (
          <div className="license-code">{info.renderValue()}</div>
        ) : (
          <div className="no-license-code">
            <Icon className="link slash solid red" />
          </div>
        ),
      size: 50
    }),
    columnHelper.accessor(row => row.name, {
      id: "name",
      header: t("v8_name").message || "Name",
      cell: info => <div>{info.renderValue()}</div>
    }),
    columnHelper.accessor(row => row.category, {
      id: "category",
      header: t("v8_role_license_service").message || "Role / License / Service",
      cell: info => <div>{info.renderValue()}</div>,
      size: 300
    }),
    columnHelper.accessor(row => row.quantity, {
      id: "quantity",
      header: t("v8_amount").message || "Amount",
      cell: info => <div>{info.renderValue()}</div>,
      size: 100
    }),
    columnHelper.accessor(row => row.price, {
      id: "price",
      header: t("v8_unit_price").message || "Unit Price",
      cell: info => <div>{`€ ${info.renderValue()}`}</div>,
      size: 100
    }),
    columnHelper.accessor(row => row.totalPrice, {
      id: "totalPrice",
      header: t("v8_total_price").message || "Total Price",
      cell: info => <div>{`€ ${info.renderValue()}`}</div>,
      size: 100
    }),
    columnHelper.accessor(row => row, {
      id: "actions",
      header: "",
      cell: info => {
        const license = info.renderValue();
        return !license?.categoryID && license?.id ? (
          <div className="subitem-delete">
            <div>
              <Icon
                className="trash red"
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  handleDeleteLicense(license.id);
                }}
              />
            </div>
          </div>
        ) : null;
      },
      size: 50
    })
  ];

  if (isLoadingLicenses) return <Loader active inline size="small" />;

  return (
    <div className="MonthlyTable mb-25">
      <ReactTable
        columns={columns}
        data={monthlyData || []}
        renderEmptyRow={!monthlyData?.length}
        emptyRowMessage={t("v8_no_monthly_licenses").message || "No monthly licenses"}
        onRowClick={onLicenseRowClick}
      />
    </div>
  );
};
