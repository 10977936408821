import { useQueryClient } from "@tanstack/react-query";

import { WebSocketMessageListener } from "components";
import { useRealTimeQuery } from "hooks";
import { SnoozeItem } from "models";
import { CarDetailsKeys } from "modules/CarDetails/queryKeys";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const useSnoozeItems = (car_id: number) => {
  const queryClient = useQueryClient();
  const carSnoozeItemsQueryKey = CarDetailsKeys.snoozetems(car_id);

  const updateSnoozeData = (updateFn: (snoozeItems: SnoozeItem[]) => SnoozeItem[]) => {
    const snoozeItems: SnoozeItem[] | undefined = queryClient.getQueryData(carSnoozeItemsQueryKey);
    if (!snoozeItems) return;
    queryClient.setQueryData(carSnoozeItemsQueryKey, updateFn(snoozeItems));
  };

  const listeners: WebSocketMessageListener[] = [
    {
      model: "snooze",
      action: "delete",
      filter: {
        car_id
      },
      callback: message => {
        updateSnoozeData(() => {
          const snoozeItems: SnoozeItem[] | undefined = queryClient.getQueryData(carSnoozeItemsQueryKey);

          if (!snoozeItems?.length) {
            return [];
          }

          const updatedSnoozeItems = snoozeItems.filter(item => item.question_result_id !== (message.data as SnoozeItem).question_result_id);

          return updatedSnoozeItems;
        });
      }
    },
    {
      model: "snooze",
      action: "append",
      filter: {
        car_id
      },
      callback: message => {
        updateSnoozeData(() => {
          const snoozeItems: SnoozeItem[] | undefined = queryClient.getQueryData(carSnoozeItemsQueryKey);
          const update = message.data as SnoozeItem;

          const updatedSnoozeItems = snoozeItems ? [...snoozeItems, update] : [update];

          return updatedSnoozeItems;
        });
      }
    }
  ];

  const query = useRealTimeQuery({
    queryKey: carSnoozeItemsQueryKey,
    queryFn: () =>
      ApiInstance.post((queryKeys.car.snoozeItems as BackendQueryKey).endpoint, { car_id: Number(car_id) }, (queryKeys.car.snoozeItems as BackendQueryKey).baseUrl),
    listeners
  });
  return { data: query.data?.data?.items, loading: query.isFetching, error: query.error };
};
