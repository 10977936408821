import { useTranslation } from "react-i18next";

import { useDealersLocations } from "hooks";
import { Appointment, COMMUNICATION_RESULT_STATUS, CommunicationResult, DeskCommunication } from "models";
import { Agreements, AppointmentDetails, CommunicationEvents, Services, Signature } from "modules/AppointmentDetails/components/DeskCommunicationLog/components";
import { ITranslation } from "util/interfaces";

type ContentProps = {
  appointment: Appointment;
  comm: DeskCommunication;
};

export const Content = ({ appointment, comm }: ContentProps) => {
  const { selectedLocation } = useDealersLocations();
  const t = useTranslation().t as ITranslation;

  const { events, agreed_interventions, declined_interventions, results, agreements } = comm;

  const agreedQuestions = results?.filter((result: CommunicationResult) => COMMUNICATION_RESULT_STATUS.OkStatus === result.status);
  const declinedQuestions = results?.filter((result: CommunicationResult) => result.status === COMMUNICATION_RESULT_STATUS.NotOkStatus);

  return (
    <>
      <AppointmentDetails appointment={appointment} />
      <CommunicationEvents comm={comm} events={events} />
      <Services services={agreed_interventions} title={t("v8_accepted_scheduled_services").message || "Accepted scheduled services"} vat={selectedLocation?.vat} />
      <Services services={declined_interventions} title={t("v8_declined_scheduled_services").message || "Declined scheduled services"} vat={selectedLocation?.vat} />
      <Services services={agreedQuestions} title={t("v8_accepted_extra_services").message || "Accepted extra services"} vat={selectedLocation?.vat} />
      <Services services={declinedQuestions} title={t("v8_declined_extra_services").message || "Declined extra services"} vat={selectedLocation?.vat} />
      <Agreements agreements={agreements} />
      <Signature events={events} />
    </>
  );
};
