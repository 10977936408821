import { useQueryClient } from "@tanstack/react-query";
import { OnChangeFn, PaginationState } from "@tanstack/react-table";

import { SNOOZE_STATUS, SNOOZE_TYPE } from "components";
import {
  useGetCustomerOkUserOptions,
  useGetLeaseCompanyOptions,
  useGetQuestionResultStatusOptions,
  useGetSnoozeStatusOptions,
  useGetSnoozeTypeOptions
} from "modules/LeadsDashboard/hooks";
import { LeadsSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetChecklistsQuestionsQuery } from "modules/LeadsDashboard/service";

type Props = {
  pageSize: number;
};

export const useSnoozedLeadsTableFilterHandlers = ({ pageSize }: Props) => {
  const queryClient = useQueryClient();
  const { leaseCompanyOptions } = useGetLeaseCompanyOptions();
  const { snoozeTypeOptions } = useGetSnoozeTypeOptions();
  const snoozedFilters = queryClient.getQueryData(LeadsQueryKeys.snoozedFilters) as LeadsSnoozedFilters;
  const { questionResultStatusOptions } = useGetQuestionResultStatusOptions();
  const { snoozeStatusOptions } = useGetSnoozeStatusOptions();
  const { customerOkUserOptions } = useGetCustomerOkUserOptions();

  const currentPage = snoozedFilters.page;
  const selectedQuestionStatuses = snoozedFilters.statuses?.map(status => status.toString()) || ["all"];
  const selectedLeaseCompanyOption = leaseCompanyOptions.find(option => option.filterValue === snoozedFilters?.is_lease_company)?.value || "";
  const selectedTaskOption = snoozeTypeOptions.find(option => option.value === snoozedFilters.snooze_type_id)?.value.toString() || "all";
  const selectedSnoozeStatusOption = snoozeStatusOptions.find(option => option.value === snoozedFilters.snooze_status_id)?.value.toString() || "all";
  const selectedCustomerOkUserOption = customerOkUserOptions.find(option => option.filterValue === snoozedFilters.last_customer_ok_is_me)?.value.toString() || "";
  const interventionQuestionOptions =
    useGetChecklistsQuestionsQuery({ dealer_ids: snoozedFilters?.dealer_ids || [], location_ids: snoozedFilters?.location_ids || [] }) || [];
  const areAllDateFiltersReset =
    !snoozedFilters.appointment_date_from && !snoozedFilters.appointment_date_to && !snoozedFilters.snooze_date_from && !snoozedFilters.snooze_date_to;

  const selectedQuestions = snoozedFilters?.questions
    ? snoozedFilters.questions.reduce((acc, questionId) => {
        const question = interventionQuestionOptions.find(element => element.filterValue === questionId);
        if (question) acc.push(question.key.toString());

        return acc;
      }, [] as string[])
    : ["all"];

  const handleQuestionResultStatusSelect = (questionStatuses: string[]) => {
    const isResetNotSelected = questionStatuses.includes("all") && snoozedFilters.statuses !== null;
    const statuses = isResetNotSelected ? null : questionStatuses.map(Number).filter(Boolean);
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...snoozedFilters, statuses });
  };

  const handleOnLeaseSelect = (leaseCompany: string[]) => {
    const leaseCompanyOption = leaseCompanyOptions.find(option => option.value === leaseCompany[0]) || null;
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, {
      ...snoozedFilters,
      is_lease_company: leaseCompanyOption ? leaseCompanyOption?.filterValue : leaseCompanyOption
    });
  };

  const handleOnTaskSelect = (task: string[]) => {
    const isResetNotSelected = task.includes("all") && snoozedFilters.snooze_type_id !== null;
    const tasks = isResetNotSelected ? null : snoozeTypeOptions.find(option => option.value === Number(task[0]));
    const taskOption = tasks ? (tasks?.value as SNOOZE_TYPE) : null;

    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...snoozedFilters, snooze_type_id: taskOption });
  };

  const handleOnSnoozeStatusSelect = (snoozeStatus: string[]) => {
    const isResetNotSelected = snoozeStatus.includes("all") && snoozedFilters.statuses !== null;
    const statuses = isResetNotSelected ? null : snoozeStatusOptions.find(option => option.value === Number(snoozeStatus[0]));
    const snoozeStatusOption = statuses ? (statuses?.value as SNOOZE_STATUS) : null;

    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...snoozedFilters, snooze_status_id: snoozeStatusOption });
  };

  const handleOnCustomerOkUserSelect = (customerOkUser: string[]) => {
    const isResetNotSelected = customerOkUser.includes("all") && snoozedFilters.statuses !== null;
    const customerOkUserOption = customerOkUserOptions.find(option => option.value === Number(customerOkUser[0]))?.value ? true : false;

    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, {
      ...snoozedFilters,
      last_customer_ok_is_me: isResetNotSelected ? false : customerOkUserOption
    });
  };

  const handleOnQuestionSelect = (questions: string[]) => {
    const isResetNotSelected = questions.includes("all") && snoozedFilters.questions !== null;
    const newQuestions = isResetNotSelected ? null : questions.map(el => Number(el.split("-")[1]));
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...snoozedFilters, questions: newQuestions });
  };

  const handleAppointmentDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...snoozedFilters, appointment_date_from: start, appointment_date_to: end });
  };

  const handleSnoozedDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...snoozedFilters, snooze_date_from: start, snooze_date_to: end });
  };

  const onPageChange = (_e: null, data: { activePage: number }) => {
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...snoozedFilters, page: data.activePage });
  };

  const handlePaginationSelect: OnChangeFn<PaginationState> = updater => {
    const newState = typeof updater === "function" ? updater({ pageIndex: currentPage - 1, pageSize }) : updater;
    onPageChange(null, { activePage: newState.pageIndex + 1 });
  };

  return {
    handleOnTaskSelect,
    handleOnLeaseSelect,
    handlePaginationSelect,
    handleOnQuestionSelect,
    handleOnSnoozeStatusSelect,
    handleSnoozedDateRangeChange,
    handleOnCustomerOkUserSelect,
    handleAppointmentDateRangeChange,
    handleQuestionResultStatusSelect,
    currentPage,
    selectedQuestions,
    snoozeTypeOptions,
    selectedTaskOption,
    leaseCompanyOptions,
    snoozeStatusOptions,
    customerOkUserOptions,
    areAllDateFiltersReset,
    selectedQuestionStatuses,
    selectedLeaseCompanyOption,
    questionResultStatusOptions,
    interventionQuestionOptions,
    selectedSnoozeStatusOption,
    selectedCustomerOkUserOption
  };
};
