import { APPOINTMENT_NOTE_TYPES } from "models";

export const DESK_NOTE_TYPES = {
  Parking: APPOINTMENT_NOTE_TYPES.Parking,
  Wo: APPOINTMENT_NOTE_TYPES.Wo,
  Main: APPOINTMENT_NOTE_TYPES.Main,
  TemporaryDriverNote: APPOINTMENT_NOTE_TYPES.TemporaryDriverNote
};

export type DeskNoteType = (typeof DESK_NOTE_TYPES)[keyof typeof DESK_NOTE_TYPES];
