import { forwardRef } from "react";
import { Icon } from "semantic-ui-react";

import "modules/LeadsDashboard/components/TableFilters/TableDatePickerInput/TableDatePicker.scss";

type CustomDatePickerInputProps = {
  value?: string;
  onClick?: () => void;
};

export const CustomDatePickerInput = forwardRef<HTMLButtonElement, CustomDatePickerInputProps>((props, ref) => (
  <div className="TableDatePickerInput">
    <button className="filter-button" onClick={props.onClick} ref={ref}>
      <Icon className={`${props.value !== "" ? "filters" : "filter"} filters-icon`} size="small" />
    </button>
  </div>
));

CustomDatePickerInput.displayName = "CustomDatePickerInput";
