import { saveAs } from "file-saver";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Icon } from "semantic-ui-react";

import { Can, DATE_FORMATS } from "components";
import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { Appointment, COMMUNICATION_STATUS, DealerLocation, STATUS_IDENTIFIER } from "models";
import { CUSTOMER_COMMUNICATIONS, CustomerCommunicationModal } from "modules/AppointmentDetails/components";
import "modules/AppointmentDetails/components/CustomerCommunicationButtons/CustomerCommunicationButtons.scss";
import { useCustomerCommunication } from "modules/AppointmentDetails/hooks";
import { isCarReady } from "modules/AppointmentDetails/utils";
import { ITranslation } from "util/interfaces";

type CustomerCommunicationButtonsProps = {
  appointment: Appointment;
};

const handleCanSendDiagnoseOverview = (appointmentStatus: STATUS_IDENTIFIER, location?: DealerLocation, communicationStatus?: COMMUNICATION_STATUS | null) => {
  return (
    location?.diagnose_overview_enabled &&
    (!communicationStatus || communicationStatus < COMMUNICATION_STATUS.DiagnoseOverviewSent) &&
    !isCarReady(appointmentStatus) &&
    ![STATUS_IDENTIFIER.NewCarStatus, STATUS_IDENTIFIER.CarInShop].includes(appointmentStatus)
  );
};

const handleCanSendRepairOverview = (appointmentStatus: STATUS_IDENTIFIER, location?: DealerLocation, communicationStatus?: COMMUNICATION_STATUS | null) => {
  return location?.repair_overview_enabled && (!communicationStatus || communicationStatus < COMMUNICATION_STATUS.RepairOverviewSent) && isCarReady(appointmentStatus);
};

const getCommunicationIcon = (appointmentStatus: STATUS_IDENTIFIER, communicationStatus?: COMMUNICATION_STATUS | null) => {
  switch (communicationStatus) {
    case COMMUNICATION_STATUS.DiagnoseOverviewSent:
      return <Icon className="eye slash" />;
    case COMMUNICATION_STATUS.DiagnoseOverviewOpened:
      return <Icon className="eye" />;
    case COMMUNICATION_STATUS.DiagnoseOverviewAnswered:
      return <Icon className="user check" />;
    case COMMUNICATION_STATUS.DiagnoseOverviewClosed:
      return !isCarReady(appointmentStatus) ? <Icon className="lock" /> : null;
    case COMMUNICATION_STATUS.RepairOverviewSent:
    case COMMUNICATION_STATUS.RepairOverviewOpened:
      return <Icon className="file invoice dollar" />;
    default:
      return null;
  }
};

export const CustomerCommunicationButtons = ({ appointment }: CustomerCommunicationButtonsProps) => {
  if (appointment.appointment_status_identifier === STATUS_IDENTIFIER.CanceledStatus) return null;
  if (!appointment.customer_communication && [STATUS_IDENTIFIER.NewCarStatus, STATUS_IDENTIFIER.CarInShop].includes(appointment.appointment_status_identifier))
    return null;

  const t = useTranslation().t as ITranslation;
  const { selectedLocation } = useDealersLocations();
  const { getRepairOverviewPDF } = useCustomerCommunication();
  const [showModal, setShowModal] = useState<boolean>(false);
  const receptionistKey = appointment.customer_communication?.receptionist_key;
  const canSendDiagnoseOverview = handleCanSendDiagnoseOverview(appointment.appointment_status_identifier, selectedLocation, appointment.customer_communication?.status);
  const canSendRepairOverview = handleCanSendRepairOverview(appointment.appointment_status_identifier, selectedLocation, appointment.customer_communication?.status);

  const onDownloadRepairOverviewPdf = () => {
    if (receptionistKey) {
      getRepairOverviewPDF(receptionistKey)
        .then(result => {
          saveAs(result, `RepairOverview_${appointment.reg_number_escaped}_WO_${appointment.wo_nr}_${moment().format(DATE_FORMATS.yearMonthDate)}.pdf`);
        })
        .catch(error => {
          console.error("Error downloading repair overview report.", error);
          toast.error(t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.");
        });
    }
  };

  const handleOpenCC = (key: string) => {
    const url = `${ENV.ccBaseURL}/#${key}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {appointment.customer_communication?.status && appointment.customer_communication.status >= COMMUNICATION_STATUS.RepairOverviewSent && (
        <Button className="-appointment-status download-pdf" size="small" onClick={onDownloadRepairOverviewPdf}>
          <Icon name="file pdf" />
        </Button>
      )}
      <Can I={["create_comm", "update_comm", "create_post_comm", "update_post_comm"]} the="customcom">
        <Button.Group basic>
          {![STATUS_IDENTIFIER.NewCarStatus, STATUS_IDENTIFIER.CarInShop].includes(appointment.appointment_status_identifier) && (
            <Button
              className="-appointment-status customer-communication-icon"
              size="small"
              disabled={!canSendDiagnoseOverview && !canSendRepairOverview}
              onClick={() => setShowModal(true)}
            >
              {getCommunicationIcon(appointment.appointment_status_identifier, appointment.customer_communication?.status)}
              <Icon className="envelope" />
            </Button>
          )}
          {receptionistKey && (
            <Button className="-appointment-status" size="small" onClick={() => handleOpenCC(receptionistKey)}>
              <Icon className="external link alt" />
            </Button>
          )}
        </Button.Group>
      </Can>
      {showModal && (
        <CustomerCommunicationModal
          appointment={appointment}
          send={canSendDiagnoseOverview ? CUSTOMER_COMMUNICATIONS.DiagnoseOverview : CUSTOMER_COMMUNICATIONS.RepairOverview}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
