import { Appointment, PinItem, SnoozeItem } from "models";

type GroupedType = {
  [Key in keyof PinItem]: PinItem[Key];
} & {
  [Key in keyof SnoozeItem]: SnoozeItem[Key];
};

export interface PinItemWithHistory extends PinItem {
  history: GroupedType[];
  appointment?: Appointment;
}

export interface SnoozeItemWithHistory extends SnoozeItem {
  history: GroupedType[];
  appointment?: Appointment;
}

const groupItems = (items: GroupedType[]) => {
  let grouped = [];
  const interventions: PinItemWithHistory[] | SnoozeItemWithHistory[] = [],
    questions: PinItemWithHistory[] | SnoozeItemWithHistory[] = [],
    interventionsOffsets: number[] = [],
    questionsOffsets: number[] = [];
  let interventionOffset = 0,
    questionOffset = 0;

  items.forEach((item: GroupedType) => {
    if (item.intervention_id) {
      if (!interventionsOffsets[item.intervention_id]) {
        interventionsOffsets[item.intervention_id] = interventionOffset++;

        interventions[interventionsOffsets[item.intervention_id]] = { ...item, history: [item] };
      } else {
        if (item.created_on > interventions[interventionsOffsets[item.intervention_id]].created_on)
          interventions[interventionsOffsets[item.intervention_id]] = {
            ...item,

            history: [...interventions[interventionsOffsets[item.intervention_id]].history, item]
          };
        else interventions[interventionsOffsets[item.intervention_id]].history.push(item);
      }
    } else {
      if (!questionsOffsets[item.question_result_id]) {
        questionsOffsets[item.question_result_id] = questionOffset++;

        questions[questionsOffsets[item.question_result_id]] = { ...item, history: [item] };
      } else {
        if (item.created_on > questions[questionsOffsets[item.question_result_id]].created_on)
          questions[questionsOffsets[item.question_result_id]] = { ...item, history: [...questions[questionsOffsets[item.question_result_id]].history, item] };
        else questions[questionsOffsets[item.question_result_id]].history.push(item);
      }
    }
  });

  grouped = [...interventions, ...questions];

  return grouped.sort((a, b) => ((b.appointment?.time_car_app ?? 0) > (a.appointment?.time_car_app ?? 0) ? 1 : -1));
};

export const getPinWithHistory = (pinItems: GroupedType[] | null): PinItem[] => {
  if (!pinItems) return [];

  return groupItems(pinItems) as PinItem[];
};

export const getSnoozeWithHistory = (snoozeItems: GroupedType[] | null): SnoozeItem[] => {
  if (!snoozeItems) return [];

  return groupItems(snoozeItems) as SnoozeItem[];
};

export const getAverageKM = (first: { time: Date; km: number }, second: { time: Date; km: number }) => {
  const timeDiff = second.time.getTime() - first.time.getTime();
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const kmDiff = second.km - first.km;
  return Math.floor(kmDiff / diffDays);
};

export const getColorByAverageKM = (averageKM: number) => {
  if (averageKM <= 30) {
    return "rgb(135, 206, 235)";
  }
  if (averageKM >= 30 && averageKM < 50) {
    return "rgb(175,196,131)";
  }
  if (averageKM >= 50 && averageKM < 70) {
    return "rgb(252,162,78)";
  }
  if (averageKM >= 70 && averageKM < 100) {
    return "rgb(224,126,96)";
  }
  return "rgb(191,59,59)";
};
