import { Icon, Popup } from "semantic-ui-react";

import { Images, Videos } from "components";
import { QuestionResultImage, QuestionResultVideo } from "models";
import "modules/LeadsDashboard/components/MediaPreview/MediaPreview.scss";

type Props = {
  images: QuestionResultImage[] | undefined;
  videos: QuestionResultVideo[] | undefined;
};

export const MediaPreview = ({ images, videos }: Props) => {
  if (!images && !videos) return null;

  const mediaNumber = (images?.length ?? 0) + (videos?.length ?? 0);
  return (
    <Popup
      on="click"
      hideOnScroll
      content={
        <div className="media-preview-container">
          <Images data={images} />
          <Videos data={videos} />
        </div>
      }
      trigger={
        <div className="media-preview-question-result ">
          <div>
            <Icon className="film photo media-preview-question-result-icon" />
          </div>
          <div className="media-preview-question-text-container">
            <span className="media-preview-question-text"> {mediaNumber} </span>
          </div>
        </div>
      }
    />
  );
};
