import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Icon, Loader } from "semantic-ui-react";

import { Can, ReactTable } from "components";
import { NOTE_TYPES } from "models";
import "modules/NoteSuggestions/components/NoteSuggestionsTable/NoteSuggestionsTable.scss";
import { useNoteSuggestions } from "modules/NoteSuggestions/hooks";
import { NoteTypeWithSuggestion } from "modules/NoteSuggestions/service";
import { ITranslation } from "util/interfaces";

type NoteSuggesionsTableProps = {
  openModal: (id: NOTE_TYPES) => void;
};
export const NoteSuggestionsTable = ({ openModal }: NoteSuggesionsTableProps) => {
  const { notes, isLoading: isLoadingNotes, handleNoteVisibility } = useNoteSuggestions();
  const t = useTranslation().t as ITranslation;

  const columnHelper = createColumnHelper<NoteTypeWithSuggestion>();
  const columns = [
    columnHelper.accessor((row: NoteTypeWithSuggestion) => row.note_type.active, {
      id: "note_suggestions",
      header: () => <span>{t("v8_note_type").message || "Note Type"}</span>,
      size: 800,
      cell: ({ row }) => {
        const currentNote = row.original;
        const visibilityIcon = `eye ${currentNote.note_type.active ? "green-lime" : "slash red"}`;
        return (
          <div className="note-info-container">
            <Icon onClick={() => handleNoteVisibility(currentNote.note_type.note_type_id)} className={`${visibilityIcon} visibilityToggle`} />
            <Icon className={`${currentNote.icon} green-lime`} />
            <span>{t(currentNote.translation_key).message || currentNote.default_message}</span>
          </div>
        );
      }
    }),
    columnHelper.accessor((row: NoteTypeWithSuggestion) => row.note_type.active, {
      id: "note_interactions",
      size: 10,
      header: () => <span></span>,
      cell: ({ row }) => {
        const currentNote = row.original;

        return (
          <Can I="update" the="note-suggestions">
            <div className="edit-button-wrapper">
              <button className="edit-button-container" onClick={() => openModal(currentNote.note_type.note_type_id)}>
                <div className="edit-button">
                  <Icon className="pen to square edit-icon" />
                </div>
              </button>
            </div>
          </Can>
        );
      }
    })
  ];

  if (isLoadingNotes || !notes) return <Loader active />;
  return (
    <div className="note-suggestions-container">
      <ReactTable columns={columns} data={notes} />
    </div>
  );
};
