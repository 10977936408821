import { useQueryClient } from "@tanstack/react-query";
import _startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";
import { Checkbox, Dropdown, Grid, GridColumn } from "semantic-ui-react";

import { DealersAndLocationsDropdown, NavPortal } from "components";
import { useUser } from "hooks";
import { ExportDataButton, ExportDataModal, LeadTypeFilter, ResetFiltersButton, SearchFilter } from "modules/LeadsDashboard/components";
import { PossibleQuestionStatusOptions, useExportModal } from "modules/LeadsDashboard/hooks";
import { useNotSnoozedFilterHandlers } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { exportLeadsNotSnoozed } from "modules/LeadsDashboard/service";
import { ITranslation, SelectOption } from "util/interfaces";

export type LeadsNotSnoozedFilters = {
  customer_approved: boolean;
  page: number;
  date_from: Date;
  date_to: Date;
  dealer_ids: number[];
  location_ids: number[];
  is_lease_company: boolean | null;
  search_term: string;
  interventions: boolean;
  statuses: PossibleQuestionStatusOptions[] | null;
  questions: number[] | null;
  tags: number[] | null;
};

type LeadsNotSnoozedFiltersProps = {
  filters: LeadsNotSnoozedFilters;
  tagsOptions: SelectOption<number>[];
};

export const NotSnoozedLeadsFilters = ({
  filters: { search_term, dealer_ids, location_ids, tags, customer_approved, interventions },
  tagsOptions
}: LeadsNotSnoozedFiltersProps) => {
  const user = useUser();
  const queryClient = useQueryClient();
  const t = useTranslation().t as ITranslation;

  const notSnoozedFilters = queryClient.getQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters);
  const { handleCloseExportModal, handleOpenExportModal, showExportModal } = useExportModal();
  const { handleCustomerApprovedChange, handleDealerLocationChange, handleInterventionsChange, handleResetFilters, handleSearchChange, handleTagsChange } =
    useNotSnoozedFilterHandlers();

  const hasMultipleLocationsSelected = notSnoozedFilters ? notSnoozedFilters?.location_ids?.length > 1 : false;
  const hasMultipleDealersSelected = notSnoozedFilters ? notSnoozedFilters?.dealer_ids?.length > 1 : false;

  return (
    <>
      <NavPortal>
        <SearchFilter value={search_term} handleSearchChange={handleSearchChange} />
      </NavPortal>

      <div className="toolbar-container">
        <Grid columns={16} className="toolbar-grid">
          <GridColumn width={3} className="no-left-gutter right-gutter">
            <LeadTypeFilter />
          </GridColumn>
          <GridColumn width={5} className="left-gutter right-gutter">
            <DealersAndLocationsDropdown
              values={{ dealerIds: dealer_ids, locationIds: location_ids }}
              handleOnChange={({ dealerIds, locationIds }) => handleDealerLocationChange({ dealerIds, locationIds })}
            />
          </GridColumn>
          <GridColumn width={4} className="left-gutter right-gutter">
            <Dropdown
              fluid
              selection
              name="tag"
              multiple
              value={tags || []}
              selectOnBlur={false}
              options={tagsOptions}
              placeholder={t("v8_tags").message || "Tags"}
              onChange={(_, { value }) => handleTagsChange(value as number[])}
            />
          </GridColumn>

          <GridColumn width={4} className="no-left-gutter no-right-gutter flex-align-justify-center">
            <div className="filters-checkboxes-container">
              <div className="filters-checkbox-container">
                <Checkbox toggle name="customer_approved" onChange={handleCustomerApprovedChange} checked={customer_approved} />
                <span className="no-wrap-text"> {_startCase(t("v8_customer_approved").message || "Customer approved")}</span>
              </div>
              <div className="filters-checkbox-container">
                <Checkbox toggle name="intervention" onChange={handleInterventionsChange} checked={interventions} />
                <span className="no-wrap-text"> {t("v8_intervention").message || "Intervention"}</span>
              </div>
            </div>
          </GridColumn>
        </Grid>

        <div className="toolbar-reset-export-buttons-container">
          <ResetFiltersButton handleReset={handleResetFilters} />
          {user?.csv_download && <ExportDataButton handleOnClick={handleOpenExportModal} hasWarning={hasMultipleLocationsSelected || hasMultipleDealersSelected} />}
        </div>
      </div>

      {notSnoozedFilters && <ExportDataModal open={showExportModal} onClose={handleCloseExportModal} onExportData={() => exportLeadsNotSnoozed(notSnoozedFilters)} />}
    </>
  );
};
