import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";

import "modules/LeadsDashboard/components/ExportDataModal/ExportDataModal.scss";
import { ExportDataResponseStatus } from "modules/LeadsDashboard/service";
import { ITranslation } from "util/interfaces";

type Props = {
  open: boolean;
  onClose: () => void;
  onExportData: () => Promise<ExportDataResponseStatus>;
};

type Status = "idle" | "success" | "error";

type ExportStatus = {
  status: Status;
  message: string;
};

const exportStatusMessagesLookup: Record<Status, { transKey: string; message: string }> = {
  idle: { transKey: "v8_export_ready_to_be_downloaded", message: "Export ready to be downloaded" },
  error: { transKey: "v8_something_went_wrong", message: "Something went wrong, please contact the administrator" },
  success: { transKey: "v8_export_success", message: "Export success" }
};

export const ExportDataModal = ({ open, onClose, onExportData }: Props) => {
  const t = useTranslation().t as ITranslation;
  const [exportStatus, setExportStatus] = useState<ExportStatus>({
    status: "idle",
    message: `${t(exportStatusMessagesLookup.idle.transKey).message || exportStatusMessagesLookup.idle.message}`
  });

  const handleOnClose = () => {
    setExportStatus({
      status: "idle",
      message: `${t(exportStatusMessagesLookup.idle.transKey).message || exportStatusMessagesLookup.idle.message}`
    });
    onClose();
  };

  const handleExport = async () => {
    const response = await onExportData();

    if (response.status === "success") {
      setExportStatus({
        status: "success",
        message: `${t(exportStatusMessagesLookup.success.transKey).message || exportStatusMessagesLookup.success.message}`
      });
    } else {
      const errorMessage =
        response.error instanceof Error ? response.error.message : t(exportStatusMessagesLookup.error.transKey).message || exportStatusMessagesLookup.error.message;
      setExportStatus({ status: "error", message: errorMessage });
    }
  };

  return (
    <Modal open={open} onClose={handleOnClose} size="tiny" className="ExportDataModal">
      <Modal.Header>
        <div className="export-file-container">
          <div>
            <Icon className="file" />
            <span> {t("v8_export").message || "Export"} </span>
          </div>

          <div className="export-modal-download-button-container">
            <Button onClick={handleExport} className="export-modal-download-button">
              <div>
                <Icon className="download" />
              </div>
              <span className="export-modal-download-text ">{t("v8_download").message || "Download"}</span>
            </Button>

            <Button basic icon onClick={handleOnClose} className="export-modal-close-button">
              <div className="export-modal-close-button-container">
                <Icon className="xmark" size="big" />
              </div>
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Actions>
        <div className="export-modal-content-text">
          {exportStatus.status === "idle" && exportStatus.message}
          {exportStatus.status === "success" && `${exportStatus.message}!`}
          {exportStatus.status === "error" && exportStatus.message}
        </div>
      </Modal.Actions>
    </Modal>
  );
};
