import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";

import "components/NavBarSearch/NavBarSearch.scss";
import { ITranslation } from "util/interfaces";

type Props = {
  value: string;
  handleSearchChange: (value: string) => void;
  debounceTime?: number;
};

const DEBOUNCE_TIME = 500;

export const SearchFilter = ({ value, handleSearchChange, debounceTime = DEBOUNCE_TIME }: Props) => {
  const t = useTranslation().t as ITranslation;
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  return (
    <div className="NavBarSearch">
      <Input
        icon="magnifying glass"
        iconPosition="left"
        placeholder={`${t("v8_search").message || "Search"}...`}
        className="nav-bar-search"
        onChange={_debounce(e => handleSearchChange(e.target.value), debounceTime)}
        defaultValue={searchTerm}
      />
    </div>
  );
};
