import { UseQueryResult } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import moment, { DurationInputArg1 } from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Icon, Loader, Message } from "semantic-ui-react";

import { DATE_FORMATS, DatePicker, DetailsCards, NavPortal, TabData, Tabs } from "components";
import { useDealersLocations } from "hooks";
import { ReportThreshold } from "models";
import { DealerQualityChecksTabular } from "modules/DealerReports/components";
import DealerTableReport from "modules/DealerReports/components/DealerTableReport";
import DealerUsersReportTable from "modules/DealerReports/components/DealerUsersReport";
import "modules/DealerReports/DealerReports.scss";
import { useDealerReports } from "modules/DealerReports/hooks/useDealerReports";
import { isPercentageField } from "modules/DealerReports/util/helper";
import { ParsedDealerReport, ParsedReportData } from "modules/DealerReports/util/types";
import SimpleBarChart from "modules/LocationReports/components/SimpleBarChart";
import { ITranslation } from "util/interfaces";

const subtractDays = (days: DurationInputArg1) => new Date(moment().subtract(days, "d").format("YYYY-MM-DDT12:00:00Z"));

export enum REPORT_PERIOD {
  Week = 7,
  Month = 30,
  Quarter = 90,
  Custom = "custom"
}

export type PERIOD = REPORT_PERIOD.Week | REPORT_PERIOD.Month | REPORT_PERIOD.Quarter | REPORT_PERIOD.Custom;

const UserReportsTableID = 24;

const DealerReports = () => {
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [dateFrom, setDateFrom] = useState<Date>(subtractDays(7));
  const [dateTo, setDateTo] = useState<Date>(subtractDays(1));
  const [expanded, setExpanded] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<PERIOD>(REPORT_PERIOD.Week);
  const t = useTranslation().t as ITranslation;

  const { selectedDealer } = useDealersLocations();

  const { groups, selectedGroup, setSelectedGroup, reports, emailStats, loading } = useDealerReports({
    dealer_id: Number(selectedDealer?.id),
    date_from: moment(dateFrom).toISOString(),
    date_to: moment(dateTo).toISOString()
  });

  const tabsData = new Map<string, TabData>([
    ...reports.map(
      report =>
        [
          `report_${report.data?.id}`,
          {
            id: `report_${report.data?.id}`,
            title: (report.data && t(report.data?.report_name).message) || report.data?.report_name,
            data: []
          }
        ] as [string, TabData]
    ),
    ...(selectedGroup === 3
      ? [
          [
            "email_stats",
            {
              id: "email_stats",
              title: t("v8_email_stats").message || "Email Statistics",
              data: []
            }
          ] as [string, TabData]
        ]
      : [])
  ]);

  const onSelectPeriod = (value: PERIOD) => {
    setSelectedPeriod(value);

    const period = (value === REPORT_PERIOD.Custom ? value : Number(value)) as PERIOD;

    switch (period) {
      case REPORT_PERIOD.Week:
      case REPORT_PERIOD.Month:
      case REPORT_PERIOD.Quarter:
        setDateFrom(subtractDays(period));
        setDateTo(subtractDays(1));
        setShowCustomRange(false);
        break;
      case REPORT_PERIOD.Custom:
        setShowCustomRange(true);
        break;
      default:
        break;
    }
  };

  const toggleExpand = () => {
    setExpanded(prev => !prev);
  };

  const handleDateChange = (dates: Date[]) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setDateFrom(start);
      setDateTo(end);
    }
  };

  const periodLabels: Record<PERIOD, string> = {
    [REPORT_PERIOD.Week]: `7 ${t("v8_days").message || "Days"}`,
    [REPORT_PERIOD.Month]: `30 ${t("v8_days").message || "Days"}`,
    [REPORT_PERIOD.Quarter]: `90 ${t("v8_days").message || "Days"}`,
    [REPORT_PERIOD.Custom]: t("v8_custom").message || "Custom"
  };

  const handleExportData = (name: string, data: ParsedReportData[]) => {
    if (!Array.isArray(data) || data.length === 0) return;

    const keys = Object.keys(data[0]).filter(key => key !== "__typename");

    const header = keys
      .map(key => {
        const translatedHeader = t(key).message || key;
        return `"${translatedHeader}"`;
      })
      .join(",");

    const formatCsvCell = (key: string, value: any): string => {
      if (value === null || value === undefined) return "0";

      if (typeof value === "object" && value !== null && "value" in value) {
        if ("percent" in value) return `"${value.value} (${value.percent}%)"`;
        return `"${value.value}"`;
      }

      if (isPercentageField(key)) return `"${value}%"`;
      if (typeof value === "number") {
        const formattedValue = value.toString().includes(".") ? value.toFixed(2) : value;
        return `"${formattedValue}"`;
      }

      return `"${value}"`;
    };

    const rows = data.map(row => {
      const rowValues = keys.map(key => formatCsvCell(key, row[key]));
      return rowValues.join(",");
    });

    const csvContent = [header, ...rows].join("\n");

    const fileName = `${name}_${moment().format(DATE_FORMATS.yearMonthDate)}.csv`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, fileName);
  };

  const renderReports = (report: UseQueryResult<ParsedDealerReport>) => {
    if (report.isLoading) return <Loader active inline />;

    if (report.isError) return <Message>{t("v8_failed_to_get_report").message || "Failed to get the report"}</Message>;

    if (!report.data) return null;

    if (!report.data.report_data?.length)
      return (
        <DetailsCards title={tabsData.get(`report_${report.data?.id}`)?.title} id={`report_${report.data?.id}`} isExpanded={expanded}>
          <div className="DealerReports__container-no-data">{t("no_data").message || "No data"}</div>
        </DetailsCards>
      );

    const thresholds = report.data?.thresholds || [];

    if (thresholds?.length > 0) {
      thresholds.forEach((th: ReportThreshold) => {
        if (th.name) th.name = t(th.name).message || th.name;
      });
    }

    if (report.data.report_type === "DealerQualityChecksTabular")
      return (
        <DealerQualityChecksTabular
          report={report}
          isExpanded={expanded}
          id={`report_${report.data.id}`}
          handleExportData={handleExportData}
          title={tabsData.get(`report_${report.data.id}`)?.title}
        />
      );

    if (report.data.report_type === "SimpleBarChart")
      return (
        <DetailsCards title={tabsData.get(`report_${report.data.id}`)?.title} id={`report_${report.data.id}`} isExpanded={expanded}>
          <SimpleBarChart
            key={report.data.report_name}
            colors={report.data.report_colors || []}
            thresholds={thresholds}
            data={report.data.report_data}
            unit={report.data.unit}
          />
        </DetailsCards>
      );

    if (report.data.report_type === "Tabular")
      return (
        <DetailsCards
          title={tabsData.get(`report_${report.data.id}`)?.title}
          id={`report_${report.data.id}`}
          rightContent={
            <div className="export-button">
              <Button icon color="green" basic onClick={() => handleExportData(report.data.report_name, report.data.report_data)}>
                <Icon className="file export" />
              </Button>
            </div>
          }
          isExpanded={expanded}
        >
          {report.data.id === UserReportsTableID ? <DealerUsersReportTable data={report.data.report_data} /> : <DealerTableReport data={report.data.report_data} />}
        </DetailsCards>
      );
  };

  const renderEmailStats = () => {
    if (selectedGroup !== 3) return;

    if (emailStats.isLoading) return <Loader active inline />;
    if (emailStats.error)
      return (
        <DetailsCards title={tabsData.get(`email_stats`)?.title} id={`email_stats`} isExpanded={expanded}>
          <div className="DealerReports__container-no-data">{t(emailStats.error?.message).message || emailStats.error.message}</div>
        </DetailsCards>
      );

    if (!emailStats.data) return null;

    if (!emailStats.data.length)
      return (
        <DetailsCards title={tabsData.get(`email_stats`)?.title} id={`email_stats`} isExpanded={expanded}>
          <div className="DealerReports__container-no-data">{t("no_data").message || "No data"}</div>
        </DetailsCards>
      );

    return (
      <DetailsCards title={tabsData.get(`email_stats`)?.title} id={"email_stats"} isExpanded={expanded}>
        <SimpleBarChart key="email_stats" data={emailStats.data} />
      </DetailsCards>
    );
  };

  return (
    <>
      <NavPortal>
        <div className="nav-bar-start DealerReports__dropdowns">
          <div className="DealerReports__dropdowns-container">
            <label htmlFor="dealer_report_group">{t("report").message || "Report"}</label>
            <Dropdown
              id="dealer_report_group"
              className="nav-bar-map"
              button
              floating
              labeled
              selection
              value={Number(selectedGroup)}
              onChange={(_e, { value }) => setSelectedGroup(Number(value))}
              options={groups.data?.map(i => ({ key: i, value: Number(i), text: t(`report_group_${i}`).message || i }))}
            />

            <label htmlFor="dealer_report_period">{t("v8_select_time_period").message || "Select time period"}</label>
            <Dropdown
              id="dealer_report_period"
              className="nav-bar-map"
              button
              floating
              labeled
              selection
              value={selectedPeriod}
              onChange={(_e, { value }) => onSelectPeriod(value as PERIOD)}
              options={Object.values(REPORT_PERIOD)
                .filter(value => !!periodLabels[value as PERIOD])
                .map(value => ({
                  key: value,
                  text: periodLabels[value as PERIOD],
                  value
                }))}
            />
            {showCustomRange && (
              <DatePicker onDateChange={handleDateChange} selectsRange startDate={dateFrom} endDate={dateTo} maxDate={moment(dateFrom).add(90, "d").toDate()} showLabel />
            )}
          </div>
        </div>
      </NavPortal>

      <div className="DealerReports__container">
        <div className="DealerReports__container-header">
          <div className="DealerReports__container-header-wrapper">
            <div className="row">
              <Tabs data={tabsData} hideBorder />
              <Button className="-appointment-status icon" size="small" onClick={toggleExpand}>
                <Icon className={`arrows ${expanded ? "minimize" : "maximize"}`} color="green" />
              </Button>
            </div>
          </div>
        </div>

        <div className="DealerReports__container-content">
          {loading && <Loader active />}
          {reports.map(r => renderReports(r))}
          {renderEmailStats()}
        </div>
      </div>
    </>
  );
};

export default DealerReports;
