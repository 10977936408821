import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Popup } from "semantic-ui-react";

import { DetailsCards, ReactTable, TabData } from "components";
import "modules/CarDetails/components/AppointmentHistory/AppointmentHistory.scss";
import { AppointmentHistoryHeader } from "modules/CarDetails/components/AppointmentHistory/components/AppointmentHistoryHeader";
import { AppointmentHistory as AppointmentHistoryModel, Checklist } from "modules/CarDetails/hooks";
import { ITranslation } from "util/interfaces";

type AppointmentHistoryProps = {
  tabData: TabData;
  isExpanded: boolean;
};

export const AppointmentHistory = ({ tabData, isExpanded }: AppointmentHistoryProps) => {
  const checkListColumnHelper = createColumnHelper<Checklist>();
  const t = useTranslation().t as ITranslation;

  const checkListColumns = [
    checkListColumnHelper.accessor(row => row.name, {
      id: "name",
      cell: info => <Popup hoverable content={info.renderValue()} trigger={<p className="ellipsis medium name-cell">{info.renderValue()}</p>} />
    }),
    checkListColumnHelper.accessor(row => row.name, {
      id: "WO",
      cell: () => null
    }),
    checkListColumnHelper.accessor(row => row.photo_count, {
      id: "photo_count",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.n_count, {
      id: "n_count",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.n_count_sold, {
      id: "n_count_sold",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.a_count, {
      id: "a_count",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.a_count_sold, {
      id: "a_count_sold",
      cell: info => info.renderValue()
    }),
    checkListColumnHelper.accessor(row => row.a_count_sold, {
      id: "icons",
      cell: () => <div />
    })
  ];

  return (
    <DetailsCards
      containerStyle="mt-20"
      isExpanded={isExpanded}
      id={tabData.id}
      icon="calendar clock"
      title={`${t("v8_appointment_history").message || "Appointment History"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
    >
      <div className="AppointmentHistory">
        {tabData.data?.map((appointment: AppointmentHistoryModel, index: number) => {
          return (
            <ReactTable
              key={`appointment-checklist-${index}`}
              renderEmptyRow={!appointment?.checklists?.length}
              columns={checkListColumns}
              data={appointment.checklists || []}
              emptyRowMessage={t("v8_no_checklists_in_the_appointment").message || "No checklists in the appointment"}
              customHeader={
                <>
                  <tr>
                    <th>
                      <AppointmentHistoryHeader appointment={appointment} propertyName={"date"} />
                    </th>
                    <th>
                      <AppointmentHistoryHeader appointment={appointment} propertyName={"wo_nr"} />
                    </th>
                    <th>
                      <AppointmentHistoryHeader appointment={appointment} propertyName={"location_name"} />
                    </th>
                    <th>
                      <AppointmentHistoryHeader appointment={appointment} propertyName={"next_date"} />
                    </th>
                    <th>
                      <AppointmentHistoryHeader appointment={appointment} propertyName={"current_km"} />
                    </th>
                    <th>
                      <AppointmentHistoryHeader appointment={appointment} propertyName={"next_km"} />
                    </th>
                    <th>
                      <AppointmentHistoryHeader appointment={appointment} propertyName={"reg_number"} />
                    </th>
                    <th>
                      <AppointmentHistoryHeader appointment={appointment} propertyName={"id"} />
                    </th>
                  </tr>
                  <div className="divider" />
                </>
              }
            />
          );
        })}
      </div>
    </DetailsCards>
  );
};
