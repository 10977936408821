import { DragOverlay, DropAnimation, defaultDropAnimationSideEffects } from "@dnd-kit/core";

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: { opacity: "0.4" }
    }
  })
};

export const SortableOverlay = () => {
  return <DragOverlay dropAnimation={dropAnimationConfig} />;
};
