import { useState } from "react";

import { DropdownTableSelectorProps } from ".";

export const useDropdownSelector = ({ handleOnSelect, options, selected, multiple, withSearch }: DropdownTableSelectorProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleOpen = () => setShowDropdown(true);
  const handleClose = () => setShowDropdown(false);
  const getFilteredOptions = () => options.filter(opt => opt.text.toLowerCase().includes(searchTerm.toLowerCase()));
  const handleOnClick = (e: React.SyntheticEvent, value: string) => {
    if (multiple) {
      const isSelected = selected.includes(value);
      const newSelected = isSelected ? selected.filter(el => el !== value) : [...selected, value];
      handleOnSelect(newSelected);
    } else {
      handleOnSelect([value]);
      handleClose();
    }

    e.stopPropagation();
  };

  const dropdownOptions = withSearch ? getFilteredOptions() : options;

  return { dropdownOptions, setSearchTerm, showDropdown, handleOpen, handleClose, handleOnClick };
};
