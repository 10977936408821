import { useTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";

import { ITranslation } from "util/interfaces";

type Props = {
  handleOnClick: () => void;
  hasWarning: boolean;
};

export const ExportDataButton = ({ handleOnClick, hasWarning }: Props) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Popup
      content={`${t("v8_you_cannot_export_leads_for_more_than_one_location").message || "You cannot export leads for more than one location"}.`}
      on="hover"
      position="bottom right"
      pinned
      style={{ opacity: hasWarning ? 1 : 0 }}
      trigger={
        <Button basic icon className="toolbar-button" onClick={hasWarning ? () => {} : handleOnClick}>
          <Icon className="file export limegreen-color" />
        </Button>
      }
    />
  );
};
