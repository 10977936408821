import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon, Label, Popup } from "semantic-ui-react";

import { DATE_FORMATS } from "components";
import { NoteProps } from "modules/Dayplanner/components/AppointmentCard/components/NotificationIcons";
import { ITranslation } from "util/interfaces";

export const CallCustomerNote = ({ note: callCustomerNote }: NoteProps) => {
  const t = useTranslation().t as ITranslation;

  return (
    <Popup
      wide
      basic
      content={
        <div className="AppointmentNotePopup">
          <div className="AppointmentNoteTitle">
            <Icon className="phone flip green" />
            <span>{t("v8_call_customer_note").message || "Call Customer Note"}</span>
          </div>
          <div className="AppointmentNoteDescription">
            {callCustomerNote?.updated_by
              ? `${callCustomerNote?.updated_by.first_name} ${callCustomerNote?.updated_by.last_name} ${t("v8_updated_call_customer_note").message || "updated Call Customer Note"} `
              : `${callCustomerNote?.user?.first_name} ${callCustomerNote?.user?.last_name} ${t("v8_added_call_customer_note").message || "added Call Customer Note"} `}
            {`${moment(callCustomerNote?.updated_on).format(DATE_FORMATS.dateMonthYearTime)}`}
          </div>

          <div className="AppointmentNoteContent">
            <Label className="CallCustomerPhoneNr">{callCustomerNote?.phone_nr}</Label>
            <div>{callCustomerNote?.note}</div>
          </div>
        </div>
      }
      trigger={
        <Label circular color="green">
          <Icon className="phone flip white" />
        </Label>
      }
    />
  );
};
