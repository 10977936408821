import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Car } from "models";
import { AppointmentHistory } from "modules/CarDetails/hooks/useAppointmentHistory";
import { CarDetailsKeys } from "modules/CarDetails/queryKeys";
import apiInstance from "util/Api";
import { ITranslation } from "util/interfaces";

export const useDeleteTyres = () => {
  const queryClient = useQueryClient();
  const t = useTranslation().t as ITranslation;

  const { mutate, isPending } = useMutation({
    mutationFn: async (car_id: number) => {
      await apiInstance.post(`/tyres/remove_storage_set`, { car_id });
    },

    onMutate: (id: number) => {
      const previousQueryData = queryClient.getQueryData(CarDetailsKeys.details(id)) as Car;
      queryClient.setQueryData(CarDetailsKeys.details(id), { ...previousQueryData, in_storage_tyres: null });
      return { previousQueryData };
    },
    onError: (_, params, context) => {
      queryClient.setQueryData([CarDetailsKeys.details(params)], () => context?.previousQueryData);
    }
  });

  const openPortalDBB = async (lastAppointment: AppointmentHistory | undefined) => {
    if (!lastAppointment) return;
    try {
      const res = await apiInstance.post("/appointments/portal_dbb", { id: lastAppointment.id });
      window.open(res.data, "_blank");
    } catch (err) {
      toast.error(t("v8_error").message || "Something went really wrong ");
      throw err;
    }
  };

  return { mutate, isPending, openPortalDBB };
};
