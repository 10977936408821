import { QueryKey, useQueryClient } from "@tanstack/react-query";

import { WebSocketMessageListener } from "components";
import ENV from "config/Env";
import { useRealTimeQuery } from "hooks";
import { PinModel } from "models";
import { CarDetailsKeys } from "modules/CarDetails/queryKeys";
import ApiInstance from "util/Api";
import { BackendQueryKey } from "util/keyFactory";

export const usePinItems = (car_id: number) => {
  const queryClient = useQueryClient();
  const carPinItemsQueryKey = CarDetailsKeys.pinItems(car_id);
  const updatePinData = (updateFn: (pinItems: PinModel[]) => PinModel[]) => {
    const pinItems: PinModel[] | undefined = queryClient.getQueryData(carPinItemsQueryKey);
    if (!pinItems) return;
    queryClient.setQueryData(carPinItemsQueryKey, updateFn(pinItems));
  };

  const listeners: WebSocketMessageListener[] = [
    {
      model: "Pin",
      action: "append",
      filter: {
        car_id
      },
      callback: message => {
        updatePinData(() => {
          const pinData = message.data as PinModel;
          const pinItems: PinModel[] | undefined = queryClient.getQueryData(carPinItemsQueryKey);
          let updatedPinItemsData = [];
          if (pinItems?.length) {
            updatedPinItemsData = pinItems.concat(pinData);
          } else {
            updatedPinItemsData = [pinData];
          }

          return updatedPinItemsData;
        });
      }
    },
    {
      model: "Pin",
      action: "delete",
      filter: {
        car_id
      },
      callback: message => {
        updatePinData(() => {
          const pinData = message.data as PinModel;
          const pinItems: PinModel[] | undefined = queryClient.getQueryData(carPinItemsQueryKey);
          if (!pinItems || !pinItems?.length) {
            return [];
          }
          const updatedPinItemsData = pinItems.filter(pinItem => pinItem.id !== pinData.id);

          return updatedPinItemsData;
        });
      }
    }
  ];

  const getPinItems = async ({ queryKey }: { queryKey: QueryKey }) => {
    const { endpoint } = queryKey[0] as BackendQueryKey;
    const res = await ApiInstance.post(endpoint, { car_id }, ENV.appointmentBaseURL);
    return res.data.items;
  };

  return useRealTimeQuery({ queryKey: carPinItemsQueryKey, queryFn: getPinItems, listeners });
};
