import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, MouseSensor, TouchSensor, pointerWithin, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToWindowEdges, snapCenterToCursor } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, DropdownProps, Icon, Label } from "semantic-ui-react";

import { NavBarSearch, NavBarSearchAppointments, NavPortal } from "components";
import { useSearchTerm } from "components/NavBarSearch/useSearchTerm";
import { useDealersLocations } from "hooks";
import { AppointmentCard, DAYPLANNER_STATUSES, MechanicBox, StatusFilters, UnassignedAppointmentCard, UnassignedColumn } from "modules/Dayplanner/components";
import "modules/Dayplanner/Dayplanner.scss";
import { useAssignMutation, useDayplanner, useReassignMutation, useUnassignMutation } from "modules/Dayplanner/hooks";
import {
  DAYPLANNER_COLUMNS,
  DayplannerAppointment,
  DayplannerColumns,
  MECHANIC_FILTERS,
  MECHANIC_SORTING,
  MechanicWithAppointments,
  WO_INTERNAL_FILTER
} from "modules/Dayplanner/interfaces";
import { applyFilters, ascendingSort, descendingSort, prepareDayplannerData } from "modules/Dayplanner/utils";
import { getPreference, setPreference } from "util/common";
import { ITranslation } from "util/interfaces";
import { AscImg, DescImg } from "util/staticAssets";

const initialState: DayplannerColumns = {
  appointmentsColumn: [],
  unassignedColumn: [],
  mechanicsColumn: [],
  carReadyColumn: [],
  qualityControlColumn: []
};

const activeFiltersInitialState = [
  DAYPLANNER_STATUSES.NewCar,
  DAYPLANNER_STATUSES.CarInShop,
  DAYPLANNER_STATUSES.Panic,
  DAYPLANNER_STATUSES.InitialCheckDone,
  DAYPLANNER_STATUSES.HandleCheckin,
  DAYPLANNER_STATUSES.CarCheck,
  DAYPLANNER_STATUSES.CustomerAnswered,
  DAYPLANNER_STATUSES.CustomerOK,
  DAYPLANNER_STATUSES.Diagnose
];

enum PREFERENCE_KEYS {
  MECHANIC_SORTING = "preferences-dayplanner-mechanics-sorting",
  PINNED_MECHANICS = "preferences-dayplanner-pinned-mechanics",
  SELECTED_MECHANIC = "preferences-dayplanner-selected-mechanic-filter",
  SELECTED_WO_INTERNAL = "preferences-dayplanner-selected-WOInternal-filter",
  SELECTED_ACTIVE_FILTER = "preferences-dayplanner-selected-status-filter",
  COLLAPSED_COLUMNS = "preferences-dayplanner-collapsed-columns"
}

interface DayplannerProps {
  t: ITranslation;
}

const modifiers = [restrictToWindowEdges, snapCenterToCursor];

const Dayplanner = ({ t }: DayplannerProps) => {
  const [columns, setColumns] = useState(initialState);
  const [defaultColumns, setDefaultColumns] = useState(initialState);
  const [preparedAppointments, setPreparedAppointments] = useState<DayplannerAppointment[]>([]);
  const [collapsedColumns, setCollapsedColumns] = useState<DAYPLANNER_COLUMNS[]>(getPreference(PREFERENCE_KEYS.COLLAPSED_COLUMNS, []));
  const [numberOfCardShownInMechanicColumn, setNumberOfCardShownInMechanicColumn] = useState(0);
  const [mechanicsFilter, setMechanicsFilter] = useState<MECHANIC_FILTERS>(getPreference(PREFERENCE_KEYS.SELECTED_MECHANIC, MECHANIC_FILTERS.All));
  const [selectedWoInternalFilter, setSelectedWoInternalFilter] = useState<WO_INTERNAL_FILTER>(
    getPreference(PREFERENCE_KEYS.SELECTED_WO_INTERNAL, WO_INTERNAL_FILTER.All)
  );
  const [draggedAppointment, setDraggedAppointment] = useState<DayplannerAppointment | null>(null);
  const [draggedOverMechanicId, setDraggedOverMechanicID] = useState<number | null>(null);
  const [activeFilters, setActiveFilters] = useState<DAYPLANNER_STATUSES[]>(getPreference(PREFERENCE_KEYS.SELECTED_ACTIVE_FILTER, activeFiltersInitialState));
  const [autoExpandedMechanics, setAutoExpandedMechanics] = useState<Array<number>>([]);
  const [mechanicsPinned, setMechanicsPinned] = useState<number[]>(getPreference(PREFERENCE_KEYS.PINNED_MECHANICS, []));
  const [mechanicsSorting, setMechanicsSorting] = useState<MECHANIC_SORTING>(getPreference(PREFERENCE_KEYS.MECHANIC_SORTING, MECHANIC_SORTING.Ascending));

  const appointmentsColumnRef = useRef<HTMLDivElement>(null);
  const unassignedColumnRef = useRef<HTMLDivElement>(null);
  const mechanicsColumnRef = useRef<HTMLDivElement>(null);
  const carReadyColumnRef = useRef<HTMLDivElement>(null);
  const qualityControlColumnRef = useRef<HTMLDivElement>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, { activationConstraint: { delay: 250, tolerance: 100 } })
  );

  const { mechanics, appointments, loading, error } = useDayplanner();
  const assignMutation = useAssignMutation();
  const unassignMutation = useUnassignMutation();
  const reassignMutation = useReassignMutation();

  const { selectedLocation } = useDealersLocations();
  const searchTerm = useSearchTerm();

  useEffect(() => {
    document.getElementById("containerId")?.setAttribute("style", "overflow: hidden !important;");

    return () => {
      document.getElementById("containerId")?.removeAttribute("style");
    };
  }, []);

  useEffect(() => {
    if (draggedAppointment) {
      appointmentsColumnRef.current?.classList.add("-column-not-allowed-to-drop");
      carReadyColumnRef.current?.classList.add("-column-not-allowed-to-drop");
      qualityControlColumnRef.current?.classList.add("-column-not-allowed-to-drop");
    } else {
      appointmentsColumnRef.current?.classList.remove("-column-not-allowed-to-drop");
      carReadyColumnRef.current?.classList.remove("-column-not-allowed-to-drop");
      qualityControlColumnRef.current?.classList.remove("-column-not-allowed-to-drop");
    }
  }, [draggedAppointment]);

  useEffect(() => {
    if (!loading && mechanics && appointments) {
      const { columns, dayplannerAppointments } = prepareDayplannerData(mechanics, appointments, mechanicsSorting, mechanicsPinned);

      setColumns(columns);
      setDefaultColumns(columns);
      setPreparedAppointments(dayplannerAppointments);
    }
  }, [loading, mechanics, appointments, mechanicsSorting, mechanicsPinned, selectedLocation]);

  useEffect(() => {
    if (!mechanicsColumnRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      let numberOfCards = 3;

      for (const entry of entries) {
        const { width } = entry.contentRect;
        const MARGIN_VALUE = 30;
        const CARD_WIDTH = 180;
        numberOfCards = Math.floor(width / (CARD_WIDTH + MARGIN_VALUE));

        setNumberOfCardShownInMechanicColumn(numberOfCards);
        break;
      }

      for (const card of document.getElementsByClassName("MechanicBox-appointmentCards") as HTMLCollectionOf<HTMLElement>)
        card.style.gridTemplateColumns = `repeat(${numberOfCards}, 1fr)`;
    });

    resizeObserver.observe(mechanicsColumnRef.current);

    return () => resizeObserver.disconnect();
  }, [mechanicsColumnRef.current]);

  const filteredColumns = useMemo(() => {
    if (loading) return columns;
    return applyFilters(columns, activeFilters, searchTerm, selectedWoInternalFilter);
  }, [columns, activeFilters, searchTerm, selectedWoInternalFilter, loading]);

  if (!selectedLocation) return null;

  if (!selectedLocation.is_dayplanner_enabled)
    return <div className="dayplanner-disabled">{t("v8_dayplanner_not_enabled_for_selected_location").message || "Dayplanner is not enabled for this location."}</div>;

  if (error) return <div>Error {error.message}</div>;

  const handleSelectMechanicSorting = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = data.value as MECHANIC_SORTING;

    setColumns(prevColumns => ({
      ...prevColumns,
      mechanicsColumn: [...columns.mechanicsColumn].sort(value === MECHANIC_SORTING.Ascending ? ascendingSort : descendingSort)
    }));

    setMechanicsSorting(value);
    setPreference(PREFERENCE_KEYS.MECHANIC_SORTING, value);
  };

  const handleToggleMechanicPin = (mechanic_id: number) => {
    const mechanicPinnedCopy = [...mechanicsPinned];
    const mechanicIndex = mechanicPinnedCopy.indexOf(mechanic_id);
    if (mechanicIndex === -1) {
      mechanicPinnedCopy.push(mechanic_id);
    } else {
      mechanicPinnedCopy.splice(mechanicIndex, 1);
    }
    setMechanicsPinned(mechanicPinnedCopy);
    setPreference(PREFERENCE_KEYS.PINNED_MECHANICS, mechanicPinnedCopy);
  };

  const handleToggleCollapseColumn = (column: DAYPLANNER_COLUMNS) => {
    const newCollapsedColumns: DAYPLANNER_COLUMNS[] = collapsedColumns.includes(column) ? collapsedColumns.filter(col => col !== column) : [...collapsedColumns, column];

    setCollapsedColumns(newCollapsedColumns);
    setPreference(PREFERENCE_KEYS.COLLAPSED_COLUMNS, newCollapsedColumns);
  };

  const handleOverMechanic = (mechanicId: number) => {
    if (autoExpandedMechanics.includes(mechanicId)) return;

    setAutoExpandedMechanics(autoExpandedMechanics => autoExpandedMechanics.concat(mechanicId));
  };

  const handleSelectMechanicFilter = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = data.value as MECHANIC_FILTERS;

    setMechanicsFilter(value);
    setPreference(PREFERENCE_KEYS.SELECTED_MECHANIC, value);
  };

  const handleWoInternalFilter = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = data.value as WO_INTERNAL_FILTER;

    setSelectedWoInternalFilter(value);
    setPreference(PREFERENCE_KEYS.SELECTED_WO_INTERNAL, value);
  };

  const handleSelectStatusFilter = (selectedFilter: DAYPLANNER_STATUSES) => {
    const selectedFilters = activeFilters.includes(selectedFilter) ? activeFilters.filter(filter => filter !== selectedFilter) : [...activeFilters, selectedFilter];

    setActiveFilters(selectedFilters);
    setPreference(PREFERENCE_KEYS.SELECTED_ACTIVE_FILTER, selectedFilters);
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    let app: DayplannerAppointment | null = null;

    if (active.data.current?.mechanicId) {
      app = columns.mechanicsColumn.find(m => m.id === active.data.current?.mechanicId)?.appointments.find(a => a.id === active.data.current?.appointment?.id) ?? null;
    } else app = columns.unassignedColumn.find(a => a.id === active.data.current?.appointment?.id) ?? null;

    setDraggedAppointment(app ? { ...app } : null);
  };

  const resetDrag = () => {
    setDraggedAppointment(null);
    setDraggedOverMechanicID(null);
    setAutoExpandedMechanics([]);
  };

  const handleDragCancel = () => {
    setColumns(defaultColumns);
    resetDrag();
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    setDraggedOverMechanicID(over?.data.current?.mechanicId ?? null);

    // this method handle only moving an appointment from one container to another (from one mechanic to another, or unassigned to assigned, or vice versa)
    // the sorting within a container is done by dnd-kit in css and does not actually change the appointment order in our state
    // applying the sorting whithin a container is done in handleDragOver if needed
    // here we return early if we are not dragging over a different container
    if (!draggedAppointment || !over?.id || over.id === active.id || over.data.current?.mechanicId === active.data.current?.mechanicId) return;

    if (over.data.current?.unassigned) {
      if (columns.unassignedColumn.length === 0) {
        setColumns(columns => ({
          ...columns,
          unassignedColumn: columns.unassignedColumn.concat({
            ...draggedAppointment,
            assigned_mechanic: null,
            assigned_mechanic_order: null
          }),
          mechanicsColumn: columns.mechanicsColumn.map(m => ({ ...m, appointments: m.appointments.filter(a => active.id !== a.id) }))
        }));
      } else if (over.data.current.appointment && !columns.unassignedColumn.some(a => a.id === active.id)) {
        setColumns(columns => ({
          ...columns,
          unassignedColumn: columns.unassignedColumn.toSpliced(columns.unassignedColumn.findIndex(a => a.id === over.id) + 1, 0, {
            ...draggedAppointment,
            assigned_mechanic: null,
            assigned_mechanic_order: null
          }),
          mechanicsColumn: columns.mechanicsColumn.map(m => ({ ...m, appointments: m.appointments.filter(a => active.id !== a.id) }))
        }));
      }

      return;
    }

    if (over.data.current?.mechanicId && over.data.current.order) {
      if (active.data.current?.unassigned && !columns.mechanicsColumn.some(m => m.appointments.some(a => a.id === active.id))) {
        setColumns(columns => ({
          ...columns,
          unassignedColumn: columns.unassignedColumn.filter(a => active.id !== a.id),
          mechanicsColumn: columns.mechanicsColumn.map(m => {
            if (m.id === over.data.current?.mechanicId) {
              return {
                ...m,
                appointments: m.appointments
                  .map(a =>
                    Number(a.assigned_mechanic_order) >= over.data.current?.order ? { ...a, assigned_mechanic_order: Number(a.assigned_mechanic_order) + 1 } : a
                  )
                  .concat({ ...draggedAppointment, assigned_mechanic: m.id, assigned_mechanic_order: over.data.current?.order })
              };
            }

            return m;
          })
        }));
      } else if (!columns.mechanicsColumn.some(m => m.id === over.data.current?.mechanicId && m.appointments.some(a => a.id === active.id))) {
        setColumns(columns => ({
          ...columns,
          mechanicsColumn: columns.mechanicsColumn.map(m => {
            if (m.id === active.data.current?.mechanicId)
              return {
                ...m,
                appointments: m.appointments
                  .filter(a => a.id !== active.id)
                  .map(a =>
                    Number(a.assigned_mechanic_order) > active.data.current?.order ? { ...a, assigned_mechanic_order: Number(a.assigned_mechanic_order) - 1 } : a
                  )
              };

            if (m.id === over.data.current?.mechanicId) {
              return {
                ...m,
                appointments: m.appointments
                  .map(a =>
                    Number(a.assigned_mechanic_order) >= over.data.current?.order ? { ...a, assigned_mechanic_order: Number(a.assigned_mechanic_order) + 1 } : a
                  )
                  .concat({ ...draggedAppointment, assigned_mechanic: m.id, assigned_mechanic_order: over.data.current?.order })
              };
            }

            return m;
          })
        }));
      }
    }
  };

  const handleDragEnd = ({ over }: DragEndEvent) => {
    if (!over?.id || !draggedAppointment || (over.data.current?.unassigned && !draggedAppointment.assigned_mechanic)) {
      handleDragCancel();
      return;
    }

    if (over.data.current?.mechanicId) {
      const app = columns.mechanicsColumn.find(m => m.id == over.data.current?.mechanicId)?.appointments.find(a => a.id === draggedAppointment.id);
      if (!app?.assigned_mechanic_order) {
        handleDragCancel();
        return;
      }

      let newOrder = app.assigned_mechanic_order;
      if (!over.data.current.placeholder && app.assigned_mechanic_order !== over.data.current.order) {
        setColumns(columns => ({
          ...columns,
          mechanicsColumn: columns.mechanicsColumn.map(m => {
            if (m.id === over.data.current?.mechanicId) {
              if (Number(app.assigned_mechanic_order) < over.data.current.order) {
                return {
                  ...m,
                  appointments: m.appointments.map(a => {
                    if (a.id === app.id) return { ...a, assigned_mechanic_order: over.data.current?.order };
                    if (Number(a.assigned_mechanic_order) > Number(app.assigned_mechanic_order) && Number(a.assigned_mechanic_order) <= over.data.current?.order)
                      return { ...a, assigned_mechanic_order: Number(a.assigned_mechanic_order) - 1 };

                    return a;
                  })
                };
              } else {
                return {
                  ...m,
                  appointments: m.appointments.map(a => {
                    if (a.id === app.id) return { ...a, assigned_mechanic_order: over.data.current?.order };
                    if (Number(a.assigned_mechanic_order) >= Number(app.assigned_mechanic_order) && Number(a.assigned_mechanic_order) < over.data.current?.order)
                      return { ...a, assigned_mechanic_order: Number(a.assigned_mechanic_order) + 1 };

                    return a;
                  })
                };
              }
            }

            return m;
          })
        }));

        newOrder = over.data.current.order;
      }

      const data = { appointment_id: draggedAppointment.id, user_id: over.data.current.mechanicId, order: newOrder };
      if (!draggedAppointment.assigned_mechanic_order) assignMutation(data);
      else reassignMutation(data);
    } else if (over.data.current?.unassigned) {
      setDefaultColumns(columns);
      unassignMutation({ appointment_id: draggedAppointment.id });
    }

    resetDrag();
  };

  const isAppointmentsColumnCollapsed = collapsedColumns.includes(DAYPLANNER_COLUMNS.Appointments);
  const isCarReadyColumnCollapsed = collapsedColumns.includes(DAYPLANNER_COLUMNS.CarReady);
  const isQualityCheckColumnCollapsed = collapsedColumns.includes(DAYPLANNER_COLUMNS.QualityCheck);

  return (
    <DndContext
      sensors={sensors}
      modifiers={modifiers}
      collisionDetection={pointerWithin}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <NavPortal>
        <StatusFilters appointments={preparedAppointments} activeFilters={activeFilters} onSelect={handleSelectStatusFilter} />
        <NavBarSearch>
          <NavBarSearchAppointments />
        </NavBarSearch>
      </NavPortal>
      <div className="Dayplanner">
        <div className={`Dayplanner-columnContainer ${isAppointmentsColumnCollapsed ? "-collapsed" : ""}`}>
          <div className="Dayplanner-columnHeader">
            <h4>{isAppointmentsColumnCollapsed ? t("v8_appointments_short").message || "AP" : t("v8_appointments").message || "Appointments"}</h4>

            <Label onClick={() => handleToggleCollapseColumn(DAYPLANNER_COLUMNS.Appointments)}>
              <Icon className={isAppointmentsColumnCollapsed ? "arrows maximize" : "arrows minimize"} color="green" />
            </Label>
          </div>

          <div ref={appointmentsColumnRef} className="Dayplanner-column">
            {!loading &&
              filteredColumns.appointmentsColumn.map(app => (
                <AppointmentCard key={app.id} appointment={app} column={DAYPLANNER_COLUMNS.Appointments} isColumnCollapsed={isAppointmentsColumnCollapsed} />
              ))}
          </div>
        </div>

        <UnassignedColumn>
          <div className="Dayplanner-columnHeader">
            <h4>{t("v8_unassigned").message || "Unassigned"}</h4>
          </div>

          <div ref={unassignedColumnRef} className="Dayplanner-column">
            <SortableContext items={columns.unassignedColumn.map(a => a.id)} strategy={verticalListSortingStrategy}>
              {!loading &&
                filteredColumns.unassignedColumn.map(app => <UnassignedAppointmentCard key={app.id} appointment={app} column={DAYPLANNER_COLUMNS.Unassigned} />)}
            </SortableContext>
          </div>
        </UnassignedColumn>

        <div className="Dayplanner-columnContainer -mechanic-tasks -flex-grow-3" ref={mechanicsColumnRef}>
          <div className="Dayplanner-columnHeader">
            <h4>{t("v8_mechanics_tasks").message || "Mechanic's Tasks"}</h4>
            <div className="Dayplanner-columnHeader-section">
              <Dropdown
                button
                className="icon"
                floating
                labeled
                icon={"filter"}
                options={[
                  { key: 1, value: WO_INTERNAL_FILTER.All, text: t("v8_all").message || "All" },
                  { key: 2, value: WO_INTERNAL_FILTER.WO, text: t("v8_wo").message || "WO" },
                  { key: 3, value: WO_INTERNAL_FILTER.Internal, text: t("v8_internal").message || "Internal" }
                ]}
                value={selectedWoInternalFilter}
                onChange={handleWoInternalFilter}
              />
              <Dropdown
                button
                className="icon"
                floating
                labeled
                icon={"filter"}
                options={[
                  { key: 1, value: MECHANIC_FILTERS.All, text: t("v8_all").message || "All" },
                  { key: 2, value: MECHANIC_FILTERS.HideOccupiedMechanics, text: t("v8_hide_occupied_mechanics").message || "Hide occupied mechanics" },
                  { key: 3, value: MECHANIC_FILTERS.HideUnoccupiedMechanics, text: t("v8_hide_unoccupied_mechanics").message || "Hide unoccupied mechanics" }
                ]}
                value={mechanicsFilter}
                onChange={handleSelectMechanicFilter}
              />
              <Dropdown
                button
                floating
                className="icon sort-btn"
                trigger={<img className="asc-desc-img" src={mechanicsSorting === MECHANIC_SORTING.Ascending ? AscImg : DescImg} />}
                options={[
                  { key: 1, value: MECHANIC_SORTING.Ascending, icon: <img className="asc-desc-img" src={AscImg} /> },
                  { key: 2, value: MECHANIC_SORTING.Descending, icon: <img className="asc-desc-img" src={DescImg} /> }
                ]}
                icon={null}
                value={mechanicsSorting}
                onChange={handleSelectMechanicSorting}
              />
            </div>
          </div>

          <div className="Dayplanner-column -mechanic-tasks">
            {!loading &&
              filteredColumns.mechanicsColumn.map((mechanic: MechanicWithAppointments) => {
                if (
                  (mechanicsFilter === MECHANIC_FILTERS.HideOccupiedMechanics && mechanic.appointments.length > 0) ||
                  (mechanicsFilter === MECHANIC_FILTERS.HideUnoccupiedMechanics && mechanic.appointments.length === 0)
                )
                  return null;

                return (
                  <div key={mechanic.id} className="MechanicBox-wrapper">
                    <MechanicBox
                      activeFilters={activeFilters}
                      searchTerm={searchTerm}
                      mechanic={mechanic}
                      key={mechanic.id}
                      mechanicsPinned={mechanicsPinned}
                      onToggleMechanicPin={handleToggleMechanicPin}
                      numberOfCardsPerRow={numberOfCardShownInMechanicColumn}
                      onOver={handleOverMechanic}
                      autoExpand={autoExpandedMechanics.includes(mechanic.id)}
                      draggedOverMechanicId={draggedOverMechanicId}
                    />
                  </div>
                );
              })}
          </div>
        </div>

        <div className={`Dayplanner-columnContainer ${isCarReadyColumnCollapsed ? "-collapsed" : ""}`}>
          <div className="Dayplanner-columnHeader">
            <h4>{isCarReadyColumnCollapsed ? t("v8_cars_ready_short").message || "CR" : t("v8_cars_ready").message || "Cars Ready"}</h4>

            <Label onClick={() => handleToggleCollapseColumn(DAYPLANNER_COLUMNS.CarReady)}>
              <Icon className={isCarReadyColumnCollapsed ? "arrows maximize" : "arrows minimize"} color="green" />
            </Label>
          </div>

          <div ref={carReadyColumnRef} className="Dayplanner-column">
            {!loading &&
              filteredColumns.carReadyColumn.map(app => (
                <AppointmentCard
                  key={app.id}
                  appointment={app}
                  column={DAYPLANNER_COLUMNS.CarReady}
                  isColumnCollapsed={isCarReadyColumnCollapsed}
                  mechanic={columns.mechanicsColumn.find(m => m.id === app.last_assigned_mechanic)}
                />
              ))}
          </div>
        </div>

        <div className={`Dayplanner-columnContainer ${isQualityCheckColumnCollapsed ? "-collapsed" : ""}`}>
          <div className="Dayplanner-columnHeader">
            <h4>{isQualityCheckColumnCollapsed ? t("v8_quality_control_short").message || "QC" : t("v8_quality_control").message || "Quality Control"}</h4>

            <Label onClick={() => handleToggleCollapseColumn(DAYPLANNER_COLUMNS.QualityCheck)}>
              <Icon className={isQualityCheckColumnCollapsed ? "arrows maximize" : "arrows minimize"} color="green" />
            </Label>
          </div>

          <div ref={qualityControlColumnRef} className="Dayplanner-column">
            {!loading &&
              filteredColumns.qualityControlColumn.map(app => (
                <AppointmentCard
                  key={app.id}
                  appointment={app}
                  column={DAYPLANNER_COLUMNS.QualityCheck}
                  isColumnCollapsed={isQualityCheckColumnCollapsed}
                  mechanic={columns.mechanicsColumn.find(m => m.id === app.planning_mechanic?.id)}
                />
              ))}
          </div>
        </div>
      </div>
      <DragOverlay>
        {draggedAppointment ? <AppointmentCard appointment={draggedAppointment} column={DAYPLANNER_COLUMNS.Appointments} isColumnCollapsed={false} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default withTranslation()(Dayplanner);
