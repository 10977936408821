import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { TabData } from "..";

export const useOverflowTabs = (data: Map<string, TabData>) => {
  const resizeObserver = useRef<ResizeObserver | null>(null);
  const [overflowTabs, setOverflowTabs] = useState<[string, TabData][]>([]);
  const [visibleTabs, setVisibleTabs] = useState<[string, TabData][]>([]);
  const [activeTab, setActiveTab] = useState<string>("");
  const [canSpy, setCanSpy] = useState(true);
  const tabsContainerRef = useRef<HTMLDivElement>(null);

  const calculateVisibleTabs = () => {
    if (!tabsContainerRef.current) return;

    const containerWidth = tabsContainerRef.current.offsetWidth;
    const tabsArray = Array.from(data.entries());

    if (containerWidth === 0 || tabsArray.length === 0) return;

    const visible: [string, TabData][] = [];
    const overflow: [string, TabData][] = [];

    const OVERFLOW_BUTTON_WIDTH = 110;
    const PADDING_PER_ITEM = 35;
    const MARGIN_SAFETY = 45;

    let availableWidth = containerWidth - OVERFLOW_BUTTON_WIDTH - MARGIN_SAFETY;

    tabsArray.forEach(([_key, item]) => {
      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden";
      tempSpan.style.position = "absolute";
      tempSpan.style.whiteSpace = "nowrap";
      tempSpan.textContent = item.title || "";
      document.body.appendChild(tempSpan);

      const itemWidth = Math.min(tempSpan.offsetWidth + PADDING_PER_ITEM, 300);
      document.body.removeChild(tempSpan);

      if (availableWidth - itemWidth >= 0) {
        availableWidth -= itemWidth;
        visible.push([_key, item]);
      } else overflow.push([_key, item]);
    });

    setVisibleTabs(visible);
    setOverflowTabs(overflow);
  };

  const handleTabClick = (id: string) => {
    if (id === activeTab) return;
    const overflowIndex = overflowTabs.findIndex(([_, item]) => item.id === id);

    if (overflowIndex < 0) {
      calculateVisibleTabs();
      setActiveTab(id);
      setCanSpy(false);
      return;
    }

    const lastVisibleTab = visibleTabs[visibleTabs.length - 1];

    setVisibleTabs(prev => {
      const newVisibleTabs = [...prev];
      newVisibleTabs[newVisibleTabs.length - 1] = overflowTabs[overflowIndex];
      return newVisibleTabs;
    });

    setOverflowTabs(prev => {
      const newOverflowTabs = [...prev];
      newOverflowTabs[overflowIndex] = lastVisibleTab;
      return newOverflowTabs;
    });
    setActiveTab(id);
    setCanSpy(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setCanSpy(false);
      const timer = setTimeout(() => setCanSpy(true), 250);
      return () => clearTimeout(timer);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useLayoutEffect(() => {
    if (!tabsContainerRef.current) return;

    resizeObserver.current = new ResizeObserver(calculateVisibleTabs);
    resizeObserver.current.observe(tabsContainerRef.current);

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [data]);

  useEffect(() => {
    calculateVisibleTabs();
  }, [data]);

  return { canSpy, setActiveTab, activeTab, handleTabClick, tabsContainerRef, visibleTabs, overflowTabs };
};
