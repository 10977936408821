import { Button, Icon } from "semantic-ui-react";

import "modules/LeadsDashboard/components/Filters/ResetFiltersButton/ResetFiltersButton.scss";

type Props = {
  handleReset: () => void;
};

export const ResetFiltersButton = ({ handleReset }: Props) => (
  <Button basic icon onClick={handleReset} className="toolbar-button">
    <div className="reset-button-icons-container">
      <Icon className="filter limegreen-color" />
      <Icon className="circle xmark limegreen-color reset-button-icon-circle-xmark" />
    </div>
  </Button>
);
