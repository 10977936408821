interface IEnv {
  apiBase: string;
  appointmentBaseURL: string;
  appointmentBaseURLv2: string;
  authBaseUrl: string;
  carBaseUrl: string;
  dealerBaseUrl: string;
  clientID: string;
  microsoftClientID: string;
  envName: string;
  zohoPrefix?: string;
  searchEnv: string;
  storageI18n: string;
  webSocketEndpoint: string;
  gaTrackingCode: string;
  sentryURL: string;
  zohoASAP: string;
  tyreteamBaseURL: string;
  keyloopBaseURL: string;
  autoflexBaseUrl: string;
  zohoDepartmentId: string;
  kioskBaseURL: string;
  importerBaseUrl: string;
  ccBaseURL: string;
  customComBaseURL: string;
  nextLaneBaseUrl: string;
  systemBaseURL: string;
  dmsV3ImportBaseUrl: string;
  keylockerBaseURL: string;
  reportsBaseURL: string;
  exactBaseUrl: string;
  deskCommBaseUrl: string;
}

interface IEnvironments {
  dev: IEnv;
  local: IEnv;
  prod: IEnv;
}

const ENVIRONMENTS: IEnvironments = {
  dev: {
    zohoDepartmentId: "22834000000007061",
    apiBase: "https://clairedev.nl/api/v2",
    appointmentBaseURL: "https://appointment.clairedev.nl/v1",
    appointmentBaseURLv2: "https://appointment.clairedev.nl/v2",
    authBaseUrl: "https://auth.clairedev.nl/v1",
    carBaseUrl: "https://car.clairedev.nl/v1",
    dealerBaseUrl: "https://dealer.clairedev.nl/v1",
    clientID: "700664373667-94su281c03e18urstpsm4vegid2lubct.apps.googleusercontent.com",
    microsoftClientID: "2c4cd78d-bc1c-4c97-a974-db6b20ecb75e",
    envName: "dev",
    zohoPrefix: "dev",
    searchEnv: "dev",
    storageI18n: "https://storage.googleapis.com/clairei18n",
    webSocketEndpoint: "wss://notifier.clairedev.nl",
    gaTrackingCode: "UA-114415392-2",
    sentryURL: "https://16dd86da404141d4917f1ad2019e61bb@sentry.io/1391180",
    zohoASAP: "https://desk.zoho.eu/portal/api/web/asapApp/22834000030544001?orgId=20066420619",
    tyreteamBaseURL: "https://tyreteam.clairedev.nl/v1",
    keyloopBaseURL: "https://keyloop.clairedev.nl/v1",
    autoflexBaseUrl: "https://autoflex.clairedev.nl/v1",
    kioskBaseURL: "https://kiosk.clairedev.nl/v1",
    importerBaseUrl: "https://importer.clairedev.nl/v3",
    ccBaseURL: "https://comm.clairedev.nl",
    customComBaseURL: "https://customcom.clairedev.nl/v1",
    nextLaneBaseUrl: "https://nextlane.clairedev.nl/v1",
    systemBaseURL: "https://system.clairedev.nl/v1",
    dmsV3ImportBaseUrl: "https://clairedev.nl/dmsv3",
    keylockerBaseURL: "https://keylocker.clairedev.nl/v1",
    reportsBaseURL: "https://report.clairedev.nl/v1",
    exactBaseUrl: "https://exact.clairedev.nl/v1",
    deskCommBaseUrl: "https://desk.clairedev.nl/v1"
  },
  local: {
    zohoDepartmentId: "22834000000007061",
    apiBase: "http://localhost:8080/api/v2",
    appointmentBaseURL: "http://localhost:8080/api/v1",
    appointmentBaseURLv2: "http://localhost:8080/api/v2",
    authBaseUrl: "https://auth.clairedev.nl/v1",
    carBaseUrl: "https://car.clairedev.nl/v1",
    dealerBaseUrl: "https://dealer.clairedev.nl/v1",
    clientID: "700664373667-94su281c03e18urstpsm4vegid2lubct.apps.googleusercontent.com",
    microsoftClientID: "2c4cd78d-bc1c-4c97-a974-db6b20ecb75e",
    envName: "dev",
    searchEnv: "dev",
    storageI18n: "https://storage.googleapis.com/clairei18n",
    webSocketEndpoint: "wss://notifier.clairedev.nl",
    gaTrackingCode: "UA-114415392-2",
    sentryURL: "https://16dd86da404141d4917f1ad2019e61bb@sentry.io/1391180",
    zohoASAP: "https://desk.zoho.eu/portal/api/web/asapApp/22834000030544001?orgId=20066420619",
    tyreteamBaseURL: "https://tyreteam.clairedev.nl/v1",
    keyloopBaseURL: "https://keyloop.clairedev.nl/v1",
    autoflexBaseUrl: "https://autoflex.clairedev.nl/v1",
    kioskBaseURL: "https://kiosk.clairedev.nl/v1",
    importerBaseUrl: "https://importer.clairedev.nl/v3",
    ccBaseURL: "https://comm.clairedev.nl",
    customComBaseURL: "https://customcom.clairedev.nl/v1",
    nextLaneBaseUrl: "https://nextlane.clairedev.nl/v1",
    systemBaseURL: "https://system.clairedev.nl/v1",
    dmsV3ImportBaseUrl: "https://clairedev.nl/dmsv3",
    keylockerBaseURL: "https://keylocker.clairedev.nl/v1",
    reportsBaseURL: "https://report.clairedev.nl/v1",
    exactBaseUrl: "https://exact.clairedev.nl/v1",
    deskCommBaseUrl: "https://desk.clairedev.nl/v1"
  },
  prod: {
    zohoDepartmentId: "22834000000007061",
    apiBase: "https://claireit.com/api/v2",
    appointmentBaseURL: "https://appointment.claireit.com/v1",
    appointmentBaseURLv2: "https://appointment.claireit.com/v2",
    authBaseUrl: "https://auth.claireit.com/v1",
    carBaseUrl: "https://car.claireit.com/v1",
    dealerBaseUrl: "https://dealer.claireit.com/v1",
    clientID: "674901036874-hrv3lhqlkp3b0uh8lhoaj0hlmrqd37it.apps.googleusercontent.com",
    microsoftClientID: "2b491317-d832-4533-b07b-95419a4d739c",
    envName: "prod",
    zohoPrefix: "pro",
    searchEnv: "prod",
    storageI18n: "https://storage.googleapis.com/clairei18n-prod",
    webSocketEndpoint: "wss://notifier.claireit.com",
    gaTrackingCode: "UA-114415392-1",
    sentryURL: "https://16dd86da404141d4917f1ad2019e61bb@sentry.io/1391180",
    zohoASAP: "https://desk.zoho.eu/portal/api/web/asapApp/22834000031001003?orgId=20066420619",
    tyreteamBaseURL: "https://tyreteam.claireit.com/v1",
    keyloopBaseURL: "https://keyloop.claireit.com/v1",
    autoflexBaseUrl: "https://autoflex.claireit.com/v1",
    kioskBaseURL: "https://kiosk.claireit.com/v1",
    importerBaseUrl: "https://importer.claireit.com/v3",
    ccBaseURL: "https://mycarcheck.pro",
    customComBaseURL: "https://customcom.claireit.com/v1",
    nextLaneBaseUrl: "https://nextlane.claireit.com/v1",
    systemBaseURL: "https://system.claireit.com/v1",
    dmsV3ImportBaseUrl: "https://claireit.com/dmsv3",
    keylockerBaseURL: "https://keylocker.claireit.com/v1",
    reportsBaseURL: "https://report.claireit.com/v1",
    exactBaseUrl: "https://exact.claireit.com/v1",
    deskCommBaseUrl: "https://desk.claireit.com/v1"
  }
};

const ENV: IEnv = typeof process.env.REACT_APP_ENV !== "undefined" ? ENVIRONMENTS[process.env.REACT_APP_ENV] : ENVIRONMENTS.local;

export default ENV;
