import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { toast } from "react-toastify";

import { DATE_FORMATS } from "components";
import ENV from "config/Env";
import { Appointment, Intervention, InterventionElement, PinModel } from "models";
import { GenericAttachmentData } from "modules/AppointmentDetails/components/Interventions/components";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";
import { getNameFromURL } from "util/common";

export type InterventionData = {
  title: string;
  description: string;
  price?: number;
  customer_ok: boolean;
  solution?: string;
  appointment_id?: number;
  elements?: InterventionElement[];
  question_result_id?: number | null;
  attachments?: GenericAttachmentData[];
  checkin_remark_id?: number | null;
  checkin_result_id?: number | null;
};

type UsePinVisibilityRequest = {
  intervention_id: number;
  pin_id: number;
  visible_mechanic: boolean;
};

type UseAttachmentInterventionRequest = {
  url: string;
  name: string;
  intervention_id: number;
  type: string;
};

type UseAttachmentInterventionData = {
  intervention_attachment_id: number;
  intervention_id: number;
};

type DeletePinRequest = {
  question_result_id?: number;
  intervention_id: number;
  note: string;
};

// TODO: cleanup this hook like for the others
export const useIntervention = (appointment_id: number) => {
  const queryClient = useQueryClient();
  const appointmentDetailsViewKey = AppointmentDetailsKeys.view(appointment_id);

  const handleNewIntervention = async (data: InterventionData) => {
    const res = await ApiInstance.post("/interventions/add", data, ENV.appointmentBaseURL);
    return res.data;
  };

  const handleDeleteIntervention = async (id: number) => {
    const res = await ApiInstance.post("/interventions/delete", { id }, ENV.appointmentBaseURL);
    return res.data;
  };

  const handlerNewPinItem = async (data: PinModel) => {
    const res = await ApiInstance.post("/pins/append", data, ENV.appointmentBaseURL);
    return res.data.id;
  };

  const handleRemovePinItem = async (data: DeletePinRequest) => {
    await ApiInstance.post("/pins/delete", data, ENV.appointmentBaseURL);
  };

  const addIntervention = useMutation({
    mutationFn: handleNewIntervention,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: appointmentDetailsViewKey }),
    onError: error => toast.error(error.message)
  });

  const deleteIntervention = useMutation({
    mutationFn: handleDeleteIntervention,
    onMutate: (interventionId: number) => {
      const appointmentSnapshot: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);

      if (appointmentSnapshot?.interventions) {
        const updatedInterventions = appointmentSnapshot.interventions.filter(intervention => intervention.id !== interventionId);
        const updatedAppointmentData = { ...appointmentSnapshot, interventions: updatedInterventions };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointmentData);
      }

      return { appointmentSnapshot };
    },
    onError: (error, _variables, context) => {
      toast.error(error.message);
      if (context?.appointmentSnapshot) {
        queryClient.setQueryData(appointmentDetailsViewKey, context.appointmentSnapshot);
      }
    }
  });

  const updateIntervention = useMutation({
    mutationFn: async (data: InterventionData) => {
      const res = await ApiInstance.post("/interventions/update", data, ENV.appointmentBaseURL);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentDetailsViewKey });
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const updateInterventionData = (data: Partial<Intervention>) => {
    const appointmentSnapshot: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
    if (appointmentSnapshot?.interventions) {
      const updatedInterventions = appointmentSnapshot.interventions.map(intervention => {
        if (intervention.id === data.id) {
          return { ...intervention, ...data };
        }
        return intervention;
      });
      const updatedAppointmentData = { ...appointmentSnapshot, interventions: updatedInterventions };
      queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointmentData);
    }
    return appointmentSnapshot;
  };

  const optimisticInterventionUpdate = useMutation({
    mutationFn: async (data: Partial<Intervention>) => {
      const res = await ApiInstance.post("/interventions/update", data, ENV.appointmentBaseURL);
      return res.data;
    },
    onMutate: updateInterventionData,
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context) {
        queryClient.setQueryData(appointmentDetailsViewKey, context);
      }
    }
  });

  const addPinItem = useMutation({
    mutationFn: handlerNewPinItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentDetailsViewKey });
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const deletePinItem = useMutation({
    mutationFn: handleRemovePinItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentDetailsViewKey });
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const updatePinVisibility = useMutation({
    mutationFn: async (data: UsePinVisibilityRequest) => {
      await ApiInstance.post("/pins/visibility", data, ENV.appointmentBaseURL);
      return data;
    },
    onMutate: (data: UsePinVisibilityRequest) => {
      const appointmentSnapshot: Appointment | undefined = queryClient.getQueryData(appointmentDetailsViewKey);
      if (appointmentSnapshot?.interventions) {
        const updatedInterventions = appointmentSnapshot.interventions.map(intervention => {
          if (intervention.id === data.intervention_id) {
            const updatedInterventionPinHistory = intervention.pin_history?.map(pin => {
              if (pin.id === data.pin_id) {
                return { ...pin, visible_mechanic: !pin.visible_mechanic };
              }
              return pin;
            });
            return { ...intervention, pin_history: updatedInterventionPinHistory };
          }
          return intervention;
        });
        const updatedAppointmentData = { ...appointmentSnapshot, interventions: updatedInterventions };
        queryClient.setQueryData(appointmentDetailsViewKey, updatedAppointmentData);
      }
      return { appointmentSnapshot };
    },
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context?.appointmentSnapshot) {
        queryClient.setQueryData(appointmentDetailsViewKey, context.appointmentSnapshot);
      }
    }
  });

  const uploadAttachment = async (appointment_id: number, file: File) => {
    const formData = new FormData();
    formData.append("appointment_id", String(appointment_id));
    formData.append("file", file);
    try {
      const res = await ApiInstance.post("/interventions/attachment/upload", formData, ENV.appointmentBaseURL);
      return res?.data?.url as string;
    } catch (e) {
      let errMessage = "";
      if (e instanceof Error) {
        errMessage = e.message;
      } else {
        errMessage = "Something went wrong, please contact to administrator.";
      }
      toast.error(errMessage);
    }
  };

  const addAttachmentToInterventionMutation = useMutation({
    mutationFn: async (data: UseAttachmentInterventionRequest) => {
      const res = await ApiInstance.post("/interventions/attachment/add", data, ENV.appointmentBaseURL);
      return res?.data?.intervention_attachment_id as number;
    },
    onSuccess: (intervention_attachment_id: number, data: UseAttachmentInterventionRequest) => {
      const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
      if (!appointment?.interventions) return;

      const newAttachment = {
        id: intervention_attachment_id,
        url: data.url,
        created_on: moment().utc().format(DATE_FORMATS.ISOFormat),
        name: getNameFromURL(data.url),
        type: data.type,
        intervention_id: data.intervention_id
      };

      const interventions = appointment.interventions.map(i => (i.id !== data.intervention_id ? i : { ...i, attachments: [...(i.attachments ?? []), newAttachment] }));
      queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, interventions });
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  const deleteInterventionAttachmentMutation = useMutation({
    mutationFn: async (data: UseAttachmentInterventionData) => {
      await ApiInstance.post("/interventions/attachment/delete", data, ENV.appointmentBaseURL);
      return data;
    },
    onSuccess: (data: UseAttachmentInterventionData) => {
      const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
      if (!appointment?.interventions) return;

      const interventions = appointment.interventions.map(intervention => {
        if (intervention.id === data.intervention_id)
          return { ...intervention, attachments: intervention.attachments?.filter(attachment => attachment.id !== data.intervention_attachment_id) };

        return intervention;
      });

      queryClient.setQueryData<Appointment>(appointmentDetailsViewKey, { ...appointment, interventions });
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  return {
    addIntervention,
    deleteIntervention,
    uploadAttachment,
    addAttachmentToInterventionMutation,
    deleteInterventionAttachmentMutation,
    updateIntervention,
    addPinItem,
    deletePinItem,
    optimisticInterventionUpdate,
    updatePinVisibility
  };
};
