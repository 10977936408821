import { Chart } from "chart.js";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { MileageChartProps } from "modules/CarDetails/components/MileageChart";
import { getColorByAverageKM } from "modules/CarDetails/utils";
import { ITranslation } from "util/interfaces";

export const useMileageChart = (mileageChartProps: MileageChartProps) => {
  const t = useTranslation().t as ITranslation;
  const chartRef = useRef<any>(null);
  const bubbleDataset = {
    type: "bubble",
    data: mileageChartProps.bubbleData,
    backgroundColor: mileageChartProps.colors.backgroundColors,
    hoverBackgroundColor: mileageChartProps.colors.backgroundColors,
    hoverRadius: 0,
    borderWidth: 2
  };

  const createCanvas = () => {
    const canvas = document.createElement("canvas");
    if (chartRef.current) {
      chartRef.current.innerHTML = "";
      chartRef.current.appendChild(canvas);
    }
    return canvas;
  };

  const calculateAverageKMDriven = (datapoint1: any, datapoint2: any) => {
    const timeDiff = datapoint2.x - datapoint1.x;
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const kmDiff = datapoint2.y - datapoint1.y;

    return Math.floor(kmDiff / diffDays);
  };

  const createLineDatasets = () => {
    const lineDatasets: any[] = [];
    const averageKMData: number[] = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < mileageChartProps.lineData.length - 1; i++) {
      const averageKM = calculateAverageKMDriven(mileageChartProps.lineData[i], mileageChartProps.lineData[i + 1]);
      const color = getColorByAverageKM(averageKM);

      averageKMData.push(averageKM);

      const dataset = {
        type: "line",
        fill: true,
        lineTension: 0.5,
        backgroundColor: `${color}0.4)`,
        borderColor: `${color}1)`,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: `${color}1)`,
        pointBackgroundColor: `${color}1)`,
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: `${color}1)`,
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [mileageChartProps.lineData[i], mileageChartProps.lineData[i + 1]],
        spanGaps: false
      };

      lineDatasets.push(dataset);
    }

    return { datasets: lineDatasets, averageKMData };
  };

  const setupChart = () => {
    const ctx = createCanvas().getContext("2d");
    const { datasets, averageKMData } = createLineDatasets();
    datasets.unshift(bubbleDataset);
    const translations = t;

    const dataCfg = {
      datasets
    };

    const legendCfg = {
      display: false
    };

    const xAxisCfg = {
      type: "time",
      unit: "month",
      gridLines: {
        color: "rgba(0,0,0,0)"
      },
      time: {
        displayFormats: {
          millisecond: "MMM, YYYY",
          second: "MMM, YYYY",
          minute: "MMM, YYYY",
          hour: "MMM, YYYY",
          day: "MMM, YYYY",
          week: "MMM, YYYY",
          month: "MMM, YYYY",
          quarter: "MMM, YYYY",
          year: "MMM, YYYY"
        },
        unitStepSize: 1
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 20
      }
    };

    const yAxisCfg = {
      type: "linear",
      ticks: {
        beginAtZero: false
      }
    };

    const scalesCfg = {
      x: xAxisCfg,
      y: yAxisCfg
    };

    const tooltipsCfg = {
      callbacks: {
        beforeLabel(tooltipItem: any) {
          if (tooltipItem.datasetIndex < 1 || tooltipItem.index !== 1) return null;

          const result = averageKMData[tooltipItem.datasetIndex - 1];
          if (!result) return null;

          return `${translations("previous_period")} - ${result} km / ${translations("day")} (${result * 30} km / ${translations("month")})`;
        },
        label() {
          return null;
        },
        afterLabel(tooltipItem: any) {
          if (tooltipItem.datasetIndex < 1 || tooltipItem.datasetIndex > averageKMData.length || tooltipItem.index !== 0) return null;

          const result = averageKMData[tooltipItem.datasetIndex - 1];
          if (!result) return null;

          return `${translations("next_period")} - ${result} km / ${translations("day")} (${result * 30} km / ${translations("month")})`;
        },
        title(tooltipItem: any) {
          return `${new Date(tooltipItem[0].raw.x).toString().substr(4, 12)} - ${tooltipItem[0].raw.y} km`;
        }
      },
      mode: "nearest",
      intersect: true
    };

    const hoverCfg = {
      animationDuration: 0
    };

    const optionsCfg = {
      plugins: {
        legend: legendCfg,
        tooltip: tooltipsCfg
      },
      scales: scalesCfg,
      hover: hoverCfg,
      aspectRatio: 3
    };

    const cfg = {
      type: "line",
      data: dataCfg,
      options: optionsCfg
    };

    // eslint-disable-next-line no-new
    new Chart(ctx as any, cfg as any);
  };

  useEffect(() => {
    setupChart();
  }, [mileageChartProps.lineData, mileageChartProps.bubbleData, mileageChartProps.colors]);

  return { chartRef };
};
