import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import ApiInstance from "util/Api";

type CreateResponse = {
  id: number;
  receptionist_key: string;
};

export const useDeskCheckin = (appointment_id: number) => {
  const createDeskCheckinMutation = useMutation({
    mutationFn: async () => {
      const response = await ApiInstance.post("/receptionists/create", { appointment_id }, ENV.deskCommBaseUrl);
      return response.data as CreateResponse;
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const cancelDeskCheckinMutation = useMutation({
    mutationFn: async (desk_communication_id: number | null) => {
      if (!desk_communication_id) throw new Error("No desk communication found");

      if (createDeskCheckinMutation.isPending) {
        createDeskCheckinMutation.reset();
        return;
      }

      const response = await ApiInstance.post("/receptionists/cancel", { desk_communication_id }, ENV.deskCommBaseUrl);
      return response;
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  return {
    cancelDeskCheckinMutation,
    createDeskCheckinMutation,

    isCreating: createDeskCheckinMutation.isPending,
    isCanceling: cancelDeskCheckinMutation.isPending
  };
};
