import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { useDealersLocations } from "hooks";
import { Car, DealerLocation, TYRE_POSITIONS, TYRE_SEASONS, TruckTyre } from "models";
import "modules/CarDetails/components/TruckTyres/TruckTyres.scss";
import { getTyreSeasonIcon } from "util/common";
import { ITranslation } from "util/interfaces";

const POSITIONS: Record<number, string> = {
  [TYRE_POSITIONS.TruckLeft]: "L",
  [TYRE_POSITIONS.TruckRight]: "R",
  [TYRE_POSITIONS.TruckExteriorLeft]: "EL",
  [TYRE_POSITIONS.TruckExteriorRight]: "ER",
  [TYRE_POSITIONS.TruckInteriorLeft]: "IL",
  [TYRE_POSITIONS.TruckInteriorRight]: "IR",
  [TYRE_POSITIONS.TruckSpare]: "S"
};

interface TruckTyresProps {
  data: Car | null;
}

type GroupedTruckTyre = {
  [key: string]: TruckTyre[];
};

export const TruckTyres: React.FC<TruckTyresProps> = (props: TruckTyresProps) => {
  const onTruckTyres: TruckTyre[] = props.data?.truck_tyres || [];
  const [truckTyres, setTruckTyres] = useState<GroupedTruckTyre>({});
  const { selectedLocation }: { selectedLocation: DealerLocation | undefined } = useDealersLocations();
  const t = useTranslation().t as ITranslation;

  const getTyreHeading = (data: TruckTyre[], key: string) => {
    const spareTyre = data.find((truckTyre: TruckTyre) => {
      return truckTyre.position === TYRE_POSITIONS.TruckSpare;
    });
    return spareTyre ? `${t("v8_spare_tyre").message || "Spare Tyre"} ${data.length}` : `${t("v8_axle").message || "Axle"} ${key}`;
  };

  const checkLayoutPosition = (position: number) => {
    return position === TYRE_POSITIONS.TruckRight || position === TYRE_POSITIONS.TruckExteriorRight || position === TYRE_POSITIONS.TruckInteriorLeft;
  };

  const getTyreColour = (profile: number) => {
    if (profile < Number(selectedLocation?.truck_tyre_n_threshold)) return "red";
    if (profile < Number(selectedLocation?.truck_tyre_a_threshold)) return "orange";
    return "green";
  };

  useEffect(() => {
    const groupedTruckTyreData = onTruckTyres.reduce((truckTyre: GroupedTruckTyre, obj: TruckTyre) => {
      if (!obj.axle) return truckTyre;

      const tyresData = [...(truckTyre[obj.axle] || []), obj];
      if (tyresData.length > 3) {
        const exteriorRightTyrePos = tyresData.map(e => e.position).indexOf(TYRE_POSITIONS.TruckExteriorRight);
        tyresData.push(tyresData.splice(exteriorRightTyrePos, 1)[0]);
      }

      return { ...truckTyre, [obj.axle]: tyresData };
    }, {});
    setTruckTyres(groupedTruckTyreData);
  }, []);

  const renderTruckTyre = (truckTyre: TruckTyre) => {
    const { tyre, position, axle, profile } = truckTyre;
    const tyreSeasonIcon = getTyreSeasonIcon(Number(tyre?.season));
    const isRightLayout = checkLayoutPosition(position);

    return (
      <div key={`${axle}-${position}`}>
        <div className={`flex-row small-gap tyre-details-font ${isRightLayout ? "end row-reverse" : "start"}`}>
          <div className={`tyre-indicator ${getTyreColour(profile)}`}>
            <p>{POSITIONS[position]}</p>
          </div>
          <p>{`${profile} mm`}</p>
        </div>
        <div className={`tyre-details tyre-details-font ${isRightLayout ? "right" : ""}`}>
          <p>
            {tyreSeasonIcon && isRightLayout && (
              <span>
                <Icon className={tyreSeasonIcon} />
              </span>
            )}
            {tyre ? tyre.manufacturer : ""}
            {tyreSeasonIcon && !isRightLayout && (
              <span>
                <Icon className={tyreSeasonIcon} />
              </span>
            )}
          </p>
          <p>{tyre ? tyre.description : ""}</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {onTruckTyres && onTruckTyres.length > 0 && (
        <div className="Tyres">
          <h4>{onTruckTyres?.[0].season === TYRE_SEASONS.NotAvailable ? t("v8_on_truck").message || "On Truck" : t("v8_on_vehicle").message || "On Vehicle"}</h4>
          {Object.keys(truckTyres).map((key, index) => {
            return (
              <div className="flex-row" key={index}>
                <div className="tyre-container">
                  <div className="header">
                    <h4>{getTyreHeading(truckTyres[key as keyof typeof truckTyres], key)}</h4>
                  </div>
                  <div className="flex-row padding">{truckTyres[key as keyof typeof truckTyres].map((truckTyre: TruckTyre) => renderTruckTyre(truckTyre))}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
