import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import _startCase from "lodash/startCase";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";

import { MODAL_TYPE, QuestionResultData, ReactTable } from "components";
import { DealerLocation, LeadsNotSnoozed, SnoozeItem } from "models";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { CustomerCard, QuestionStatusIcon, SnoozeButtonIcon, TableDatePicker, TableDropdownSelector, TableQuestionStatusButton } from "modules/LeadsDashboard/components";
import "modules/LeadsDashboard/Leads/NotSnoozedLeads/components/NotSnoozedTable/NotSnoozedTable.scss";
import { useNotSnoozedLeadsTableFilterHandlers } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { getFormattedDate, getLocationName, groupWOResults } from "modules/LeadsDashboard/util";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

type NotSnoozedLeadsTableProps = {
  locations: DealerLocation[];
  dateFrom: Date;
  dateTo: Date;
  setModalData: Dispatch<SetStateAction<InterventionData | QuestionResultData | null>>;
  currentlySnoozedItems: number[];
};

const PAGE_SIZE = 100;

export const NotSnoozedLeadsTable = ({ locations, dateFrom, dateTo, setModalData, currentlySnoozedItems }: NotSnoozedLeadsTableProps) => {
  const t = useTranslation().t as ITranslation;
  const {
    handleOnLeaseSelect,
    handlePaginationSelect,
    handleOnQuestionSelect,
    handleDateRangeChange,
    handleQuestionResultStatusSelect,
    currentPage,
    selectedQuestions,
    leaseCompanyOptions,
    selectedQuestionStatuses,
    selectedLeaseCompanyOption,
    questionResultStatusOptions,
    interventionQuestionOptions
  } = useNotSnoozedLeadsTableFilterHandlers({ pageSize: PAGE_SIZE });

  const queryClient = useQueryClient();
  const notSnoozedLeads: LeadsNotSnoozed | undefined = queryClient.getQueryData([LeadsQueryKeys.listNotSnoozed]);
  const groupedNotSnoozedLeadsData = groupWOResults(notSnoozedLeads?.items || []);

  const columnHelper = createColumnHelper<SnoozeItem>();

  const columns = [
    columnHelper.accessor((row: SnoozeItem) => row.appointment?.time_car_app, {
      id: "appointment_date",
      header: () => (
        <div className="appointment-table-wrapper default-cursor NotSnoozedTable" onClick={e => e.stopPropagation()}>
          <span className="table-header-text no-wrap-text">{_startCase(t("v8_appointment_date").message || "Appointment Date")} </span>
          <TableDatePicker dateStart={dateFrom} dateEnd={dateTo} onChange={handleDateRangeChange} />
        </div>
      ),
      size: 180,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{getFormattedDate(renderValue())}</span>
    }),

    columnHelper.accessor((row: SnoozeItem) => row.appointment, {
      id: "wo",
      header: () => <span className="table-header-text no-wrap-text default-cursor">WO #</span>,
      size: 70,
      cell: ({ getValue }) => {
        const appointment = getValue();
        if (appointment?.wo_nr === "0") return "";

        return (
          <p className="ellipsis ">
            <Link to={`${PATHS.APPOINTMENTS}/${appointment?.id}`}>{appointment?.wo_nr}</Link>
          </p>
        );
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row.appointment, {
      id: "reg_number",
      header: () => (
        <div className="appointment-table-wrapper default-cursor">
          <span className="ellipsis ellipsis-wo-nr table-header-text no-wrap-text"> {t("v8_reg_number").message || "Reg"} #</span>
          <TableDropdownSelector options={leaseCompanyOptions} selected={[selectedLeaseCompanyOption]} handleOnSelect={handleOnLeaseSelect} />
        </div>
      ),
      size: 70,
      cell: ({ renderValue }) => {
        const appointment = renderValue();
        if (appointment?.reg_number === "0") return "";

        return (
          <p className="ellipsis ellipsis-wo-nr">
            <Link to={`${PATHS.CAR_DETAIL}/${appointment?.car_id}`}>
              {appointment?.reg_number}
              {appointment?.is_lease_company && <Icon name="building" />}
            </Link>
          </p>
        );
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row.dealer_location_id, {
      id: "location_name",
      header: () => <span className="no-wrap-text table-header-text default-cursor">{t("v8_location").message || "Location"}</span>,
      size: 120,
      cell: ({ renderValue }) => <span className="ellipsis ellipsis-wo-nr">{getLocationName(renderValue(), locations)}</span>
    }),

    columnHelper.accessor((row: SnoozeItem) => row, {
      id: "intervention_question_result",
      header: () => (
        <div className="appointment-table-wrapper default-cursor">
          <span className="table-header-text no-wrap-text"> {t("v8_intervention_question_result").message || "Intervention/Question Result"}</span>
          <TableDropdownSelector
            multiple={true}
            withSearch={true}
            selected={selectedQuestions}
            options={interventionQuestionOptions}
            handleOnSelect={handleOnQuestionSelect}
          />
        </div>
      ),
      size: 210,
      cell: ({ getValue }) => {
        const { question_result, intervention } = getValue();
        return <span>{question_result ? question_result.title : intervention.title}</span>;
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row, {
      id: "note",
      header: () => <span className="no-wrap-text table-header-text default-cursor"> {t("v8_note").message || "Note"} </span>,
      size: 350,
      cell: ({ getValue }) => {
        const { question_result, intervention, note } = getValue();
        const content = question_result?.raw || intervention?.description || note || "";
        return <Popup hoverable content={content} trigger={<p className="ellipsis ellipsis-wo-nr">{content}</p>} />;
      }
    }),

    columnHelper.accessor((row: SnoozeItem) => row, {
      id: "status",
      header: () => (
        <div className="table-action-buttons-container default-cursor">
          <TableDropdownSelector
            multiple={true}
            selected={selectedQuestionStatuses}
            options={questionResultStatusOptions}
            handleOnSelect={handleQuestionResultStatusSelect}
            trigger={<TableQuestionStatusButton selectedQuestionStatuses={selectedQuestionStatuses} />}
            scrolling={false}
          />
          <div className="table-icon-placeholder-div" />
          <div className="table-icon-placeholder-div" />
        </div>
      ),
      size: 30,
      cell: ({ getValue }) => {
        const { appointment, question_result, question_result_id, snooze_type_id, snooze_status_id, snooze_department_id, snooze_date, note, appointment_id } =
          getValue();

        const snoozeModalData: QuestionResultData = {
          modalType: MODAL_TYPE.Snooze,
          title: "",
          description: "",
          solution: "",
          question_result_id: question_result_id,
          id: undefined,
          images: [],
          appointment_id: appointment_id,
          snoozed: question_result?.snoozed,
          snooze_history: question_result?.snooze_history,
          snooze_type_id: snooze_type_id,
          snooze_status_id: snooze_status_id,
          snooze_department_id: snooze_department_id,
          snooze_date: snooze_date,
          note,
          attachments: [],
          checkin_remark_id: question_result?.checkin_remark_id || undefined
        };

        return (
          <div className="table-action-buttons-container">
            <QuestionStatusIcon status={question_result?.status || null} />
            {appointment?.wo_nr !== "0" ? (
              <CustomerCard
                contractor={appointment?.customer_contract || null}
                owner={appointment?.customer_owner || null}
                driver={appointment?.customer_driver || null}
              />
            ) : (
              <div className="table-icon-placeholder-div" />
            )}
            <SnoozeButtonIcon
              snoozedStatus={currentlySnoozedItems.includes(question_result_id) ? "snoozed" : "notSnoozed"}
              handleClick={() => setModalData(snoozeModalData)}
            />
          </div>
        );
      }
    })
  ];

  return (
    <div className="table-container">
      <ReactTable
        columns={columns}
        data={groupedNotSnoozedLeadsData || []}
        pageCount={notSnoozedLeads?.nb_pages}
        pageSize={PAGE_SIZE}
        totalItems={notSnoozedLeads?.nb_items}
        pagination={notSnoozedLeads?.nb_pages && notSnoozedLeads.nb_pages > 1 ? { pageIndex: currentPage - 1, pageSize: PAGE_SIZE } : undefined}
        onPaginationChange={handlePaginationSelect}
      />
    </div>
  );
};
