import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect } from "react";

import { useDealersLocations } from "hooks";
import { QUESTION_STATUSES } from "models";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";

export const useDefaultNotSnoozedFilters = () => {
  const queryClient = useQueryClient();
  const { selectedLocation } = useDealersLocations();

  const defaultNotSnoozedFilters: LeadsNotSnoozedFilters = {
    page: 1,
    dealer_ids: [],
    search_term: "",
    is_lease_company: null,
    date_from: moment().subtract(30, "days").toDate(),
    date_to: moment().toDate(),
    location_ids: selectedLocation?.id ? [selectedLocation.id] : [],
    customer_approved: false,
    interventions: false,
    statuses: [QUESTION_STATUSES.OKWithRemarks, QUESTION_STATUSES.Advice, QUESTION_STATUSES.Necessary],
    questions: null,
    tags: null
  };

  useEffect(() => {
    const notSnoozedFilters = queryClient.getQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters);
    if (!selectedLocation || (notSnoozedFilters?.location_ids && notSnoozedFilters?.location_ids.length > 0)) return;

    queryClient.setQueryData(LeadsQueryKeys.notSnoozedFilters, { ...notSnoozedFilters, location_ids: selectedLocation ? [selectedLocation.id] : [] });
  }, [selectedLocation]);

  return { defaultNotSnoozedFilters };
};

export const useGetNotSnoozedFilters = () => {
  const { defaultNotSnoozedFilters } = useDefaultNotSnoozedFilters();
  const { data: notSnoozedFilters } = useQuery<LeadsNotSnoozedFilters>({ queryKey: LeadsQueryKeys.notSnoozedFilters, initialData: defaultNotSnoozedFilters });
  return { notSnoozedFilters };
};
