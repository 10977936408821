import { saveAs } from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Dropdown, Form, Grid, Icon, Input, Modal, TextArea } from "semantic-ui-react";

import { DATE_FORMATS } from "components";
import { useDealersLocations } from "hooks";
import { Appointment, CHANNELS, CommunicationEvent, CommunicationReceiver, Customer, SMS_GATEWAYS } from "models";
import { CustomerCommunicationInterventions } from "modules/AppointmentDetails/components/CustomerCommunicationModal/components";
import "modules/AppointmentDetails/components/CustomerCommunicationModal/CustomerCommunicationModal.scss";
import { useCustomerCommunication } from "modules/AppointmentDetails/hooks";
import { validateEmail } from "util/common";
import { ITranslation } from "util/interfaces";

export type Receivers = {
  destination: string;
  channel: CHANNELS;
};

type CustomerCommunicationModalProps = {
  appointment: Appointment;
  onClose?: () => void;
  send: CUSTOMER_COMMUNICATIONS;
};

export enum CUSTOMER_COMMUNICATIONS {
  DiagnoseOverview = 1,
  RepairOverview
}

type FormState = {
  manualEmailSelected: boolean;
  selectedCustomerID: number;
  selectedEmail: string;
  selectedPhone: string;
  manuallyEnteredEmail: string;
  parsedCustomerPhone: string;
  parsedCustomerEmail: string;
  invalidCustomer: boolean;
  invalidPhone: boolean;
  invalidEmail: boolean;
  invalidInterventions: boolean;
  invalidManualEmail: boolean;
  note: string;
};

type DropdownOptions = {
  key?: string;
  text: string;
  value: string | number;
  disabled?: boolean;
  description?: string;
};

const locale = localStorage.getItem("locale");

export const CustomerCommunicationModal = ({ appointment, send, onClose }: CustomerCommunicationModalProps) => {
  const t = useTranslation().t as ITranslation;
  const { selectedLocation: location, selectedDealer: dealer } = useDealersLocations();
  const { saveCustomerCommunicationMutation, downloadPdfPreview } = useCustomerCommunication();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [showPreviewURL, setShowPreviewURL] = useState<string>("");
  const [emailOptions, setEmailOptions] = useState<DropdownOptions[]>([]);
  const [phoneOptions, setPhoneOptions] = useState<DropdownOptions[]>([]);

  const customerCommunicationLastEvent = appointment.customer_communication?.events
    ?.filter((c: CommunicationEvent) => Array.isArray(c.receivers) && c.receivers.length > 0)
    .sort((ev1: CommunicationEvent, ev2: CommunicationEvent) => {
      if (ev1.created_on > ev2.created_on) return -1;
      if (ev1.created_on < ev2.created_on) return 1;
      return 0;
    })
    .find((item: CommunicationEvent) => item.receivers?.some((receiver: CommunicationReceiver) => receiver.destination));

  const lastEmailReceiver = customerCommunicationLastEvent?.receivers?.find((receiver: CommunicationReceiver) => receiver.channel === CHANNELS.Email);
  const lastPhoneReceiver = customerCommunicationLastEvent?.receivers?.find((receiver: CommunicationReceiver) => receiver.channel === CHANNELS.Sms);

  const [formState, setFormState] = useState<FormState>({
    manualEmailSelected: false,
    selectedCustomerID: appointment.customer_communication?.customer_id || 0,
    selectedEmail: ";-2",
    selectedPhone: ";-2",
    manuallyEnteredEmail: "",
    parsedCustomerPhone: lastPhoneReceiver?.destination || "",
    parsedCustomerEmail: lastEmailReceiver?.destination || "",
    note: appointment.customer_communication?.note || "",
    invalidCustomer: false,
    invalidPhone: false,
    invalidEmail: false,
    invalidInterventions: false,
    invalidManualEmail: false
  });

  const shouldRenderPhoneNumberField = !appointment.internal && dealer?.sms_gateway_id && dealer.sms_gateway_id > SMS_GATEWAYS.Disabled;

  const getBlockCommunicationSuffix = (customer: Customer) =>
    customer.block_communication ? ` - ${t("v8_communication_blocked").message || "Communication blocked"}` : "";

  const getIsInternalCommunicationSuffix = (customer: Customer) => (customer.is_internal ? ` - ${t("v8_internal").message || "Internal"}` : "");

  const getCustomerOptions = (appointment: Appointment) => {
    const customerOptions: DropdownOptions[] = [];
    if (appointment.customer_driver?.dms_nr) {
      customerOptions.push({
        key: `${appointment.customer_driver.id}`,
        text: `${appointment.customer_driver.firstname} ${appointment.customer_driver.surname}`,
        value: appointment.customer_driver.id,
        disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
        description:
          (t("v8_driver").message || "Driver") + getBlockCommunicationSuffix(appointment.customer_driver) + getIsInternalCommunicationSuffix(appointment.customer_driver)
      });
    }
    if (appointment.customer_owner?.dms_nr) {
      customerOptions.push({
        key: `${appointment.customer_owner.id}`,
        text: `${appointment.customer_owner.firstname} ${appointment.customer_owner.surname}`,
        value: appointment.customer_owner.id,
        disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
        description:
          (t("v8_owner").message || "Owner") + getBlockCommunicationSuffix(appointment.customer_owner) + getIsInternalCommunicationSuffix(appointment.customer_owner)
      });
    }

    if (appointment.customer_contract?.dms_nr) {
      customerOptions.push({
        key: `${appointment.customer_contract.id}`,
        text: `${appointment.customer_contract.firstname} ${appointment.customer_contract.surname}`,
        value: appointment.customer_contract.id,
        disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
        description:
          (t("v8_contractor").message || "Contractor") +
          getBlockCommunicationSuffix(appointment.customer_contract) +
          getIsInternalCommunicationSuffix(appointment.customer_contract)
      });
    }

    return customerOptions;
  };

  const getDefaultValueForEmailAndPhone = (customerId: number, customerEmailOrPhone: string, defaultValue: string, isEmail: boolean) => {
    const lastReceiver = isEmail ? lastEmailReceiver : lastPhoneReceiver;
    if (formState.selectedCustomerID === customerId && lastReceiver?.destination === customerEmailOrPhone) return `${customerEmailOrPhone};${customerId}`;
    return defaultValue;
  };

  const setEmailOptionsAndValue = (appointment: Appointment) => {
    const emailOptions: DropdownOptions[] = [];
    let emailValue: string = "";
    let manualEmailSelected: boolean = false;
    let manuallyEnteredEmail: string = "";

    emailOptions.push({
      text: t("v8_do_not_send_by_email").message || "Do not send by email",
      value: ";-2"
    });
    if (!lastEmailReceiver?.destination) {
      emailValue = ";-2";
    }

    if (appointment.customer_driver?.email_business) {
      emailOptions.push({
        text: appointment.customer_driver.email_business,
        value: `${appointment.customer_driver.email_business};${appointment.customer_driver.id}`,
        disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
        description:
          (t("v8_driver_business").message || "Driver business") +
          getBlockCommunicationSuffix(appointment.customer_driver) +
          getIsInternalCommunicationSuffix(appointment.customer_driver)
      });
      emailValue = getDefaultValueForEmailAndPhone(appointment.customer_driver.id, appointment.customer_driver.email_business, emailValue, true);
    }

    if (appointment.customer_driver?.email_private) {
      emailOptions.push({
        text: appointment.customer_driver.email_private,
        value: `${appointment.customer_driver.email_private};${appointment.customer_driver.id}`,
        disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
        description:
          (t("v8_driver_private").message || "Driver private") +
          getBlockCommunicationSuffix(appointment.customer_driver) +
          getIsInternalCommunicationSuffix(appointment.customer_driver)
      });
      emailValue = getDefaultValueForEmailAndPhone(appointment.customer_driver.id, appointment.customer_driver.email_private, emailValue, true);
    }

    if (appointment.customer_contract?.email_business) {
      emailOptions.push({
        text: appointment.customer_contract.email_business,
        value: `${appointment.customer_contract.email_business};${appointment.customer_contract.id}`,
        disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
        description:
          (t("v8_contract_business").message || "Contract business") +
          getBlockCommunicationSuffix(appointment.customer_contract) +
          getIsInternalCommunicationSuffix(appointment.customer_contract)
      });
      emailValue = getDefaultValueForEmailAndPhone(appointment.customer_contract.id, appointment.customer_contract.email_business, emailValue, true);
    }

    if (appointment.customer_contract?.email_private) {
      emailOptions.push({
        text: appointment.customer_contract.email_private,
        value: `${appointment.customer_contract.email_private};${appointment.customer_contract.id}`,
        disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
        description:
          (t("v8_contract_private").message || "Contract private") +
          getBlockCommunicationSuffix(appointment.customer_contract) +
          getIsInternalCommunicationSuffix(appointment.customer_contract)
      });
      emailValue = getDefaultValueForEmailAndPhone(appointment.customer_contract.id, appointment.customer_contract.email_private, emailValue, true);
    }

    if (appointment.customer_owner?.email_business) {
      emailOptions.push({
        text: appointment.customer_owner.email_business,
        value: `${appointment.customer_owner.email_business};${appointment.customer_owner.id}`,
        disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
        description:
          (t("v8_owner_business").message || "Owner business") +
          getBlockCommunicationSuffix(appointment.customer_owner) +
          getIsInternalCommunicationSuffix(appointment.customer_owner)
      });
      emailValue = getDefaultValueForEmailAndPhone(appointment.customer_owner.id, appointment.customer_owner.email_business, emailValue, true);
    }

    if (appointment.customer_owner?.email_private) {
      emailOptions.push({
        text: appointment.customer_owner.email_private,
        value: `${appointment.customer_owner.email_private};${appointment.customer_owner.id}`,
        disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
        description:
          (t("v8_owner_private").message || "Owner private") +
          getBlockCommunicationSuffix(appointment.customer_owner) +
          getIsInternalCommunicationSuffix(appointment.customer_owner)
      });
      emailValue = getDefaultValueForEmailAndPhone(appointment.customer_owner.id, appointment.customer_owner.email_private, emailValue, true);
    }

    emailOptions.push({
      text: t("v8_manually_enter_email").message || "Manually enter email",
      value: ";-1"
    });
    if (!emailValue) {
      emailValue = ";-1";
      manualEmailSelected = true;
      manuallyEnteredEmail = lastEmailReceiver?.destination || "";
    }

    setEmailOptions(emailOptions);
    setFormState(pre => ({ ...pre, selectedEmail: emailValue, manualEmailSelected, manuallyEnteredEmail }));
  };

  const setPhoneOptionsAndValue = (appointment: Appointment) => {
    const phoneOptions: DropdownOptions[] = [];
    let phoneValue: string = "";

    phoneOptions.push({
      text: t("v8_do_not_send_by_SMS").message || "Do not send by SMS",
      value: ";-2"
    });

    if (appointment.customer_driver?.id) {
      if (appointment.customer_driver.tel_mobile_business) {
        phoneOptions.push({
          text: appointment.customer_driver.tel_mobile_business,
          value: `${appointment.customer_driver.tel_mobile_business};${appointment.customer_driver.id}`,
          disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
          description:
            (t("v8_driver_business").message || "Driver business") +
            getBlockCommunicationSuffix(appointment.customer_driver) +
            getIsInternalCommunicationSuffix(appointment.customer_driver)
        });
        phoneValue = getDefaultValueForEmailAndPhone(appointment.customer_driver.id, appointment.customer_driver.tel_mobile_business, phoneValue, false);
      }

      if (appointment.customer_driver.tel_mobile_private) {
        phoneOptions.push({
          text: appointment.customer_driver.tel_mobile_private,
          value: `${appointment.customer_driver.tel_mobile_private};${appointment.customer_driver.id}`,
          disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
          description:
            (t("v8_driver_private").message || "Driver private") +
            getBlockCommunicationSuffix(appointment.customer_driver) +
            getIsInternalCommunicationSuffix(appointment.customer_driver)
        });
        phoneValue = getDefaultValueForEmailAndPhone(appointment.customer_driver.id, appointment.customer_driver.tel_mobile_private, phoneValue, false);
      }
    }

    if (appointment.customer_contract?.id) {
      if (appointment.customer_contract.tel_mobile_business) {
        phoneOptions.push({
          text: appointment.customer_contract.tel_mobile_business,
          value: `${appointment.customer_contract.tel_mobile_business};${appointment.customer_contract.id}`,
          disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
          description:
            (t("v8_contract_business").message || "Contract business") +
            getBlockCommunicationSuffix(appointment.customer_contract) +
            getIsInternalCommunicationSuffix(appointment.customer_contract)
        });
        phoneValue = getDefaultValueForEmailAndPhone(appointment.customer_contract.id, appointment.customer_contract.tel_mobile_business, phoneValue, false);
      }

      if (appointment.customer_contract.tel_mobile_private) {
        phoneOptions.push({
          text: appointment.customer_contract.tel_mobile_private,
          value: `${appointment.customer_contract.tel_mobile_private};${appointment.customer_contract.id}`,
          disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
          description:
            (t("v8_contract_private").message || "Contract private") +
            getBlockCommunicationSuffix(appointment.customer_contract) +
            getIsInternalCommunicationSuffix(appointment.customer_contract)
        });
        phoneValue = getDefaultValueForEmailAndPhone(appointment.customer_contract.id, appointment.customer_contract.tel_mobile_private, phoneValue, false);
      }
    }

    if (appointment.customer_owner?.id) {
      if (appointment.customer_owner.tel_mobile_business) {
        phoneOptions.push({
          text: appointment.customer_owner.tel_mobile_business,
          value: `${appointment.customer_owner.tel_mobile_business};${appointment.customer_owner.id}`,
          disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
          description:
            (t("v8_owner_business").message || "Owner business") +
            getBlockCommunicationSuffix(appointment.customer_owner) +
            getIsInternalCommunicationSuffix(appointment.customer_owner)
        });
        phoneValue = getDefaultValueForEmailAndPhone(appointment.customer_owner.id, appointment.customer_owner.tel_mobile_business, phoneValue, false);
      }

      if (appointment.customer_owner.tel_mobile_private) {
        phoneOptions.push({
          text: appointment.customer_owner.tel_mobile_private,
          value: `${appointment.customer_owner.tel_mobile_private};${appointment.customer_owner.id}`,
          disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
          description:
            (t("v8_owner_private").message || "Owner private") +
            getBlockCommunicationSuffix(appointment.customer_owner) +
            getIsInternalCommunicationSuffix(appointment.customer_owner)
        });
        phoneValue = getDefaultValueForEmailAndPhone(appointment.customer_owner.id, appointment.customer_owner.tel_mobile_private, phoneValue, false);
      }
    }

    if (!phoneValue) phoneValue = ";-2";

    setPhoneOptions(phoneOptions);
    setFormState(pre => ({ ...pre, selectedPhone: phoneValue }));
  };

  const handleManualEmailChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsInvalidEmail(false);
    setFormState(pre => ({ ...pre, manuallyEnteredEmail: evt.target.value }));
  };

  const handleNoteChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState(pre => ({ ...pre, note: evt.target.value }));
  };

  const handleCustomerDropdownChange = (value: number) => {
    setFormState(pre => {
      return {
        ...pre,
        selectedCustomerID: value
      };
    });
  };

  const handleEmailDropdownChange = (value: string) => {
    if (value === ";-1") {
      setFormState(pre => {
        return {
          ...pre,
          manualEmailSelected: true,
          parsedCustomerEmail: "",
          parsedCustomerPhone: "",
          selectedEmail: value,
          selectedCustomerID: 0,
          selectedPhone: ";-2"
        };
      });
      return;
    }

    const splittedValue = value.split(";");
    const customerEmail: string = splittedValue[0];
    let customerID: number = Number(splittedValue[1]);
    let selectedCustomerPhone = formState.parsedCustomerPhone;
    let selectedPhone = formState.selectedPhone;

    if (formState.parsedCustomerEmail === customerEmail && formState.selectedCustomerID === customerID) return;

    if (customerID > 0 && formState.selectedCustomerID !== customerID) {
      selectedCustomerPhone = "";
      selectedPhone = ";2";
    }

    if (!customerID && selectedCustomerPhone) customerID = formState.selectedCustomerID;

    setFormState(pre => {
      return {
        ...pre,
        manualEmailSelected: false,
        parsedCustomerEmail: customerEmail,
        parsedCustomerPhone: selectedCustomerPhone,
        selectedCustomerID: Number(customerID),
        selectedEmail: `${customerEmail};${customerID}`,
        selectedPhone: selectedPhone
      };
    });
  };

  const handlePhoneDropdownChange = (value: string) => {
    const splittedValue = value.split(";");
    const customerPhone: string = splittedValue[0];
    let customerID: number = Number(splittedValue[1]);

    let selectedCustomerEmail = formState.parsedCustomerEmail;
    let manualEmailSelected = formState.manualEmailSelected;

    if (formState.parsedCustomerPhone === customerPhone && formState.selectedCustomerID === customerID) return;
    if (customerID > 0 && formState.selectedCustomerID !== customerID) {
      selectedCustomerEmail = "";
      manualEmailSelected = false;
    }
    if (!customerID && selectedCustomerEmail) customerID = formState.selectedCustomerID;

    setFormState(pre => {
      return {
        ...pre,
        manualEmailSelected: manualEmailSelected,
        parsedCustomerEmail: selectedCustomerEmail,
        parsedCustomerPhone: customerPhone,
        selectedCustomerID: Number(customerID),
        selectedPhone: `${customerPhone};${customerID}`
      };
    });
  };

  const renderFields = () => {
    return (
      <Form>
        <Grid columns={shouldRenderPhoneNumberField ? 2 : undefined} className="inline-field">
          {location?.third_party_send_communications && (
            <Grid.Column className={`email-field three`}>
              <Form.Field required>
                <label>{t("v8_select_customer").message || "Select customer"}</label>
                <Dropdown
                  options={getCustomerOptions(appointment)}
                  fluid
                  search
                  selection
                  className={formState.invalidCustomer ? "error" : ""}
                  value={formState.selectedCustomerID}
                  name="selectCustomer"
                  placeholder={t("v8_select_customer").message || "Select customer"}
                  onChange={(_, { value }) => handleCustomerDropdownChange(value as number)}
                />
              </Form.Field>
            </Grid.Column>
          )}
          {!location?.third_party_send_communications && (
            <>
              <Grid.Column className="email-field three">
                <Form.Field required>
                  <label style={{ width: !shouldRenderPhoneNumberField ? (!formState.manualEmailSelected ? "7%" : "7.33%") : "16%" }}>
                    {t("v8_select_email").message || "Select email"}
                  </label>
                  <Dropdown
                    fluid
                    search
                    name="selectEmail"
                    selection
                    value={formState.selectedEmail}
                    className={formState.invalidEmail ? "error" : ""}
                    placeholder={t("v8_select_email").message || "Select email"}
                    options={emailOptions}
                    onChange={(_, { value }) => handleEmailDropdownChange(value as string)}
                  />
                </Form.Field>
              </Grid.Column>
              {shouldRenderPhoneNumberField && (
                <Grid.Column className="email-field two">
                  <Form.Field>
                    <label>{t("v8_select_phone_number").message || "Select phone number"}</label>
                    <Dropdown
                      fluid
                      name="selectPhone"
                      value={formState.selectedPhone}
                      selection
                      disabled={formState.manualEmailSelected}
                      className={formState.invalidPhone ? "error" : ""}
                      options={phoneOptions}
                      placeholder={t("v8_do_not_send_by_sms").message || "Do not send by SMS"}
                      onChange={(_, { value }) => handlePhoneDropdownChange(value as string)}
                    />
                  </Form.Field>
                </Grid.Column>
              )}
            </>
          )}
        </Grid>
        {formState.manualEmailSelected && (
          <div className="mt-15 email-field note-field">
            <Form.Field>
              <label>{t("v8_email").message || "Email"}</label>
              <Input
                name="email"
                value={formState.manuallyEnteredEmail}
                onChange={handleManualEmailChange}
                required
                type="email"
                placeholder={t("v8_enter_email").message || "Enter email"}
                className={formState.invalidManualEmail ? "error" : ""}
              />
            </Form.Field>
            {isInvalidEmail && <span className="error">{t("v8_invalid_email").message || "Please enter a valid email address"}</span>}
          </div>
        )}
        <div className="mt-15 email-field note-field">
          <Form.Field>
            <label>{t("v8_note").message || "Note"}</label>
            <TextArea
              rows={2}
              name="note"
              value={formState.note}
              onChange={handleNoteChange}
              required
              type="email"
              placeholder={t("v8_write_note").message || "Write note"}
            />
          </Form.Field>
        </div>
      </Form>
    );
  };

  const renderInterventions = () => {
    return <CustomerCommunicationInterventions appointment={appointment} invalidInterventions={formState.invalidInterventions} />;
  };

  const renderContent = () => {
    if (showPreviewURL) return <iframe id="customer-communication-preview-iframe" src={showPreviewURL} style={{ width: "100%", minHeight: "600px", border: "0px" }} />;
    return (
      <>
        {renderFields()}
        <div className="CustomerCommunicationModal-divider" />
        {renderInterventions()}
      </>
    );
  };

  const getSaveButtonName = () => {
    if (showPreviewURL) return t("v8_send_repair_overview").message || "Send repair overview";
    if (send === CUSTOMER_COMMUNICATIONS.DiagnoseOverview) return t("v8_send_diagnose_overview").message || "Send diagnose overview";
    return t("v8_repair_overview_preview").message || "Repair overview preview";
  };

  const canSendCommunication = () => {
    const visibleToCustomerInterventions = appointment.interventions?.find(intervention => intervention.visible_to_customer);

    if (formState.manualEmailSelected) {
      if (!formState.manuallyEnteredEmail || !validateEmail(formState.manuallyEnteredEmail)) {
        setFormState(pre => ({ ...pre, invalidManualEmail: true }));
        return false;
      }
    }

    if (location?.third_party_send_communications && !formState.selectedCustomerID) {
      setFormState(pre => ({ ...pre, invalidCustomer: true }));
      return false;
    } else if (!location?.third_party_send_communications && formState.selectedEmail === ";-2" && formState.selectedPhone === ";-2") {
      setFormState(pre => ({ ...pre, invalidPhone: true, invalidEmail: true }));
      return false;
    } else if (!visibleToCustomerInterventions) {
      setFormState(pre => ({ ...pre, invalidInterventions: true }));
      return false;
    }
    setFormState(pre => ({ ...pre, invalidPhone: false, invalidEmail: false, invalidCustomer: false, invalidInterventions: false, invalidManualEmail: false }));
    return true;
  };

  const getReceivers = () => {
    const receivers: Receivers[] = [];
    if (formState.parsedCustomerEmail || formState.manualEmailSelected) {
      receivers.push({
        destination: formState.manualEmailSelected ? formState.manuallyEnteredEmail : formState.parsedCustomerEmail,
        channel: CHANNELS.Email
      });
    }
    if (formState.parsedCustomerPhone) {
      receivers.push({
        destination: formState.parsedCustomerPhone,
        channel: CHANNELS.Sms
      });
    }
    if (location?.third_party_send_communications && formState.selectedCustomerID > 0) {
      const customerType =
        [
          { id: appointment.customer_driver_id, type: "driver" },
          { id: appointment.customer_owner_id, type: "owner" }
        ].find(item => item.id === formState.selectedCustomerID)?.type || "contractor";
      receivers.push({
        destination: customerType,
        channel: CHANNELS.Webhook
      });
      receivers.push({
        destination: customerType,
        channel: CHANNELS.Webhook
      });
    }
    return receivers;
  };

  const handleSaveCommunication = async () => {
    if (!canSendCommunication) return;
    setIsLoading(true);

    const payload = {
      send: send,
      showPreviewURL: showPreviewURL,
      appointment_id: appointment.id,
      note: formState.note,
      customer_id: formState.manualEmailSelected ? null : formState.selectedCustomerID,
      receivers: getReceivers()
    };

    try {
      const data = await saveCustomerCommunicationMutation.mutateAsync(payload);
      setIsLoading(false);
      if (data?.showPreviewURL) {
        setShowPreviewURL(data.showPreviewURL);
      } else {
        onClose && onClose();
      }
    } catch (err) {
      toast.error(t("v8_something_went_wrong").message || "Something went wrong, please try again.");
      setIsLoading(false);
      throw err;
    }
  };

  const handleDownloadPdfPreview = async () => {
    setIsDownloading(true);
    const payload = {
      appointment_id: appointment.id,
      customer_id: formState.manualEmailSelected ? null : formState.selectedCustomerID,
      receivers: getReceivers(),
      locale
    };
    try {
      const result = await downloadPdfPreview(payload);
      saveAs(result, `Preview_${appointment.reg_number_escaped}_WO_${appointment.wo_nr}_${moment().format(DATE_FORMATS.yearMonthDate)}.pdf`);
      setIsDownloading(false);
    } catch (e) {
      console.error("Error downloading preview report.", e);
      toast.error(t("v8_something_went_wrong").message || "Something went wrong, please contact the administrator.");
      setIsDownloading(false);
    }
  };

  const isSaveButtonDisabled = () => {
    let isSaveButtonDisabled = false;
    if (formState.manualEmailSelected && !formState.manuallyEnteredEmail) isSaveButtonDisabled = true;

    if (!formState.manualEmailSelected && !formState.parsedCustomerEmail && !formState.parsedCustomerPhone && !location?.third_party_send_communications)
      isSaveButtonDisabled = true;

    if (
      !location?.third_party_send_communications &&
      dealer?.sms_gateway_id &&
      dealer.sms_gateway_id > SMS_GATEWAYS.Disabled &&
      formState.selectedEmail === ";2" &&
      formState.selectedPhone === ";2"
    )
      isSaveButtonDisabled = true;

    return isSaveButtonDisabled;
  };

  useEffect(() => {
    setEmailOptionsAndValue(appointment);
    setPhoneOptionsAndValue(appointment);
  }, []);

  return (
    <Modal open size="fullscreen" onClose={onClose} className="CustomerCommunicationModal">
      <Modal.Header className="CustomerCommunicationModal-header">
        <h1>{t("v8_customer_communication").message || "Customer Communication"}</h1>
        <span onClick={onClose}>
          <Icon name="close" />
        </span>
      </Modal.Header>
      <Modal.Content className="CustomerCommunicationModal-content">{renderContent()}</Modal.Content>
      <Modal.Actions className="CustomerCommunicationModal-action">
        <div className="inner">
          {showPreviewURL && (
            <Button loading={isDownloading} color="green" className="download-btn" onClick={handleDownloadPdfPreview}>
              {t("v8_download").message || "Download"}
            </Button>
          )}
          <Button loading={isLoading} disabled={isSaveButtonDisabled()} color="green" className="save-btn" onClick={handleSaveCommunication}>
            {getSaveButtonName()}
            <Icon className="check" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
