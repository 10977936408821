import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon, Label } from "semantic-ui-react";

import { Can, DATE_FORMATS, ReactTable } from "components";
import { useDealersLocations, useUser } from "hooks";
import { DOCUMENTATION_CATEGORIES, DealerDocumentation } from "models";
import "modules/DealerDocumentations/components/DealerDocumentationTable/DealerDocumentationTable.scss";
import { useDealerDocumentationContext } from "modules/DealerDocumentations/context/DealerDocumentationContext";
import { getEntityNames, useDealerDocumentationTable } from "modules/DealerDocumentations/hooks";
import { ITranslation } from "util/interfaces";

export const DealersDocumentationTable = () => {
  const t = useTranslation().t as ITranslation;
  const user = useUser();

  const { dealers, locations } = useDealersLocations();
  const { dealersDocumentation } = useDealerDocumentationTable();
  const { handleSetShowViewModalDocumentation, handleSetShowAddEditDealerDocumentationModal } = useDealerDocumentationContext();

  const isOwnerOrHasHigherPrivilege = (data: DealerDocumentation): boolean => {
    if (!user || !data) return false;

    if (data.publish_date === null || moment(data.publish_date).isAfter(moment().endOf("day"))) {
      return user.id === data.created_by?.id;
    } else {
      if (!data.created_by?.role_id) return false;
      return user.role_id <= data.created_by.role_id;
    }
  };
  const columnHelper = createColumnHelper<DealerDocumentation>();
  const columns = [
    columnHelper.accessor(row => row, {
      id: "title",
      header: t("v8_title").message || "Title",
      cell: ({ cell }) => {
        const { getValue } = cell;
        const { title, is_pinned } = getValue();
        return (
          <>
            <Icon className={is_pinned ? "pin" : "circle regular"} /> {title}
          </>
        );
      },
      size: 200
    }),
    columnHelper.accessor(row => row, {
      id: "dealer",
      header: t("v8_dealer").message || "Dealer",
      cell: ({ cell: { getValue } }) => {
        const { dealer_ids } = getValue();
        return getEntityNames({ defaultMessage: "All dealers", entities: dealers, entityIds: dealer_ids, translationKey: "v8_all_dealers", t });
      },
      size: 150
    }),
    columnHelper.accessor(row => row, {
      id: "locations",
      header: t("v8_locations").message || "Locations",
      cell: ({ cell: { getValue } }) => {
        const { dealer_location_ids } = getValue();
        return getEntityNames({ defaultMessage: "All locations", entities: locations, entityIds: dealer_location_ids, translationKey: "v8_all_locations", t });
      },
      size: 150
    }),
    columnHelper.accessor(row => row, {
      id: "publish_date",
      header: t("v8_publish_date").message || "Publish Date",
      cell: ({ getValue }) => {
        const { publish_date } = getValue();
        return <span> {publish_date ? moment(publish_date).format(DATE_FORMATS.dateMonthYear) : ""}</span>;
      },
      size: 100
    }),
    columnHelper.accessor(row => row, {
      id: "category",
      header: t("v8_category").message || "Category",
      cell: ({ getValue }) => {
        const { category_id } = getValue();
        return <Label className="doc-table-category"> {DOCUMENTATION_CATEGORIES[category_id]} </Label>;
      },
      size: 80
    }),
    columnHelper.accessor(row => row, {
      id: "published_by",
      header: t("v8_published_by").message || "Published By",
      cell: ({ getValue }) => {
        const { updated_by, created_by } = getValue();
        const publishedBy = updated_by ? `${updated_by.first_name} ${updated_by.last_name}` : `${created_by?.first_name} ${created_by?.last_name}`;
        return <span> {publishedBy} </span>;
      },
      size: 100
    }),

    columnHelper.accessor(row => row, {
      id: "edit",
      header: "",
      cell: ({ getValue }) => {
        const data = getValue();
        if (!isOwnerOrHasHigherPrivilege(data)) return null;

        return (
          <Can I="update" the="dealer-documentation">
            <div className="edit-button-container">
              <div
                className="edit-button"
                onClick={e => {
                  e.stopPropagation();
                  handleSetShowAddEditDealerDocumentationModal(data);
                }}
              >
                <Icon className="pen to square edit-icon" />
              </div>
            </div>
          </Can>
        );
      },
      size: 50
    })
  ];

  return dealersDocumentation?.length ? (
    <div className="DealerDocumentationTable">
      <ReactTable columns={columns} data={dealersDocumentation} onRowClick={handleSetShowViewModalDocumentation} />
    </div>
  ) : (
    <div className="SubitemsTable">
      <div className="no-data">
        <p>{t("v8_no_data_to_show").message || "No data to show"}</p>
      </div>
    </div>
  );
};
