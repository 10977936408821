import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";

import { useDealersAndLocationsDropdown } from "components/DealersAndLocationsDropdown/useDealersAndLocationsDropdown";
import { ITranslation } from "util/interfaces";

interface DealersAndLocationsDropdownProps extends DropdownProps {
  handleOnChange: ({ dealerIds, locationIds }: DealersLocationsDropdownValue) => void;
  values: DealersLocationsDropdownValue;
  allowSelectAllDealers?: boolean;
  allowSelectDealers?: boolean;
}

export type DealersLocationsDropdownValue = {
  dealerIds: number[];
  locationIds: number[];
};

export const DealersAndLocationsDropdown = ({
  handleOnChange,
  values,
  allowSelectAllDealers = true,
  allowSelectDealers = true,
  ...rest
}: DealersAndLocationsDropdownProps) => {
  const t = useTranslation().t as ITranslation;
  const { allDealersSelected, groupedLocationsOptions, handleOnSelectionChange } = useDealersAndLocationsDropdown({
    handleOnChange,
    allowSelectDealers,
    allowSelectAllDealers
  });
  const value = allDealersSelected ? ["all"] : [...values.locationIds.map(l => "location-" + l), ...(allowSelectDealers ? values.dealerIds.map(d => "dealer-" + d) : [])];

  return (
    <Dropdown
      selectOnBlur={false}
      placeholder={allowSelectDealers ? t("dealers_locations").message || "Dealers/Locations" : t("locations").message || "Locations"}
      value={value}
      closeOnChange={false}
      options={groupedLocationsOptions}
      onChange={handleOnSelectionChange}
      fluid
      search
      multiple
      floating
      selection
      scrolling
      clearable
      renderLabel={(option: DropdownItemProps) => ({ content: ` ${option.labelText} `, icon: option.icon })}
      {...rest}
    />
  );
};
