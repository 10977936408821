import { Grid, GridColumn } from "semantic-ui-react";

import { LeadTypeFilter } from "modules/LeadsDashboard/components";

export const TiresLeadsFilters = () => (
  <>
    <Grid columns={16} className="toolbar-container">
      <GridColumn width={4} className="no-left-gutter right-gutter ">
        <LeadTypeFilter />
      </GridColumn>
    </Grid>
  </>
);
