import { STATUS_IDENTIFIER, User } from "models";
import { DAYPLANNER_STATUSES, ShouldBeFiltered, filtersMatchingStatuses } from "modules/Dayplanner/components/StatusFilters";
import { DayplannerAppointment, DayplannerColumns, MECHANIC_SORTING, MechanicWithAppointments, WO_INTERNAL_FILTER } from "modules/Dayplanner/interfaces";
import { updateAppointmentStatusIdentifier } from "util/appointmentUtils";

export const ascendingSort = (a: MechanicWithAppointments, b: MechanicWithAppointments): number => {
  return (a.first_name + a.last_name).localeCompare(b.first_name + b.last_name);
};

export const descendingSort = (a: MechanicWithAppointments, b: MechanicWithAppointments): number => {
  return (b.first_name + b.last_name).localeCompare(a.first_name + a.last_name);
};

export const sortMechanics = (mechanics: MechanicWithAppointments[], mechanicsSorting: MECHANIC_SORTING, mechanicsPinned: number[]): MechanicWithAppointments[] => {
  const pinnedMechanics: MechanicWithAppointments[] = [];
  const mechanicWithAppointments: MechanicWithAppointments[] = [];
  const mechanicWithoutAppointments: MechanicWithAppointments[] = [];

  mechanics.forEach(mechanic => {
    if (mechanicsPinned.includes(mechanic.id)) {
      pinnedMechanics.push(mechanic);
    } else if (mechanic.appointments?.length > 0) {
      mechanicWithAppointments.push(mechanic);
    } else {
      mechanicWithoutAppointments.push(mechanic);
    }
  });

  if (mechanicsSorting === MECHANIC_SORTING.Ascending) {
    pinnedMechanics.sort(ascendingSort);
    mechanicWithAppointments.sort(ascendingSort);
    mechanicWithoutAppointments.sort(ascendingSort);
  } else {
    pinnedMechanics.sort(descendingSort);
    mechanicWithAppointments.sort(descendingSort);
    mechanicWithoutAppointments.sort(descendingSort);
  }

  return [...pinnedMechanics, ...mechanicWithAppointments, ...mechanicWithoutAppointments];
};

export const sortAppointments = (appointments: DayplannerAppointment[]) => {
  return appointments.sort((ac1: DayplannerAppointment, ac2: DayplannerAppointment) => {
    if (ac1.appointment_status_identifier === STATUS_IDENTIFIER.BackOrderStatus && ac2.appointment_status_identifier !== STATUS_IDENTIFIER.BackOrderStatus) return 1;
    if (ac2.appointment_status_identifier === STATUS_IDENTIFIER.BackOrderStatus && ac1.appointment_status_identifier !== STATUS_IDENTIFIER.BackOrderStatus) return -1;

    const ac1Time = ac1.car_return_time || ac1.planning_work_stop;
    const ac2Time = ac2.car_return_time || ac2.planning_work_stop;

    if (ac1Time && ac2Time) {
      return new Date(ac1Time).getTime() - new Date(ac2Time).getTime();
    }

    return Number(ac1.wo_nr) - Number(ac2.wo_nr);
  });
};

export const prepareDayplannerData = (mechanics: User[], appointments: DayplannerAppointment[], mechanicsSorting: MECHANIC_SORTING, mechanicsPinned: number[]) => {
  const columns: DayplannerColumns = {
    appointmentsColumn: [],
    unassignedColumn: [],
    mechanicsColumn: mechanics.map(mechanic => ({ ...mechanic, appointments: [] })),
    carReadyColumn: [],
    qualityControlColumn: []
  };

  const dayplannerAppointments: DayplannerAppointment[] = [];

  sortAppointments(appointments)
    .map(updateAppointmentStatusIdentifier)
    .forEach(appointment => {
      if (!appointment.assigned_mechanic) {
        switch (appointment.appointment_status_identifier) {
          case STATUS_IDENTIFIER.CarReadyStatus:
          case STATUS_IDENTIFIER.CarOkPlusRepairOverview:
            columns.carReadyColumn.push(appointment);
            dayplannerAppointments.push(appointment);
            break;

          case STATUS_IDENTIFIER.QualityCheckStatus:
          case STATUS_IDENTIFIER.QualityCheckPlusRepairOverview:
            columns.qualityControlColumn.push(appointment);
            dayplannerAppointments.push(appointment);
            break;

          case STATUS_IDENTIFIER.CanceledStatus:
            columns.appointmentsColumn.push(appointment);
            dayplannerAppointments.push(appointment);
            break;

          default:
            if (appointment.car_in_shop && !appointment.car_out_of_shop) {
              columns.unassignedColumn.push(appointment);
              dayplannerAppointments.push(appointment);
            } else if (appointment.car_out_of_shop) {
              columns.appointmentsColumn.push(appointment);
              dayplannerAppointments.push(appointment);
            } else {
              switch (appointment.appointment_status_identifier) {
                case STATUS_IDENTIFIER.CarCheckStatus:
                case STATUS_IDENTIFIER.BackOrderStatus:
                case STATUS_IDENTIFIER.PricingOKStatus:
                case STATUS_IDENTIFIER.ContactStatus:
                case STATUS_IDENTIFIER.CustomerOKStatus:
                case STATUS_IDENTIFIER.DiagnoseStatus:
                  columns.unassignedColumn.push(appointment);
                  dayplannerAppointments.push(appointment);
                  break;

                default:
                  columns.appointmentsColumn.push(appointment);
                  dayplannerAppointments.push(appointment);
                  break;
              }
            }
        }
      } else {
        const assignedMechanic = columns.mechanicsColumn.find(mechanic => mechanic.id === appointment.assigned_mechanic);
        if (assignedMechanic) {
          assignedMechanic.appointments.push(appointment);
          dayplannerAppointments.push(appointment);
        }
      }
    });

  columns.mechanicsColumn = sortMechanics(columns.mechanicsColumn, mechanicsSorting, mechanicsPinned);

  return { columns, dayplannerAppointments };
};

export const appointmentMatchesSearch = (app: DayplannerAppointment, searchTerm: string) => {
  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  return (
    app.wo_nr.toLowerCase().includes(lowerCaseSearchTerm) ||
    app.reg_number.toLowerCase().includes(lowerCaseSearchTerm) ||
    `${app.driver_firstname} ${app.driver_surname}`.toLowerCase().includes(lowerCaseSearchTerm) ||
    `${app.owner_firstname} ${app.owner_surname}`.toLowerCase().includes(lowerCaseSearchTerm)
  );
};

export const woInternalFiltered = (app: DayplannerAppointment, selectedWoInternalFilter: WO_INTERNAL_FILTER) => {
  return selectedWoInternalFilter === WO_INTERNAL_FILTER.Internal ? app.internal : !app.internal;
};

export const applyFilters = (
  columns: DayplannerColumns,
  activeFilters: DAYPLANNER_STATUSES[],
  searchTerm: string,
  selectedWoInternalFilter: WO_INTERNAL_FILTER
): DayplannerColumns => {
  const matchFilterAndSearch = (appointment: DayplannerAppointment) => {
    const matchesFilter = activeFilters.some(filter => ShouldBeFiltered(appointment, filter));
    const matchesSearch = searchTerm ? appointmentMatchesSearch(appointment, searchTerm) : true;
    const matchesWoInternalFilter = selectedWoInternalFilter !== WO_INTERNAL_FILTER.All ? woInternalFiltered(appointment, selectedWoInternalFilter) : true;
    const isMatchingStatus = filtersMatchingStatuses.includes(appointment.appointment_status_identifier);
    const isInactiveMatchingStatus = !(matchesFilter && matchesSearch && matchesWoInternalFilter && isMatchingStatus);
    const isInactiveNonMatchingStatus = !(matchesWoInternalFilter && matchesSearch);

    return {
      ...appointment,
      inactive: isMatchingStatus ? isInactiveMatchingStatus : isInactiveNonMatchingStatus
    };
  };

  return {
    appointmentsColumn: columns.appointmentsColumn.map(matchFilterAndSearch),
    unassignedColumn: columns.unassignedColumn.map(matchFilterAndSearch),
    mechanicsColumn: columns.mechanicsColumn.map(mechanic => ({
      ...mechanic,
      appointments: mechanic.appointments.map(matchFilterAndSearch)
    })),
    carReadyColumn: columns.carReadyColumn.map(matchFilterAndSearch),
    qualityControlColumn: columns.qualityControlColumn.map(matchFilterAndSearch)
  };
};
