import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { WebSocketMessageListener } from "components";
import { useRealTimeQuery } from "hooks";
import { User } from "models";
import { QUERY_KEY_COMPONENTS, staticLocalKey } from "util/keyFactory";

export const useUser = () => {
  const queryClient = useQueryClient();
  const userDataKey = staticLocalKey(QUERY_KEY_COMPONENTS.UserData);

  const listeners = useMemo((): WebSocketMessageListener[] => {
    return [
      {
        model: "User",
        action: "update",
        callback: message => {
          const user = queryClient.getQueryData<User>(userDataKey);
          if (!user) return;

          const updatedUserData = message.data as User;
          queryClient.setQueryData(userDataKey, { ...user, ...updatedUserData });
        }
      }
    ];
  }, [queryClient]);

  const realTimeQuery = useRealTimeQuery({
    queryKey: userDataKey,
    listeners
  });

  return realTimeQuery.data as User | undefined;
};
