import { useEffect, useState } from "react";

import { QuestionResultData } from "components";
import SnoozeModal from "components/SnoozeModal";
import { useDealersLocations } from "hooks";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { NotSnoozedLeadsTable } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { NotSnoozedLeadsFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components/NotSnoozedFilters";
import { useGetNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/hooks";
import { useGetTagsQuery, useLeadsNotSnoozedQuery } from "modules/LeadsDashboard/service";

export const NotSnoozedLeads = () => {
  const { locations } = useDealersLocations();
  const tags = useGetTagsQuery();
  const { notSnoozedFilters } = useGetNotSnoozedFilters();
  const { refetch } = useLeadsNotSnoozedQuery(notSnoozedFilters);
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);
  const [currentlySnoozedItems, setCurrentlySnoozedItems] = useState<number[]>([]);

  useEffect(() => {
    const hasDealerLocationSelected = notSnoozedFilters.location_ids?.length > 0 || notSnoozedFilters.dealer_ids?.length > 0;
    const hasDateRangeSelected = notSnoozedFilters.date_from && notSnoozedFilters.date_to;

    if (hasDealerLocationSelected && hasDateRangeSelected) refetch();

    return () => {
      setCurrentlySnoozedItems([]);
    };
  }, [notSnoozedFilters]);

  return (
    <>
      <NotSnoozedLeadsFilters filters={notSnoozedFilters} tagsOptions={tags} />
      <NotSnoozedLeadsTable
        locations={locations}
        dateFrom={notSnoozedFilters.date_from}
        dateTo={notSnoozedFilters.date_to}
        setModalData={setModalData}
        currentlySnoozedItems={currentlySnoozedItems}
      />
      <SnoozeModal
        size="large"
        open={modalData !== null}
        onClose={() => setModalData(null)}
        closeOnDimmerClick
        data={modalData}
        onSave={(questionResultId: number) => setCurrentlySnoozedItems([...currentlySnoozedItems, questionResultId])}
      />
    </>
  );
};
