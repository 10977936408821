import { useEffect, useState } from "react";

import { QuestionResultData } from "components";
import SnoozeModal from "components/SnoozeModal";
import { useDealersLocations } from "hooks";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { useGetDepartmentOptions } from "modules/LeadsDashboard/hooks";
import { SnoozedLeadsFilters, SnoozedLeadsTable } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { useGetSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks";
import { useGetTagsQuery, useLeadsSnoozedQuery } from "modules/LeadsDashboard/service";

export const SnoozedLeads = () => {
  const { locations } = useDealersLocations();
  const tags = useGetTagsQuery();
  const { snoozedFilters } = useGetSnoozedFilters();
  const { refetch } = useLeadsSnoozedQuery(snoozedFilters);
  const { departmentOptions } = useGetDepartmentOptions();
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);

  useEffect(() => {
    const hasDealerLocationSelected = snoozedFilters.location_ids?.length > 0 || snoozedFilters.dealer_ids?.length > 0;
    const hasAppointmentDateRangeSelected = snoozedFilters.appointment_date_from && snoozedFilters.appointment_date_from;
    const hasSnoozeDateRangeSelected = snoozedFilters.snooze_date_from && snoozedFilters.snooze_date_to;
    const hasDateRangeSelected = hasAppointmentDateRangeSelected || hasSnoozeDateRangeSelected;

    if (hasDealerLocationSelected && hasDateRangeSelected) refetch();
  }, [snoozedFilters]);

  return (
    <>
      <SnoozedLeadsFilters filters={snoozedFilters} tagsOptions={tags} departmentOptions={departmentOptions} />
      <SnoozedLeadsTable locations={locations} filters={snoozedFilters} setModalData={setModalData} />
      <SnoozeModal
        size="large"
        open={modalData !== null}
        onClose={() => setModalData(null)}
        closeOnDimmerClick
        data={modalData}
        onSave={() => refetch()}
        onDelete={() => refetch()}
      />
    </>
  );
};
