import _omit from "lodash/omit";

import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey, localQueryKey } from "util/keyFactory";

const component = "LocationLicenseOverview";

export const LocationLicenseOverviewKeys = {
  items: (params: Record<string, unknown>) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.exactBaseUrl,
      endpoint: "/licenses/items/list",
      params
    })
  ],
  licenses: (params: Record<string, unknown>) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.exactBaseUrl,
      endpoint: "/licenses/list",
      params
    })
  ],
  invoices: (params: Record<string, unknown>) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.exactBaseUrl,
      endpoint: "/invoices/list",
      params
    })
  ],
  paginatedInvoices: [localQueryKey({ component, data: "paginatedInvoices" })]
};
