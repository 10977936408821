import { useQueryClient } from "@tanstack/react-query";

import { DealersLocationsDropdownValue } from "components";
import { LeadsSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { useDefaultSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks/useGetSnoozedFilters";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";

export const useSnoozedFilterHandlers = () => {
  const queryClient = useQueryClient();
  const { defaultSnoozedFilters } = useDefaultSnoozedFilters();
  const filters = queryClient.getQueryData(LeadsQueryKeys.snoozedFilters) as LeadsSnoozedFilters;

  const handleResetFilters = () => {
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, defaultSnoozedFilters);
  };

  const handleSearchChange = (value: string) => {
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...filters, search_term: value });
  };

  const handleDealerLocationChange = ({ dealerIds, locationIds }: DealersLocationsDropdownValue) => {
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...filters, dealer_ids: dealerIds, location_ids: locationIds });
  };

  const handleTagsChange = (value: number[]) => {
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...filters, tags: value });
  };

  const handleDepartmentChange = (value: number) => {
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...filters, snooze_department_id: value ? value : null });
  };

  const handleCustomerApprovedChange = () => {
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...filters, customer_approved: !filters?.customer_approved });
  };

  const handleInterventionsChange = () => {
    queryClient.setQueryData<LeadsSnoozedFilters>(LeadsQueryKeys.snoozedFilters, { ...filters, interventions: !filters?.interventions });
  };

  return {
    handleTagsChange,
    handleResetFilters,
    handleSearchChange,
    handleInterventionsChange,
    handleDealerLocationChange,
    handleCustomerApprovedChange,
    handleDepartmentChange
  };
};
