import { useGetSelectedLeadType } from "modules/LeadsDashboard/hooks";
import { CarsLeads, NotSnoozedLeads, SnoozedLeads, TiresLeads } from "modules/LeadsDashboard/Leads";
import "modules/LeadsDashboard/LeadsDashboard.scss";

export enum LEAD_TYPES {
  Snoozed = 1,
  NotSnoozed,
  Cars,
  Tires
}

const leadTypesMap = {
  [LEAD_TYPES.Cars]: <CarsLeads />,
  [LEAD_TYPES.Snoozed]: <SnoozedLeads />,
  [LEAD_TYPES.NotSnoozed]: <NotSnoozedLeads />,
  // TODO: Tires page is going to be implemented in the future, for now it will have only lead type selector
  [LEAD_TYPES.Tires]: <TiresLeads />
};

const Leads = () => {
  const { selectedLeadType } = useGetSelectedLeadType();
  return <div className="LeadsDashboard">{leadTypesMap[selectedLeadType]}</div>;
};

export default Leads;
