import { UniqueIdentifier } from "@dnd-kit/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { useDealersLocations } from "hooks";
import { NoteModalProps } from "modules/NoteSuggestions/components";
import { SortableItems } from "modules/NoteSuggestions/components/SortableList/SortableList";
import { updateNoteSuggestionMutation } from "modules/NoteSuggestions/service";
import { ITranslation } from "util/interfaces";

export const useNoteSuggestionsModal = ({ notes, modalId, closeModal }: NoteModalProps) => {
  const t = useTranslation().t as ITranslation;
  const selectedNote = modalId && notes?.find(note => note.note_type.note_type_id === modalId);
  const extendedSuggestions = [...(selectedNote?.note_suggestions || [])].map(suggestion => ({ ...suggestion, id: uuidv4() }));
  const { selectedLocation } = useDealersLocations();
  const [items, setItems] = useState<SortableItems[]>(extendedSuggestions);
  const [noteVisibility, setNoteVisibility] = useState(selectedNote?.note_type.active || false);
  const { mutate: updateNoteSuggestions } = updateNoteSuggestionMutation();

  useEffect(() => {
    setItems(extendedSuggestions);
    setNoteVisibility(selectedNote?.note_type.active || false);
  }, [selectedNote, modalId]);

  const handleRemoveItem = (id: UniqueIdentifier) =>
    setItems(prev => prev.filter(suggestion => suggestion.id !== id).map((suggestion, index) => ({ ...suggestion, order: index + 1 })));

  const handleContentEdit = (id: UniqueIdentifier, content: string) =>
    setItems(prev => prev.map(suggestion => (suggestion.id === id ? { ...suggestion, content } : { ...suggestion })));

  const handleAddItem = () => {
    setItems(prev => {
      const newSuggestion = {
        id: uuidv4(),
        order: prev.length + 2,
        content: "",
        note_type_id: modalId!,
        note: "",
        created_on: new Date().toISOString(),
        updated_on: new Date().toISOString(),
        dealer_location_id: selectedLocation!.id
      };
      return [...prev, newSuggestion];
    });
  };

  const handleVisibleInNote = () => setNoteVisibility(prev => !prev);
  const handleOnSave = () => {
    if (!selectedNote) return;
    if (items.some(item => !item.content)) return toast.error(t("v8_content_is_mandatory").message || "Content is mandatory");
    if (items.length < 3) return toast.error(t("v8_minimum_three_suggestions").message || "Minimum of 3 suggestions are required");
    updateNoteSuggestions(
      { ...selectedNote, note_suggestions: items, note_type: { ...selectedNote.note_type, active: noteVisibility } },
      {
        onSuccess: () => {
          setItems([]);
          closeModal();
        }
      }
    );
  };

  return { items, handleOnSave, handleVisibleInNote, handleAddItem, handleRemoveItem, handleContentEdit, noteVisibility, setItems };
};
