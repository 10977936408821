import { faCircle, faEnvelope, faLocationPin, faPencil, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { DATE_FORMATS } from "components";
import { COMMUNICATION_EVENT_TYPE, CommunicationEvent } from "models";
import { ITranslation } from "util/interfaces";

type CommunicationEventsProps = {
  events: CommunicationEvent[];
};

enum CHANNEL {
  Email = 1,
  SMS,
  Webhook
}

const getCommunicationEventName = (event: CommunicationEvent, t: ITranslation) => {
  switch (event.type) {
    case COMMUNICATION_EVENT_TYPE.OnlineCheckInSent:
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewSent:
    case COMMUNICATION_EVENT_TYPE.RepairOverviewSent:
      return t("v8_sent").message || "Sent";

    case COMMUNICATION_EVENT_TYPE.OnlineCheckInOpened:
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened:
    case COMMUNICATION_EVENT_TYPE.RepairOverviewOpened:
      return t("v8_opened").message || "Opened";

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent:
    case COMMUNICATION_EVENT_TYPE.RepairOverviewResent:
      return t("v8_resent").message || "Resent";

    case COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered:
    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered:
      return t("v8_answered").message || "Answered";

    default:
      return "";
  }
};

export const CommunicationEvents = ({ events }: CommunicationEventsProps) => {
  const t = useTranslation().t as ITranslation;
  if (!events?.length) return null;

  return (
    <div className="info-section">
      <div className="section-header">{t("v8_communication_events").message || "Communication events"}</div>
      <div className="section-content">
        <Grid>
          {events.map((event, key) => {
            const fullname = event.customer && `${event.customer.title} ${event.customer.firstname} ${event.customer.surname}`;

            return (
              <Grid.Row key={key}>
                <Grid.Column width={12}>{getCommunicationEventName(event, t)}</Grid.Column>

                <Grid.Column width={4} className="event-timestamp">
                  {moment(event.created_on).format(DATE_FORMATS.time)} - {moment(event.created_on).format(DATE_FORMATS.dateMonthYear)}
                </Grid.Column>

                <Grid.Row width={16} className="section-rows">
                  {event.receivers?.map((receiver, i) => (
                    <React.Fragment key={i}>
                      <span>
                        <FontAwesomeIcon icon={receiver.channel === CHANNEL.Email ? faEnvelope : faPhone} className="i-green mr-5" />
                        {receiver.destination}
                      </span>
                      {receiver.reason && (
                        <span className="ml-10">
                          <FontAwesomeIcon key={i} icon={faCircle} color="red" className="mr-5" />
                          {receiver.reason}
                        </span>
                      )}
                    </React.Fragment>
                  ))}

                  {fullname && (
                    <span>
                      <FontAwesomeIcon icon={faUser} className="i-green mr-5" />
                      {fullname}
                    </span>
                  )}

                  {event.customer_name && (
                    <span>
                      <FontAwesomeIcon icon={faPencil} className="i-green mr-5" />
                      {event.customer_name}
                    </span>
                  )}

                  {event.ip && (
                    <>
                      <span className="icon-text">
                        IP
                        <FontAwesomeIcon icon={faLocationPin} className="i-green mr-5 ml-5" />
                      </span>
                      {event.ip}
                    </>
                  )}

                  {event.key_locker_pin && event.type === COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered && <span>{t("v8_pin_sent").message || "Pin sent"}</span>}
                  {event.is_parking_gate_code_sent && <span>{t("v8_parking_gate_code_sent").message || "Parking gate code sent"}</span>}
                </Grid.Row>
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};
