import { saveAs } from "file-saver";
import moment from "moment";
import { MutableRefObject, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Loader, Modal } from "semantic-ui-react";

import { DATE_FORMATS } from "components";
import { Appointment } from "models";
import { getChecklistReport, getChecklistReportUrl } from "modules/AppointmentDetails/components/ChecklistPDF/api";
import { ITranslation } from "util/interfaces";

type ChecklistPDFProps = {
  appointment: Appointment;
  checkId: number;
  isPreviewable: boolean | undefined;
};

export const ChecklistPDF = ({ appointment, checkId, isPreviewable }: ChecklistPDFProps) => {
  const t = useTranslation().t as ITranslation;
  const iframeRef: MutableRefObject<HTMLIFrameElement | null> = useRef(null);
  const [isReportDisplayVisible, setIsReportDisplayVisible] = useState(false);
  const [isReportMessageVisible, setIsReportMessageVisible] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isReportStatusError, setIsReportStatusError] = useState<boolean>(false);

  const errorMessage = isReportStatusError
    ? t("v8_could_not_generate_report_message").message || "Could not generate a report. Make sure that this checklist has a report template selected."
    : null;

  const setReportUrl = (url: string) => {
    if (iframeRef.current) iframeRef.current.src = url;
  };

  const printIframe = () => {
    try {
      iframeRef.current?.contentWindow?.postMessage("print", "*");
    } catch (e) {
      console.error("PrintError", e);
    }
  };

  const downloadChecklistReport = () => {
    if (!appointment.id || !checkId) return;

    setIsReportDisplayVisible(false);
    setIsReportLoading(false);

    getChecklistReport(checkId)
      .then((result: BlobPart) => {
        saveAs(
          new Blob([result], { type: "application/pdf" }),
          `Car_${appointment.reg_number_escaped}_WO_${appointment.wo_nr}_${moment().format(DATE_FORMATS.yearMonthDate)}.pdf`
        );
      })
      .catch((error: Error) => {
        console.error("Error getting checklist report.", error);
        setIsReportMessageVisible(true);
        setIsReportStatusError(true);
      })
      .finally(() => {
        setIsReportLoading(false);
      });
  };

  const showChecklistReport = () => {
    if (!appointment.id || !checkId) return;

    if (!isPreviewable) {
      downloadChecklistReport();
      return;
    }

    setIsReportDisplayVisible(true);
    setIsReportLoading(true);

    getChecklistReportUrl(checkId)
      .then(result => {
        if (result) setReportUrl(result.data.url);
      })
      .catch(error => {
        console.error("Error getting checklist report.", error);
        setIsReportMessageVisible(true);
        setIsReportStatusError(true);
      })
      .finally(() => {
        setIsReportLoading(false);
      });
  };

  const handleHideReportDisplay = () => {
    setIsReportDisplayVisible(false);
  };

  const handleHideReportMessage = () => {
    setIsReportMessageVisible(false);
  };

  return (
    <>
      <Button className="-appointment-status icon" size="small">
        <Icon className="file pdf" color="green" onClick={() => showChecklistReport()} />
      </Button>
      <Modal
        className="PrintPreviewModal"
        closeOnEscape
        size="large"
        dimmer="blurring"
        open={isReportDisplayVisible}
        onClose={() => setIsReportDisplayVisible(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Actions
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <Button color="green" onClick={printIframe}>
            {t("v8_print").message || "PRINT"}
          </Button>
          <Button color="green" onClick={() => downloadChecklistReport()}>
            {t("v8_download").message || "DOWNLOAD"}
          </Button>
          <Button color="blue" onClick={handleHideReportDisplay}>
            {t("v8_close").message || "CLOSE"}
          </Button>
        </Modal.Actions>
        <Modal.Content>
          <iframe style={{ width: "100%", minHeight: "600px", border: "0px", display: "block" }} ref={iframeRef} title="reportDisplay" />
        </Modal.Content>
      </Modal>

      <Modal className="ReportMessageModal" size="mini" dimmer="blurring" open={isReportMessageVisible} onClose={handleHideReportMessage} closeOnDimmerClick={false}>
        <Modal.Header>
          <Icon name="file pdf outline" />
          {t("v8_could_not_get_the_report").message || "Could not get the report"}
        </Modal.Header>
        <Modal.Content>{isReportLoading ? <Loader type="Oval" color="#46923d" height="100" width="100" /> : errorMessage}</Modal.Content>
        <Modal.Actions
          style={{
            textAlign: "center"
          }}
        >
          <Button color="blue" onClick={handleHideReportMessage}>
            {t("v8_close").message || "CLOSE"}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
