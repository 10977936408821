import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

const component = "CarDetails";

export const CarDetailsKeys = {
  details: (id: number) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.carBaseUrl,
      endpoint: "/cars/details",
      params: { id }
    })
  ],

  appointmentHistory: (id: number) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.carBaseUrl,
      endpoint: "/cars/checks/list",
      params: { id }
    })
  ],

  pinItems: (car_id: number) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.appointmentBaseURL,
      endpoint: "/pins/list_by_car_id",
      params: { car_id }
    })
  ],

  snoozetems: (car_id: number) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.WebSocket,
      component,
      baseUrl: ENV.appointmentBaseURL,
      endpoint: "/snoozes/list_by_car_id",
      params: { car_id }
    })
  ]
};
