import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useDealersLocations } from "hooks";
import { Check, QuestionResult, TyreReplacement } from "models";
import { TyreData } from "modules/AppointmentDetails/components/Tyre/components";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

type TyreSearch = {
  height: number;
  id: number;
  in_stock: boolean;
  manufacturer: string;
  season: number;
  size: number;
  tyre_team_delivery_ids: string[];
  width: number;
};

// TODO: cleanup like other hooks
const useTyre = (appointment_id: number) => {
  const checklistQueryKey = AppointmentDetailsKeys.checks(appointment_id);
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const getBrands = async () => {
    const res = await ApiInstance.get((queryKeys.appointments.tyreBrands as unknown as BackendQueryKey).endpoint);
    return res.data.brands;
  };

  const getDelivery = async () => {
    const res = await ApiInstance.post(
      (queryKeys.appointments.deliveryTypes as unknown as BackendQueryKey).endpoint,
      { dealer_location_id: selectedLocation?.id },
      (queryKeys.appointments.deliveryTypes as unknown as BackendQueryKey).baseUrl
    );
    return res.data;
  };

  const brandsQuery = useQuery({
    queryKey: ["realtime", queryKeys.appointments.tyreBrands],
    queryFn: getBrands,
    retry: false
  });

  const deliveryQuery = useQuery({
    queryKey: ["realtime", { ...queryKeys.appointments.deliveryTypes, params: { dealer_location_id: selectedLocation?.id } }],
    queryFn: getDelivery,
    retry: false,
    enabled: !!selectedLocation?.id
  });

  const searchTyreTeamTyres = async (data: TyreSearch) => {
    const res = await ApiInstance.post(
      (queryKeys.appointments.tyreSearch as unknown as BackendQueryKey).endpoint,
      data,
      (queryKeys.appointments.tyreSearch as unknown as BackendQueryKey).baseUrl
    );
    return res.data;
  };

  const searchTyres = async (data: TyreSearch) => {
    const res = await ApiInstance.post("/tyres/search", data);
    return res.data;
  };

  const tyreSearchMutation = useMutation({
    mutationFn: selectedLocation?.is_tyre_team_enabled ? searchTyreTeamTyres : searchTyres,
    onError: e => toast.error(e.message)
  });

  const addTyre = async (data: TyreData) => {
    const res = await ApiInstance.post(selectedLocation?.is_tyre_team_enabled ? "/tyres/add_tyreteam_replacement" : "/tyres/add_replacement", {
      ...data,
      tyre_id: data.tyre_team.id
    });
    return res.data;
  };

  const addTyreMutation = useMutation({
    mutationFn: addTyre,
    onError: e => toast.error(e.message)
  });

  const addTyres = async (selection: Record<string, TyreData>) => {
    return new Promise<void>((resolve, reject) => {
      if (!selection || Object.keys(selection).length === 0) {
        resolve();
        return;
      }

      const promises: unknown[] = [];

      Object.values(selection).forEach(item => {
        promises.push(addTyreMutation.mutate(item));
      });

      Promise.allSettled(promises)
        .then(async () => {
          await resolve();
          await queryClient.invalidateQueries({ queryKey: checklistQueryKey });
        })
        .catch(reject);
    });
  };

  const deleteTyreReplacementFromChecklist = (data: TyreReplacement) => {
    const checklistSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);

    if (checklistSnapshot) {
      const updatedSnapshot = checklistSnapshot.map(item => {
        if (item.question_items) {
          const updatedQuestionItems = item.question_items.map(question => {
            if (question.tyre_replacements) {
              const filteredReplacements = question.tyre_replacements.filter(replacement => replacement.tyre_replacement_id !== data?.tyre_replacement_id);
              return { ...question, tyre_replacements: filteredReplacements };
            }
            return question;
          });
          return { ...item, question_items: updatedQuestionItems };
        }
        return item;
      });

      queryClient.setQueryData(checklistQueryKey, updatedSnapshot);
    }

    return { checklistSnapshot };
  };

  const deleteReplacement = async (data: TyreReplacement) => {
    const res = await ApiInstance.post("/tyres/delete_replacement", { tyre_replacement_id: data.tyre_replacement_id });
    return res.data;
  };

  const tyreReplacementDeleteMutation = useMutation({
    mutationFn: deleteReplacement,
    onMutate: deleteTyreReplacementFromChecklist,
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context?.checklistSnapshot) {
        queryClient.setQueryData(checklistQueryKey, context.checklistSnapshot);
      }
    }
  });

  const updateTyreRemark = async (data: QuestionResult) => {
    const res = await ApiInstance.post("/checks/update_question_item", {
      appointment_id: data.appointment_id,
      dealer_location_id: data.dealer_location_id,
      id: data.id,
      raw: data.raw
    });
    return res.data;
  };

  const updateTyreRemarkFromChecklist = (data: QuestionResult) => {
    const checklistSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);

    if (checklistSnapshot) {
      const updatedSnapshot = checklistSnapshot.map(item => {
        if (item.question_items) {
          const updatedQuestionItems = item.question_items.map(question => {
            if (question.id === data.id) {
              return data;
            }
            return question;
          });
          return { ...item, question_items: updatedQuestionItems };
        }
        return item;
      });

      queryClient.setQueryData(checklistQueryKey, updatedSnapshot);
    }

    return { checklistSnapshot };
  };

  const tyreRemarkMutation = useMutation({
    mutationFn: updateTyreRemark,
    onMutate: updateTyreRemarkFromChecklist,
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context?.checklistSnapshot) {
        queryClient.setQueryData(checklistQueryKey, context.checklistSnapshot);
      }
    }
  });

  return { brandsQuery, deliveryQuery, tyreSearchMutation, addTyres, tyreReplacementDeleteMutation, tyreRemarkMutation };
};

export default useTyre;
