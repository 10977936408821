import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { DATE_FORMATS, ReactTable } from "components";
import { isPercentageField } from "modules/DealerReports/util/helper";
import { ITranslation } from "util/interfaces";

interface ReportRow {
  [key: string]: any;
}

interface LocationReportTableProps {
  data: Record<string, string>[];
}

const formatTableCell = (key: string, value: any, t: ITranslation): React.ReactNode => {
  if (value === null || value === undefined) return "0";

  if (typeof value === "object" && value !== null && "value" in value) {
    if ("percent" in value) {
      return (
        <>
          {value.value} <span style={{ color: value.color ? value.color : "inherit" }}>({value.percent}%)</span>
        </>
      );
    }

    return value.value;
  }

  if (isPercentageField(key)) return `${value}%`;
  if (typeof value === "number" && value.toString().includes(".")) return value.toString().includes(".");
  return key.includes("date") ? moment(value).format(DATE_FORMATS.dateMonthYear) : t(value).message || value;
};

const LocationReportsTable = ({ data }: LocationReportTableProps) => {
  const t = useTranslation().t as ITranslation;

  if (!data || data.length === 0) {
    return (
      <div className="Table__no-results">
        <p>{t("v8_no_data").message || "No data"}</p>
      </div>
    );
  }

  const columnHelper = createColumnHelper<ReportRow>();
  const columns = Object.keys(data[0])
    .filter(key => key !== "__typename")
    .map(key =>
      columnHelper.accessor(key, {
        id: key,
        header: () => <strong>{t(key).message || key}</strong>,
        cell: info => formatTableCell(key, info.getValue(), t),
        minSize: 135
      })
    );

  return (
    <div className="location-reports-table-container">
      <div className="location-reports-table-wrapper">
        <ReactTable data={data} columns={columns} />
      </div>
    </div>
  );
};

export default LocationReportsTable;
