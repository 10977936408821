import { NoteSuggestionsModal, NoteSuggestionsTable } from "modules/NoteSuggestions/components";
import { useNoteSuggestions } from "modules/NoteSuggestions/hooks";

const NoteSuggestions = () => {
  const { openModal, closeModal, modalId, notes } = useNoteSuggestions();
  return (
    <>
      <NoteSuggestionsTable openModal={openModal} />
      <NoteSuggestionsModal closeModal={closeModal} modalId={modalId} notes={notes} />
    </>
  );
};

export default NoteSuggestions;
