import { useQueryClient } from "@tanstack/react-query";
import { OnChangeFn, PaginationState } from "@tanstack/react-table";

import { useGetLeaseCompanyOptions, useGetQuestionResultStatusOptions } from "modules/LeadsDashboard/hooks";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components/NotSnoozedFilters";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetChecklistsQuestionsQuery } from "modules/LeadsDashboard/service";

type Props = {
  pageSize: number;
};

export const useNotSnoozedLeadsTableFilterHandlers = ({ pageSize }: Props) => {
  const queryClient = useQueryClient();
  const { leaseCompanyOptions } = useGetLeaseCompanyOptions();
  const notSnoozedFilters = queryClient.getQueryData(LeadsQueryKeys.notSnoozedFilters) as LeadsNotSnoozedFilters;
  const { questionResultStatusOptions } = useGetQuestionResultStatusOptions();

  const currentPage = notSnoozedFilters.page;
  const selectedQuestionStatuses = notSnoozedFilters.statuses?.map(status => status.toString()) || ["all"];
  const selectedLeaseCompanyOption = leaseCompanyOptions.find(option => option.filterValue === notSnoozedFilters?.is_lease_company)?.value || "";
  const interventionQuestionOptions =
    useGetChecklistsQuestionsQuery({ dealer_ids: notSnoozedFilters?.dealer_ids || [], location_ids: notSnoozedFilters?.location_ids || [] }) || [];

  const selectedQuestions = notSnoozedFilters?.questions
    ? notSnoozedFilters.questions.reduce((acc, questionId) => {
        const question = interventionQuestionOptions.find(element => element.filterValue === questionId);
        if (question) acc.push(question.key.toString());

        return acc;
      }, [] as string[])
    : ["all"];

  const handleQuestionResultStatusSelect = (questionStatuses: string[]) => {
    const isResetNotSelected = questionStatuses.includes("all") && notSnoozedFilters.statuses !== null;
    const statuses = isResetNotSelected ? null : questionStatuses.map(Number).filter(Boolean);
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...notSnoozedFilters, statuses });
  };

  const handleOnLeaseSelect = (leaseCompany: string[]) => {
    const leaseCompanyOption = leaseCompanyOptions.find(option => option.value === leaseCompany[0]) || null;
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, {
      ...notSnoozedFilters,
      is_lease_company: leaseCompanyOption ? leaseCompanyOption?.filterValue : leaseCompanyOption
    });
  };

  const handleOnQuestionSelect = (questions: string[]) => {
    const isResetNotSelected = questions.includes("all") && notSnoozedFilters.questions !== null;
    const newQuestions = isResetNotSelected ? null : questions.map(el => Number(el.split("-")[1]));
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...notSnoozedFilters, questions: newQuestions });
  };

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    if (start && end) queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...notSnoozedFilters, date_from: start, date_to: end });
  };

  const onPageChange = (_e: null, data: { activePage: number }) => {
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...notSnoozedFilters, page: data.activePage });
  };

  const handlePaginationSelect: OnChangeFn<PaginationState> = updater => {
    const newState = typeof updater === "function" ? updater({ pageIndex: currentPage - 1, pageSize }) : updater;
    onPageChange(null, { activePage: newState.pageIndex + 1 });
  };

  return {
    handleOnLeaseSelect,
    handleDateRangeChange,
    handlePaginationSelect,
    handleOnQuestionSelect,
    handleQuestionResultStatusSelect,
    currentPage,
    selectedQuestions,
    leaseCompanyOptions,
    selectedQuestionStatuses,
    selectedLeaseCompanyOption,
    questionResultStatusOptions,
    interventionQuestionOptions
  };
};
