import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { LEAD_TYPES } from "modules/LeadsDashboard";
import { useGetSelectedLeadType } from "modules/LeadsDashboard/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { ITranslation, SelectOption } from "util/interfaces";

export const LeadTypeFilter = () => {
  const t = useTranslation().t as ITranslation;
  const queryClient = useQueryClient();
  const { selectedLeadType } = useGetSelectedLeadType();

  const leadsTypeOptions: SelectOption<number>[] = useMemo(
    () => [
      { key: LEAD_TYPES.Snoozed, value: LEAD_TYPES.Snoozed, text: t("v8_snoozed").message || "Snoozed" },
      { key: LEAD_TYPES.NotSnoozed, value: LEAD_TYPES.NotSnoozed, text: t("v8_not_snoozed").message || "Not Snoozed" },
      { key: LEAD_TYPES.Cars, value: LEAD_TYPES.Cars, text: t("v8_cars").message || "Cars" },
      { key: LEAD_TYPES.Tires, value: LEAD_TYPES.Tires, text: t("v8_tires").message || "Tires" }
    ],
    []
  );

  const handleChangeLeadsType = (type: LEAD_TYPES) => queryClient.setQueryData(LeadsQueryKeys.leadsPageType, type);

  return (
    <Dropdown
      fluid
      selection
      selectOnBlur={false}
      value={selectedLeadType}
      options={leadsTypeOptions}
      placeholder={t("v8_leads_type").message || "Leads Type"}
      onChange={(_e, data) => handleChangeLeadsType(data.value as LEAD_TYPES)}
    />
  );
};
