import { NOTE_TYPES } from "models";
import { useGetDealerLocationData } from "modules/NoteSuggestions/service";

export const useGetNotes = (noteTypeId: NOTE_TYPES | null) => {
  const { location } = useGetDealerLocationData();

  if (!noteTypeId || !location?.note_suggestions) return [];

  if (!location.note_types?.find(noteType => noteType.note_type_id === noteTypeId)?.active) return [];
  return location.note_suggestions
    .filter(note => note.note_type_id === noteTypeId)
    .map(suggestion => ({ text: suggestion.content, value: suggestion.order, key: suggestion.order }));
};
