import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

export const CommunicationSettingsQueryKeys = {
  settings: (location_id: number | undefined) => [
    backendQueryKey({
      baseUrl: ENV.dealerBaseUrl,
      endpoint: "communication/settings/read",
      updateType: UPDATE_TYPE.Static,
      component: "CommunicationSettings",
      ...(location_id ? { params: { location_id } } : {})
    })
  ],
  pdf_templates: [backendQueryKey({ baseUrl: ENV.apiBase, endpoint: "/report_templates/list", component: "CommunicationSettings", updateType: UPDATE_TYPE.Static })]
};
