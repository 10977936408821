import { useQueryClient } from "@tanstack/react-query";

import { DealersLocationsDropdownValue } from "components";
import { LeadsCarsFilter } from "modules/LeadsDashboard/Leads/CarsLeads/components";
import "modules/LeadsDashboard/Leads/CarsLeads/components/CarsLeadsFilters/CarsLeadsFilters.scss";
import { useDefaultCarsFilters } from "modules/LeadsDashboard/Leads/CarsLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";

export const useCarsFilterHandlers = () => {
  const queryClient = useQueryClient();
  const { defaultLeadsCarsFilters } = useDefaultCarsFilters();
  const filters = queryClient.getQueryData(LeadsQueryKeys.carsFilters) as LeadsCarsFilter;

  const handleResetFilters = () => {
    queryClient.setQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters, defaultLeadsCarsFilters);
  };

  const handleSearchChange = (value: string) => {
    queryClient.setQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters, { ...filters, search_term: value });
  };

  const handleDealerLocationChange = ({ dealerIds, locationIds }: DealersLocationsDropdownValue) => {
    queryClient.setQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters, { ...filters, dealer_ids: dealerIds, location_ids: locationIds });
  };

  const handleDateRangeChange = (dates: Date[]) => {
    const [start, end] = dates;
    queryClient.setQueryData<LeadsCarsFilter>(LeadsQueryKeys.carsFilters, { ...filters, date_from: start, date_to: end });
  };

  return { handleResetFilters, handleSearchChange, handleDealerLocationChange, handleDateRangeChange };
};
