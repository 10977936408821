import { useMutation, useQueryClient } from "@tanstack/react-query";

import ENV from "config/Env";
import { DealerLocation } from "models";
import { useLicenses } from "modules/LocationLicenseOverview/hooks/useLicenses";
import { CategoryLicenseMutationArgs } from "modules/LocationLicenseOverview/types";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

export const useCategoryLicenses = () => {
  const queryClient = useQueryClient();
  const { refreshLicenses } = useLicenses();

  const categoryLicensesEnabledMutation = useMutation({
    mutationFn: async (params: CategoryLicenseMutationArgs) => {
      const response = await ApiInstance.post(`/licenses/category/toggle`, params, ENV.exactBaseUrl);
      return response.data;
    },
    onMutate: params => {
      const { enabled, dealer_location_id } = params;
      const location = queryClient.getQueryData<DealerLocation>([{ ...queryKeys.location.details, params: { id: dealer_location_id } }]);
      queryClient.setQueryData([{ ...queryKeys.location.details, params: { id: dealer_location_id } }], { ...location, exact_is_category_licence_enabled: enabled });
      return { location };
    },
    onSuccess: refreshLicenses,
    onError: (_error, variables, context) => {
      queryClient.setQueryData([{ ...queryKeys.location.details, params: { id: variables.dealer_location_id } }], context?.location);
    }
  });

  return { categoryLicensesEnabledMutation };
};
