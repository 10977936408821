import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey } from "util/keyFactory";

const component = "NoteSuggestionModal";

export const queryKeys = {
  location: (location_id?: number) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      baseUrl: ENV.dealerBaseUrl,
      endpoint: "/locations/details",
      component,
      ...(location_id ? { params: { location_id } } : {})
    })
  ],
  noteSuggestions: () => [backendQueryKey({ updateType: UPDATE_TYPE.Static, baseUrl: ENV.appointmentBaseURL, endpoint: "/note_suggestion/update", component })]
};
