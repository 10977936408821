import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Icon, Tab } from "semantic-ui-react";

import { DATE_FORMATS, EllipsisText, ReactTable } from "components";
import "components/CustomerModal/components/CustomerEntities/CustomerEntities.scss";
import { useDealersLocations } from "hooks";
import { Appointment, Car } from "models";
import { ITranslation } from "util/interfaces";

type CustomerEntitiesProps = {
  appointments: Appointment[];
  cars: Car[];
  t: ITranslation;
};

const CustomerEntities = ({ t, appointments, cars }: CustomerEntitiesProps) => {
  const { selectedLocation } = useDealersLocations();
  const renderAppointments = (apps: Appointment[]) => {
    if (!apps || apps.length === 0) {
      return <p style={{ padding: "1em", textAlign: "center" }}>{t("v8_no_appointments").message || "No appointments"}</p>;
    }

    const columnHelper = createColumnHelper<Appointment>();
    const columns = [
      columnHelper.accessor(row => row, {
        id: "date",
        header: t("v8_date").message || "Date",
        cell: info => {
          const app = info.renderValue();
          return (
            <span>
              <Icon className="arrow up right from square" /> {app?.time_car_app ? moment(app.time_car_app).format("DD.MM.YYYY") : ""}
            </span>
          );
        },
        size: 150
      }),
      columnHelper.accessor(row => row.wo_nr, {
        id: "wo_nr",
        header: t("v8_wo_nr").message || "WO",
        cell: info => <EllipsisText text={String(info.renderValue())} />,
        size: 90
      }),
      columnHelper.accessor(row => row.reg_number, {
        id: "reg_nr",
        header: t("v8_reg_nr").message || "Registration",
        cell: info => <EllipsisText text={String(info.renderValue())} />,
        size: 90
      }),
      columnHelper.accessor(row => row.car_apk_date, {
        id: "apkDate",
        header: t("v8_apk_date").message || "APK date",
        cell: info => {
          const car_apk_date = info.renderValue();
          return <span>{car_apk_date ? moment(car_apk_date).format(DATE_FORMATS.dateMonthYear) : ""}</span>;
        },
        size: 200
      }),
      columnHelper.accessor(row => row, {
        id: "car_hu_date",
        header: t("v8_car_hu_date").message || "Car hu date",
        cell: info => {
          const app = info.renderValue();
          return (
            <span>
              <Icon name="external" /> {app?.car_hu_date ? moment(app.car_hu_date).format(DATE_FORMATS.dateMonthYear) : ""}
            </span>
          );
        },
        size: 200
      }),
      columnHelper.accessor(row => row.car_make, {
        id: "car_make",
        header: t("v8_car_make").message || "Car make",
        cell: info => <EllipsisText text={String(info.renderValue())} />,
        size: 100
      }),
      columnHelper.accessor(row => row.car_model, {
        id: "car_model",
        header: t("v8_car_model").message || "Car model",
        cell: info => <EllipsisText text={String(info.renderValue())} />,
        size: 100
      }),
      columnHelper.accessor(row => row.current_km, {
        id: "current_km",
        header: t("v8_km_state").message || "KM State",
        cell: info => <EllipsisText text={String(info.renderValue())} />,
        size: 100
      })
    ];

    apps.sort((a, b) => (a.time_car_app > b.time_car_app ? -1 : 1));

    const onRowClick = (data: Appointment) => {
      window.open(`/appointments/${data.id}`, "_blank");
    };

    return (
      <ReactTable
        columns={columns}
        data={apps}
        state={{ columnVisibility: { car_hu_date: !!selectedLocation?.hu_visible, apkDate: !!selectedLocation?.apk_visible } }}
        onRowClick={onRowClick}
      />
    );
  };

  const renderCars = (cars: Car[]) => {
    if (!cars || cars.length === 0) {
      return <p style={{ padding: "1em", textAlign: "center" }}>{t("v8_no_cars").message || "No cars"}</p>;
    }

    const columnHelper = createColumnHelper<Car>();
    const columns = [
      columnHelper.accessor(row => row, {
        id: "makeModel",
        header: t("v8_make_model").message || "Make and model",
        cell: info => {
          const car = info.renderValue();
          return <EllipsisText text={`${car?.make} ${car?.model}`} />;
        },
        size: 200
      }),
      columnHelper.accessor(row => row.fuel, {
        id: "fuel",
        header: t("v8_fuel").message || "Fuel",
        cell: info => <EllipsisText text={String(info.renderValue())} />,
        size: 200
      }),
      columnHelper.accessor(row => row.dms_nr, {
        id: "dms_nr",
        header: t("v8_dms_nr").message || "DMS",
        cell: info => <EllipsisText text={String(info.renderValue())} />,
        size: 200
      }),
      columnHelper.accessor(row => row.reg_nr, {
        id: "reg_nr",
        header: t("v8_reg_nr").message || "Registration",
        cell: info => <EllipsisText text={String(info.renderValue())} />,
        size: 200
      }),
      columnHelper.accessor(row => row.vin_nr, {
        id: "vin_nr",
        header: t("v8_vin_nr").message || "VIN",
        cell: info => <EllipsisText text={String(info.renderValue())} />,
        size: 200
      }),
      columnHelper.accessor(row => row.reg1_date, {
        id: "regDate",
        header: t("v8_reg1_date").message || "1st Reg. date",
        cell: info => {
          const reg1_date = info.renderValue();
          return <span>{reg1_date ? moment(reg1_date).format(DATE_FORMATS.dateMonthYear) : ""}</span>;
        },
        size: 300
      }),
      columnHelper.accessor(row => row.apk_date_dms, {
        id: "apkDate",
        header: t("v8_apk_date").message || "APK date",
        cell: info => {
          const apk_date_dms = info.renderValue();
          return <span>{apk_date_dms ? moment(apk_date_dms).format(DATE_FORMATS.dateMonthYear) : ""}</span>;
        },
        size: 300
      }),
      columnHelper.accessor(row => row.hu_date_dms, {
        id: "huDateDms",
        header: t("v8_hu_date_dms").message || "HU date dms",
        cell: info => {
          const hu_date_dms = info.renderValue();
          return <span>{hu_date_dms ? moment(hu_date_dms).format(DATE_FORMATS.dateMonthYear) : ""}</span>;
        },
        size: 300
      })
    ];

    const onRowClick = (data: Car) => {
      window.open(`/cardetails/${data.id}`, "_blank");
    };

    return <ReactTable columns={columns} data={cars} onRowClick={onRowClick} />;
  };

  return (
    <div className="CustomerEntities" style={{ padding: "1em" }}>
      <Tab
        style={{ padding: "0" }}
        panes={[
          {
            menuItem: t("v8_appointments").message || "Appointments",
            render: () => <Tab.Pane>{renderAppointments(appointments)}</Tab.Pane>
          },
          {
            menuItem: t("v8_cars").message || "Cars",
            render: () => <Tab.Pane>{renderCars(cars)}</Tab.Pane>
          }
        ]}
        grid={{ paneWidth: 16, tabWidth: 8 }}
      />
    </div>
  );
};

export default withTranslation()(CustomerEntities);
